import { Component, OnInit } from '@angular/core';
import {Theming} from "../../models/Theming" ;

import { saveAs } from 'file-saver';
import * as FileSaver from 'file-saver';

@Component({
  selector: 'app-admin-theming',
  templateUrl: './admin-theming.component.html',
  styleUrls: ['./admin-theming.component.scss']
})
export class AdminThemingComponent implements OnInit {

	theming : Theming; 

	predefinedThemes : any[] = [
		{
			"name" : "dark",
			"palette" : {
				"backgroundPrimary" : "#253138" ,
				"backgroundSecondary" : "#2f3e47" ,
				"textDark" : "#f3f3f3" ,
				"textMuted" : "#98a6ad" ,
				"headings" : "#ffffff" ,
				"formBorder" : "#ffffff" ,
				"boxShadow" : "#fff" 
			}
		},
		{
			"name" : "light",
			"palette" : {
				"backgroundPrimary" : "#fff" ,
				"backgroundSecondary" : "#fff" ,
				"textDark" : "#f3f3f3" ,
				"textMuted" : "#98a6ad" ,
				"headings" : "#000000" ,
				"formBorder" : "#000000" ,
				"boxShadow" : "#fff" 
			}
		},
		{
			"name" : "golden",
			"palette" : {
				"backgroundPrimary" : "#424951" ,
				"backgroundSecondary" : "#3c4752" ,
				"textDark" : "#f3f3f3" ,
				"textMuted" : "#98a6ad" ,
				"headings" : "#ffce61" ,
				"formBorder" : null ,
				"boxShadow" : "#fff" 
			}
		}
	];

	UISelectors : any[] = [
		{
			"name"  : "backgroundPrimary" ,
			"selectors" : [
				".topbar-left" ,
				".navbar.navbar-default",
				".content-page"
			],
			"property" : "background-color"
		},
		{
			"name"  : "backgroundSecondary" ,
			"selectors" : [
				".card-box" ,
				".left-sidebar-menu" ,
				".side-menu" ,
				"#sidebar-menu > ul > li > a" , 
				".form-control" ,
				".modal .modal-dialog .modal-content" 
			],
			"property" : "background-color"
		},
		{
			"name"  : "textMuted" ,
			"selectors" : [
				".text-muted" 
			],
			"property" : "color"
		},
		{
			"name"  : "textDark" ,
			"selectors" : [
				".text-dark" 
			],
			"property" : "color"
		},
		{
			"name"  : "headings" ,
			"selectors" : [
				"h1" ,
				"h2" ,
				"h3" ,
				"h4" ,
				"h5" ,
				"h6" ,
				".app-search button" ,
				".form-control" , 
				".logo ", 
				".button-menu-mobile" , 
				"#sidebar-menu > ul > li > a.active" ,
				".notification-box ul li a"
			],
			"property" : "color"
		}
	];

	constructor(){
		this.theming = new Theming;
	}

	ngOnInit() {
	}

	configure(){
		console.log(this.theming);
		for(var i=0 ; i < this.UISelectors.length ; i++){
			var name = this.UISelectors[i].name ;
			var colorValue = this.theming[name] ;
			// var selectors = this.theming[name] ;
			console.log(colorValue);
			for(var j=0 ; j < this.UISelectors[i].selectors.length ; j++){
				var x = document.querySelectorAll(this.UISelectors[i].selectors[j]) ;
				for(var k = 0 ; k < x.length ; k++){
					// x[k].style[this.UISelectors[i].property] = colorValue  ;
					x[k].style.setProperty(this.UISelectors[i].property , colorValue , "important") ;
				}
			}
		}
	}

	setTheme(theme){
		console.log(typeof theme);
		console.log(typeof this.predefinedThemes);
		console.log(this.predefinedThemes.filter(
			(obj)=> { if(obj["name"] == theme){
				return obj ;
			}})
		);
		this.theming = this.predefinedThemes.filter(
			(obj)=> { if(obj["name"] == theme){
				return obj ;
			}})[0].palette ;
		console.log(this.theming);
		// this.theming  = this.predefinedThemes.filter((obj)=>{obj.name == theme})[0].palette ;
	}

	exportTheme(){
		// const blob = new Blob(JSON.stringify(this.UISelectors) , { type:  "text/plain" });
		// const url= window.URL.createObjectURL(blob);
		// window.open(url);
		var retVal = prompt("Enter Theme Name : ", "Name here");
        // document.write("You have entered : " + retVal);
        var themeName = retVal.length ? retVal : "Theme" ;
		// var FileSaver = require('file-saver');
		var blob = new Blob([JSON.stringify(this.theming)], {type: "text/json;charset=utf-8"});
		FileSaver.saveAs(blob, `Theme-${themeName}.json`);
	}

}
