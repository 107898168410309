export class CorpUser{
	companyName     : string ;
	companyID       : string ;
	country         : string ;
	jurisdictionCode: string ;
}

export class VerificationResult{
	addressStatus     : string = null ;
	dobStatus         : string = null ;
	docSerialNoStatus : string = null ;
	expiryDateStatus  : string = null ;
	firstNameStatus   : string = null ;
	issueDateStatus   : string = null ;
	lastNameStatus    : string = null ;
	middleNameStatus  : string = null ;
	billingDate       ?: string = null ;
	nameStatus        ?: string = null ;
	verificationScore ?: string = null ;
	verificationStatus?: string = null ;
}

export class Verification{
	verificationResult : VerificationResult = new VerificationResult() ;
	verificationScore  : any = null;
	verificationStatus : "CREATED" |  "SUBMITTED" | "PENDING" | "FAILED" | "SUCCESS" | "PASS" = null ;
}


import { Component, OnInit , Input , Output , EventEmitter} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DataService } from "../../../services/data.service";
import { AppService } from "../../../services/app.service";
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-corp-form',
  templateUrl: './corp-form.component.html',
  styleUrls: ['./corp-form.component.scss']
})
export class CorpFormComponent implements OnInit {

	@Input() usersAMLChecks : any ;
	@Input() corpUser      : any
	@Input() hashId        : any
	@Input() traversalState: any ;
	//Emitting the current state of the Selected Check
	@Output() checkState   : EventEmitter<any> = new EventEmitter();
	companyJurisdictions   : any[] = [];
	corpCountries          : any[] = [];

	endUserCheckID		   : any ;

	showOCSearch		   : boolean = false ;

	selectedCompany 	   : any = null ;
	verification		   : Verification  = new Verification();

	noCompanyDataFound     : boolean = false ;

	constructor(
		private formBuilder: FormBuilder,
		private _data      : DataService,
		private _app       : AppService){
	}

	ngOnInit(){
		console.log(this.corpUser);
		console.log(this.usersAMLChecks);
		this.endUserCheckID = this.usersAMLChecks[0].endUserCheckID ;
		this.getCompanyJurisdictions();
		this.getSavedCorpData();
	}

	getSavedCorpData(){
		this._app.getCorpCheckInfo(this.hashId)
		.subscribe(
			(res)=>{
				console.log(res);
				this.verification.verificationStatus = res.data.corporateStatus ;
				this.selectedCompany = res.data.corporateInfo ;
			}
		);
	}

	submitCorpCheck(){
		let data = {
			"company" : this.selectedCompany
		};
		this._app.submitCorpCheck(data , this.hashId)
		.subscribe(
			(res)=>{
				console.log(res);
				this.selectedCompany = res.data.corporateInfo ;
				this.verification.verificationStatus = res.data.corporateStatus ;
				this._data.toastrShow(res["message"] , "info");
			}
		);
	}

	getCompanyJurisdictions(){
		this._app.getCompanyJurisdictions()
		.subscribe(
			(res)=>{
				// this.companyJurisdictions = res.data;
				// this.corpUser["country"] = "all";
				this.corpCountries = res.data;
				let all = {
					"countryCode"    : null ,
					"countryName"    : "all" ,
					"countrySynonyms": null ,
					"isActive"       : null ,
					"jurisdictions"  : null 
				};
				// this.corpUser["country"] = all;
				this.corpUser.country = all;
				this.corpCountries.unshift(all);
			}
		);
	}

	shouldJurisdiction(countryObj){
		console.log(countryObj);
		let companyJurisdictions = this.corpCountries.filter((obj)=>{
			if(obj.jurisdictions && obj.countryName == countryObj.countryName){
				return obj.jurisdictions ;
			}
		});
		if(companyJurisdictions.length){
			console.log("Yes J code");
			this.companyJurisdictions = companyJurisdictions[0].jurisdictions;
			if(this.companyJurisdictions.length && this.companyJurisdictions.length ==1){
				this.corpUser.jurisdictionCodes = this.companyJurisdictions[0];
				this.corpUser["country"] = countryObj;
			}
			else{
				let jObj = {
					"code"            : 'all',
					"createdDate"     : null ,
					"fullName"        : null ,
					"lastModifiedDate": null ,
					"name"            : "all"
				}
				this.companyJurisdictions.unshift(jObj);
				this.corpUser["jurisdictionCodes"] = jObj ;
				this.corpUser["country"] = countryObj;
			}
		}
		else{
			console.log("No J code");
			this.companyJurisdictions = null;	
			this.corpUser["jurisdictionCodes"] = null;
			this.corpUser["countryCodes"]  = null; 
			this.corpUser["country"] = null;
		}
	}

	doCorpCheck(){
		console.log(this.corpUser);
		this.showOCSearch = true ;
		this._data.storeId(this.hashId)
		console.log("corporate id",this._data.corporateId)
	}

	updateCheckState($event){
		this.checkState.emit($event);
	}

	shouldAddWizard(e){
		console.log("addWizardStep" + e);
		// this.addWizardStep = e ;
	}

    onGettingZeroSearchResults(e){
        console.log("onGettingZeroSearchResults");
        console.log(e);
        this.noCompanyDataFound = e ;
    }

	onSelectCompany(company){
		this.selectedCompany = company ;
		// this.endUser["company"] = this.selectedCompany ;
	}
	

}
