import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { AppService } from 'src/app/services/app.service';
import { DataService } from 'src/app/services/data.service';
import { AmlSearchService } from '../../aml-search/aml-search.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-aml-screening-report',
  templateUrl: './aml-screening-report.component.html',
  styleUrls: ['./aml-screening-report.component.scss']
})
export class AmlScreeningReportComponent implements OnInit {
  @Input() item;
  @Output() close = new EventEmitter<any>();
  entityRiskStatus: string = 'LOW';
  entityRiskRating: number = 0;
  entityAdverseMediaRiskRating: number = 0;
  amlType: string;
  noAmlArray: any[] = [
    { key: 'WatchList' },
    { key: 'PEP' },
    { key: 'SANCTION' },
    { key: 'CRIME' },
  ]
  entityName: string;
  newsArticlesArray: any[] = [];
  adverseMediaArray: any[] = [];

  constructor(private dataService: DataService, private amlService: AmlSearchService,public app: AppService,private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.hideLeftPanel();
    if (this.item.profileRisk) {
      if (this.item.profileRisk.riskRating && this.item.profileRisk.riskRating.length > 0) {
        for (let risk of this.item.profileRisk.riskRating) {
          if (risk.name === 'Adverse Media') {
            this.entityAdverseMediaRiskRating = risk.score;
          }
        }
      }
    }
    if(this.item.riskRes){
      this.entityRiskStatus = this.item.riskRes.label;
      this.entityRiskRating = this.item.riskRes.score;
    }
    if (this.item.masterData) {
      if (this.item.masterData[0].fullName) {
        this.entityName = this.item.masterData[0].fullName[0]
      }
    }
    if (this.item.relatedData) {
      for (let type of this.item.relatedData) {
        if (type.service === 'AML') {
          this.amlType = type.category;
          var index = this.noAmlArray.findIndex((res) => res.key == this.amlType);
          if (index > -1) {
            this.noAmlArray.splice(index, 1);
          }
        }
      }
    }
    this.newsArticles()
  }


  hideLeftPanel() {
    var leftElem = document.getElementById("wrapper");
    leftElem.classList.add("forced");
    leftElem.classList.add("enlarged");
  }

  newsArticles() {
    let payload = {
      name: this.entityName
    }
    console.log(payload);

    this.dataService.changeLoaderVisibility(true);
    this.amlService.getRecentArticles(payload).pipe(finalize(() => this.dataService.changeLoaderVisibility(false))).subscribe((res) => {
      this.newsArticlesArray = res;
    })
  }

  closeInfo($event: MouseEvent) {
    $event.preventDefault();
    this.close.emit('retailOut');
  }
  public getSantizeUrl(url : string) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
}

}
