// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
	production: false,
	firebase : {
		apiKey           : "AIzaSyDXJqmgdZy8GgMncHvijeEHfDsC_0u3yqg",
		authDomain       : "kychub-cd897.firebaseapp.com",
		databaseURL      : "https://kychub-cd897.firebaseio.com",
		projectId        : "kychub-cd897",
		storageBucket    : "",
		messagingSenderId: "739715708057",
		appId            : "1:739715708057:web:2f59573e01be65a8"
	}
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
