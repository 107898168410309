import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PipesModule } from "./../../../pipes/pipes.module" ;
import { AmlDetailsComponent } from "./aml-details.component" ;

@NgModule({
  declarations: [ AmlDetailsComponent ],
  imports: [
    CommonModule,
    PipesModule
  ],
  exports: [ AmlDetailsComponent ]
})
export class AmlDetailsModule { }
