import { Component, OnInit , Input , Output , EventEmitter } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Router, ActivatedRoute, Params, NavigationEnd } from "@angular/router";
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import {KycModalComponent} from '../../shared/kyc-modal/kyc-modal.component';
import { DataService } from "../../services/data.service";
import { AppService } from "../../services/app.service";
import { PaymentService } from "../../services/payment.service";
import { first } from 'rxjs/operators';


@Component({
  selector: 'app-coupons',
  templateUrl: './coupons.component.html',
  styleUrls: ['./coupons.component.scss']
})
export class CouponsComponent implements OnInit {

	@Input() allowRedemption: any ;
	@Output() applyCoupon   = new EventEmitter();
	coupons                 : any[] = []
	
	//loading
	isApiLoading            : boolean = false;

  	constructor(
		private modalService: BsModalService,
		private _data       : DataService,
		private _app        : AppService,
		private _payment    : PaymentService,
		public router       : Router){
	}

	ngOnInit(){
		this.getSaleCoupons();
	}

	getSaleCoupons(){
		this._app.getSaleCoupons()
		.subscribe(
			(res)=>{
				this.coupons = res.data ;
			}
		);
	}

	onApplyCoupon(coupon){
		this.applyCoupon.emit(coupon);
	}

}
