import { Component, OnInit } from '@angular/core';
import { DataService } from "../../services/data.service";
import { AppService } from "../../services/app.service";
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { KycModalComponent } from '../../shared/kyc-modal/kyc-modal.component';
@Component({
  selector: 'app-aml-false-positive',
  templateUrl: './aml-false-positive.component.html',
  styleUrls: ['./aml-false-positive.component.scss']
})
export class AmlFalsePositiveComponent implements OnInit {

  falseAlertsData=[];
  falseBookmarkData=[];
  pageList: any[];
  bookmarkedpageList=[];
  jump:any;
	currentPage    : number = 0;
	itemsPerPage   : number = 10 ;
	totalItems     : number ;
	maxSize        : number ;
  startIndex: number=0;
  endIndex: number=10;

  bookmarkedJump: any;
  currentPagebookmarked    : number = 0;
	itemsPerPagebookmarked   : number = 10 ;
	totalItemsbookmarked    : number ;
	maxSizebookmarked        : number ;
  bookmarkedStartIndex: number=0;
  bookmarkedEndIndex: number=10;

  payload	  	: any = {
    'size'              : this.itemsPerPage,
    'page'              : this.currentPage,
    };
  
    bookmarkedpayload	  	: any = {
      'size'              : this.itemsPerPagebookmarked,
      'page'              : this.currentPagebookmarked,
      };
  public bsModalRef: BsModalRef;
  constructor(
    private _data       : DataService,
		private _app        : AppService,
    private modalService: BsModalService,
  ) { }

  ngOnInit() {

    this.getFalsePositiveData();
    this.jump=this.currentPage+1;
    this.getBookmarkedData();
    this.bookmarkedJump=this.currentPagebookmarked+1;
  }

  getFalsePositiveData(){
    this._data.changeLoaderVisibility(true);
    this._app.getFalsePositiveData(this.payload).subscribe((res)=>{
      if(res['data']){
        this.falseAlertsData = res.data.content|| [];
        if(this.currentPage === 0){
          this.totalItems = res.data.totalElements;
          this.maxSize = res.data.totalPages;
          this.pageSize();
        }
        this._data.changeLoaderVisibility(false);
			}
    }
		);
  }

  getBookmarkedData(){
    this._app.getBookMarkedFalsePositiveData(this.bookmarkedpayload).subscribe((res)=>{
      if(res['data']){
        this.falseBookmarkData = res.data.content|| [];
        if(this.currentPagebookmarked === 0){
          this.totalItemsbookmarked = res.data.totalElements;
          this.maxSizebookmarked = res.data.totalPages;
          this.pageSizeBookmarked();
        }
			}
    }
		);
  }

  openModal(id){
    const initialState = {
      title: `Please enter your reason for marking as false positive`,
      type: "false-positive-alerts",
    };
    this.bsModalRef = this.modalService.show(KycModalComponent, {
      initialState,
      class: "modal-lg",
    });
    this.bsModalRef.content.onClose.subscribe((result) => {
      if(Object.keys(result).length){
        const payload = {
          "endUserAmlChangeAlertsFalsePositiveDataId": id,
          "devOpsMessage": result.comment,
          "falsePositiveDevOps": false
        }
        this._app.markFalsePositive(payload).subscribe(() => {
          this.getFalsePositiveData()
           this.getBookmarkedData()
        })            
      }
    })
  }

  getPaginatedText(){
    let a = (this.currentPage * this.itemsPerPage) + 1 ;
    let b ;
    if(this.currentPage < (this.maxSize - 1)){
        b = (this.currentPage + 1 ) * this.itemsPerPage;
    }
    else if(this.currentPage == (this.maxSize - 1 )){
        b = this.totalItems;
    }
    return  a+"-"+b ;
}

  getPaginatedTextBookmarked(){
  let a = (this.currentPagebookmarked * this.itemsPerPagebookmarked) + 1 ;
  let b ;
  if(this.currentPagebookmarked < (this.maxSizebookmarked - 1)){
      b = (this.currentPagebookmarked + 1 ) * this.itemsPerPagebookmarked;
  }
  else if(this.currentPagebookmarked == (this.maxSizebookmarked - 1 )){ 
      b = this.totalItemsbookmarked;
  }
  return  a+"-"+b ;
}

pageSize() {
  this.pageList = []
  let totalPages = this.getPage(this.totalItems)
  this.startIndex = (this.currentPage - 4) < 0 ? 0 : this.currentPage - 4
  this.endIndex = (this.currentPage + 4) > this.getPage(this.totalItems) ? totalPages : this.currentPage + 4
  for (let i = 0; i <= this.currentPage + 4; i++) {
    if (i >= this.currentPage - 4 && i < totalPages) { this.pageList.push(i) }
  }

}

pageSizeBookmarked() {
  this.bookmarkedpageList = []
  let totalPages = this.getPage(this.totalItemsbookmarked)
  this.bookmarkedStartIndex = (this.currentPagebookmarked - 4) < 0 ? 0 : this.currentPagebookmarked - 4
  this.bookmarkedEndIndex = (this.currentPagebookmarked + 4) > this.getPage(this.totalItemsbookmarked) ? totalPages : this.currentPagebookmarked + 4
  for (let i = 0; i <= this.currentPagebookmarked + 4; i++) {
    if (i >= this.currentPagebookmarked - 4 && i < totalPages) { this.bookmarkedpageList.push(i) }
  }

}

getPage(length) {
  return (Math.round(length / 10));
}

getPaginatedData(isNext){
  this.currentPage = isNext ? this.currentPage + 1 : this.currentPage - 1 ;
  this.payload["page"] = isNext ? this.payload["page"] + 1 : this.payload["page"] - 1 ; ;
  if(this.currentPage < this.maxSize){
      this.getFalsePositiveData();
      this.pageSize();
      this.jump=this.currentPage+1;
  }
}

getPaginatedDatabookmarked(isNext){
  this.currentPagebookmarked = isNext ? this.currentPagebookmarked + 1 : this.currentPagebookmarked - 1 ;
  this.bookmarkedpayload["page"] = isNext ? this.bookmarkedpayload["page"] + 1 : this.bookmarkedpayload["page"] - 1 ; ;
  if(this.currentPagebookmarked < this.maxSizebookmarked){
      this.getBookmarkedData();
      this.pageSizeBookmarked();
      this.bookmarkedJump=this.currentPagebookmarked+1;
  }
}

jumpTo() {
  if (this.jump <= this.maxSize) {
    if (Math.sign(this.jump) != -1 && Math.sign(this.jump) != 0)
      this.getPagedData(this.jump - 1)
  }
}

jumpToBookmarked() {
  if (this.bookmarkedJump <= this.maxSizebookmarked) {
    if (Math.sign(this.bookmarkedJump) != -1 && Math.sign(this.bookmarkedJump) != 0)
      this.getPagedDatabookmarked(this.bookmarkedJump - 1)
  }
}

getPagedData(index) {
  this.currentPage = index
  this.payload["page"] = index;
  if (this.currentPage < this.maxSize) {
      this.getFalsePositiveData();
    this.pageSize()
    this.jump = this.currentPage + 1;
  }

}

getPagedDatabookmarked(index) {
  this.currentPagebookmarked = index
  this.bookmarkedpayload["page"] = index;
  if (this.currentPagebookmarked < this.maxSizebookmarked) {
      this.getBookmarkedData();
    this.pageSizeBookmarked()
    this.bookmarkedJump = this.currentPagebookmarked + 1;
  }

}


}
