import { Component, OnInit , Input , Output , EventEmitter } from '@angular/core';
import { Router, ActivatedRoute, Params, NavigationEnd } from "@angular/router";
import { DataService } from "../../services/data.service";
import { AppService } from "../../services/app.service";
import { first } from 'rxjs/operators';
import { TrackingService } from 'src/app/services/tracking.service';

@Component({
  selector: 'app-users-all-checks',
  templateUrl: './users-all-checks.component.html',
  styleUrls: ['./users-all-checks.component.scss']
})
export class UsersAllChecksComponent implements OnInit {

	@Input() identityInfo : any;
    @Output() goToId = new EventEmitter();

    currentPage : number = 0;
    itemsPerPage: number = 100 ;
    totalItems  : number ;
    maxSize     : number ;
    sort        : string = null ;
    buildQuery: any = {
        'size'        : this.itemsPerPage,
        'page'        : this.currentPage,
        'identityInfo': this.identityInfo,
        // 'userType' : null,
        'sort'        : this.sort,
    };

    //loading
    isApiLoading   : boolean = false;

    //arr
    userChecks     : any[] = [];

	constructor(
		private _data: DataService,
		private _app : AppService,
        public router: Router,
        private _track:TrackingService){
	}

	ngOnInit(){
		this.buildQuery["identityInfo"] = this.identityInfo;
		this._app.getUsersAllChecks(this.buildQuery)
		.subscribe(
			(res) =>{
                this.userChecks = res.data.content;
                console.log("datas",this.userChecks)
                for(var i = 0 ; i < this.userChecks.length ; i++){
                    if(this.userChecks[i].checks.allCheckInfo){
                        this.userChecks[i].checks.allCheckInfo = this.userChecks[i].checks.allCheckInfo.map(obj=>obj.name);
                        this.userChecks[i].checks.allCheckInfo = Array.from(new Set(this.userChecks[i].checks.allCheckInfo)); 
                    }
                }
			}
		)
	}

    sendRemoteLink(id){
        this._app.sendRemoteLink(id)
        .subscribe(
            (res)=>{
                this._data.toastrShow("Remote Kyc link has been sent" , "info");
                this._track.activityLog();
            }
        )
    }

    goToUpload(id){
        this.goToId.emit(id);
    }

}
