import { Component, Input, OnInit, Output } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { DataService } from "../../services/data.service";
import { MessagingService } from "../../services/messaging.service";
import { NotificationService } from "../../services/notification.service";

import { BsModalService } from "ngx-bootstrap/modal";
import { BsModalRef } from "ngx-bootstrap/modal/bs-modal-ref.service";
import { KycModalComponent } from "./../../shared/kyc-modal/kyc-modal.component";
import { EventEmitter } from "@angular/core";

@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.scss"]
})
export class NavbarComponent implements OnInit {
  sideBarHidden: boolean;
  currentRoute: string;

  //Modal
  bsModalRef: BsModalRef;

  notifications: any[] = [];
  totalElements: number = 0;
  messageBehaviour: any;
  isNotify:boolean =false;

  clientData:any;
  // notificationType: "webhook" | "api-key-generated" ;

  constructor(
    private router: Router,
    private _data: DataService,
    private _noti: NotificationService,
    private messagingService: MessagingService,
    private modalService: BsModalService,
    public route: ActivatedRoute,
  ) {
    this.messageBehaviour = this.messagingService.currentMessage;
  }

  ngOnInit() {
    let data = {
      page: 0,
      size: 10,
      sort: "ASC"
    };
    let type = "unread"; //read | unread | all
    //add this when notification is ready
    // this._noti.getAllNotification(data , type)
    // .subscribe(
    //     (res)=>{
    //         this.notifications = res.data.content ;
    //         this.totalElements = res.data.totalElements ;
    //         // this.totalElements = res.data.totalElements ;
    //         console.log(this.notifications);
    //     }
    // );
    let standardServices;
    let b = this._data.loadClientStandardServices.subscribe(
      (val)=>{ standardServices = val; 
        let serviceItem=standardServices.find(item=>item['slug']=='notification')
        this.isNotify=serviceItem!=undefined?true:false;
      }
    );
    
  }

  hideSideBar() {
    var element = document.getElementById("wrapper");
    if (!this.sideBarHidden) {
      //hide it
      element.classList.add("forced", "enlarged");
      this.sideBarHidden = true;
    } else {
      // unhide it
      element.classList.remove("forced", "enlarged");
      this.sideBarHidden = false;
    }
  }
  @Input() public currentTheme: string;
  @Output() changedTheme: EventEmitter<any> = new EventEmitter<any>();
  themeChange: boolean = false;
  themeToggle(){
    if(this.currentTheme == 'theme-light'){
      localStorage.setItem('currentTheme', 'theme-dark');
      this.changedTheme.emit('theme-dark');
      this._data.themeFlag('theme-dark')
    }else if(this.currentTheme == 'theme-dark'){
      this.changedTheme.emit('theme-light');
      localStorage.setItem('currentTheme', 'theme-light');
      this._data.themeFlag('theme-light')
    }
  }
  logOut() {
    const initialState = {
      title: `Log Out`,
      type: "user-logout"
    };
    this.bsModalRef = this.modalService.show(KycModalComponent, {
      initialState,
      class: "modal-lg"
    });
    this.bsModalRef.content.closeBtnName = "Close";
    console.log(this.bsModalRef.content.onClose);
    this.bsModalRef.content.onClose.subscribe(result => {
      console.log("results", result);
      if (Object.keys(result).length) {
        console.log(result);
        if (result.shouldLogout) {
          this.doLogout();
        }
      }
    });
  }

  doLogout() {
    this._data.removeAllCookie();
    this._data.changeToken(null);
    this._data.changeClientStandardServices(null);
    this._data.changeClientStandardChecks(null);
    this.messageBehaviour.unsubscribe();
    this.router.navigate(["/login"]);
  }

  getNotificationIcon(slug, type) {
    let notiDetail = [
      {
        slug: "api-key-generated",
        color: "bg-danger",
        icon: "fa-key"
      },
      {
        slug: "webhook",
        color: "bg-warning",
        icon: "fa-cog"
      }
    ];

    let selectedNoti = notiDetail.filter(obj => obj.slug == slug);
    if (selectedNoti && selectedNoti.length) {
      return selectedNoti[0][type];
    } else {
      return type == "color" ? "bg-primary" : "fa-info-circle";
    }
  }

  markRead(notificationID) {
    this.totalElements =
      this.totalElements - 1 >= 0 ? this.totalElements - 1 : 0;

    // this.notifications = this.notifications.filter(obj=>obj.notificationID != notificationID) ;
    // this.router.navigate(['/notifications']);
    this._noti.markAsRead(notificationID).subscribe(res => {
      this.notifications.map(obj => {
        if (obj.notificationID == notificationID) {
          obj.readBy = res.data.readBy;
          obj.isRead = true;
        }
        return obj;
      });
      this.router.navigate(["/notifications"]);
    });
  }
}
