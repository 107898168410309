import { Component, OnInit , Input , Output , EventEmitter , ChangeDetectorRef} from '@angular/core';
import { Router, ActivatedRoute, Params, NavigationEnd } from "@angular/router";
import { DataService } from "../../../services/data.service";
import { AppService } from "../../../services/app.service";
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-company-data',
  templateUrl: './company-data.component.html',
  styleUrls: ['./company-data.component.scss']
})
export class CompanyDataComponent implements OnInit {

    //storing details
    @Input() hashId          : any;
    @Input() companiesData   : any;
    @Output() selectedCompany: EventEmitter<any> = new EventEmitter();
    
    //for selecting company for details
    chosenCompany            : any ;
    btnHide:boolean=true;
    l:any=7;
    fbtnHide:boolean=true;
    lf:any=5;
    obtnHide:boolean=true;
    ol:any=5;
    //Loading
    isApiLoading             : boolean = false;

	constructor(
        private _data: DataService,
        private _app : AppService,
        public cdRef : ChangeDetectorRef,
        public router: Router){
	}

	ngOnInit(){
        console.log(this.companiesData);
		if(this.companiesData){
			this.getCompanyDetails(this.companiesData);
		}
	}

    getCompanyDetails(companiesData){
        let buildQuery = {
            'size'            : 10,
            'page'            : 1,
            'name'            : null ,
            "companyID"       : companiesData.company_number ,  
            "jurisdictionCode": companiesData.jurisdiction_code ,
            "country"         : null 
        };
        this.isApiLoading = true ;
        this._data.changeLoaderVisibility(true);
        // this._app.searchCompanies(buildQuery)
        this._app.searchCorpCompanies(buildQuery , this.hashId)
            .pipe(first())
            .subscribe(
                (res)=>{
                    if(res.data.results.company ){
                        this.companiesData = res.data.results.company ;
                        
                    }
                    this.isApiLoading = false ;
                    this._data.changeLoaderVisibility(false);
                    console.log(this.companiesData);

                    this.emitCompany(this.companiesData);
                }
            )
    }

    emitCompany(company){
        this.cdRef.detectChanges();
        this.chosenCompany = company.company_number ;
        console.log(this.chosenCompany);
        this.selectedCompany.emit(company);
    }

}
