export class VerificationResult{
	addressStatus     : string = null ;
	dobStatus         : string = null ;
	docSerialNoStatus : string = null ;
	expiryDateStatus  : string = null ;
	firstNameStatus   : string = null ;
	issueDateStatus   : string = null ;
	lastNameStatus    : string = null ;
	middleNameStatus  : string = null ;
	
	billingDate       ?: string = null ;
	nameStatus        ?: string = null ;
	verificationScore ?: string = null ;
	verificationStatus?: string = null ;
}

export class Verification{
	verificationResult : VerificationResult = new VerificationResult() ;
	verificationScore  : any = null;
	verificationStatus : "CREATED" |  "SUBMITTED" | "PENDING" | "FAILED" | "SUCCESS" | "PASS" = null ;
}
