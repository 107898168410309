import { Component, OnInit , Input , Output , EventEmitter} from '@angular/core';
import { Router, ActivatedRoute, Params, NavigationEnd } from "@angular/router";
import {KycModalComponent} from '../../../shared/kyc-modal/kyc-modal.component';
import { DataService } from "../../../services/data.service";
import { AppService } from "../../../services/app.service";
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { first } from 'rxjs/operators';
import { Observable , of } from 'rxjs';

import {AdverseMedia , CR , LR , AdverseKey} from "../../../utils/adverseMedia";

@Component({
  selector: 'app-aml-selected-result',
  templateUrl: './aml-selected-result.component.html',
  styleUrls: ['./aml-selected-result.component.scss']
})
export class AmlSelectedResultComponent implements OnInit {
	
	@Input() hashId                : any ;
	@Input() amlEntityGuid         : any ;
	@Input() usersAMLChecks        : any ;
	@Output() getAMLSelectedResults: EventEmitter<any> = new EventEmitter();
	@Output() getAdverseResults	   : EventEmitter<any> = new EventEmitter();
	
	// sourceType                  : any[] = ["PEP" , "SANCTION" , "CRIME"];
	@Input() amlData               : any;
	@Input() canChangeCheckStatus  : boolean = true; // true meaning its selection page & not user detail page
	
	@Input() amlUserData           : any ;
	
	adverseMediaQuery     		   : any ;
	
	itemsPerPage                   : number = 1 ;
	
	amlSelectedResults             : any = {
	"pepSourceGUIDs"          : [],	
	"sanctionSourceGUIDs"     : [],
	"crimeSourceGUIDs"        : []
	}

	adverseSelectedResult          : any[] = [] ;

	hasDoneAdverseSearch	: boolean = false ;
	
	totalAdverseMediaResults: any = 0 ;
	showAdverseFilters      : boolean = false ;
	adverseCategory         : any[] = AdverseMedia ;
	cr                      : any[] = CR ;
	lr                      : any[] = LR ;

	adverseKey			    : any = AdverseKey ;
	
	bsModalRef              : BsModalRef;

	@Input() amlComment 	: any ;
	showuserDetails: boolean;
	showcompanyDetails: boolean;
	showuserfulname: boolean;


	constructor(
		private modalService: BsModalService,
		private _data       : DataService,
		private _app        : AppService,
		public router       : Router){
		this.amlData = {
			"pep"       : null ,
			"sanction"  : null ,
			"crime"     : null 
		};
	}

	ngOnInit(){
		console.log("amlUserData");
		if(this.amlUserData.firstName == "" ||this.amlUserData.firstName ==undefined){
			this.showuserDetails=false;
		}
		else this.showuserDetails=true;
		if(this.amlUserData.companyName == "" ||this.amlUserData.companyName ==undefined){
			this.showcompanyDetails=false;
		}
		else this.showcompanyDetails=true;
		if(this.amlUserData.fullName == "" ||this.amlUserData.fullName ==undefined){
			this.showuserfulname=false;
		}
		else this.showuserfulname=true;
		this.amlUserData["category"]     = this.adverseCategory[0];
		this.amlUserData["exactTerms"]   = null;
		this.amlUserData["excludeTerms"] = null;
		this.amlUserData["dateRestrict"] = null;
		this.amlUserData["cr"]           = null;
		this.amlUserData["lr"]           = null;
		this.amlUserData["orTerms"]      = null;
		console.log(this.amlUserData);
		this.prepareDataAsPerChecks();
		this.parseAdverMediaQuery();
		console.log(this.usersAMLChecks);
		var page = 0 ;
		var sort = "desc" ;
		if(!this.amlData["pep"] || !this.amlData["sanction"] || !this.amlData["crime"]){
			for(var key in this.amlData){
				if(key !== "media"){
					this.getSelectedAMLData(key , page , sort);
				}
			}
		}
		console.log(this.amlData);
	}

	prepareDataAsPerChecks(){
		for(var key in this.amlData){
			if(!this.getEndUserCheckID(key)){
				delete this.amlData[key];
			}
		}
		// if(this.adverseMediaQuery){
			this.amlData.media = null ;
			// this.doAdverseMediaSearch(this.adverseMediaQuery);
		// }
	}

	applyAdverseFilter(){
		console.log(this.amlUserData);
		// let cx = this.amlUserData["category"].cx ;
		let cx = "006369557890321809005:ppvzevhnmii" ;
		// this.adverseMediaQuery = `https://www.googleapis.com/customsearch/v1?q=${this.amlUserData.firstName}+${this.amlUserData.lastName}&cx=${cx}&key=${newKey}&num=10&filter=0` ;
		if(this.amlUserData["firstName"] && this.amlUserData["lastName"] ){
			this.adverseMediaQuery = `https://www.googleapis.com/customsearch/v1?q=${this.amlUserData.firstName}+${this.amlUserData.lastName}&cx=${cx}&key=${this.adverseKey}&num=10&filter=0` ;
		}
		else if(this.amlUserData["fullName"]){
			this.adverseMediaQuery = `https://www.googleapis.com/customsearch/v1?q=${this.amlUserData.fullName}&cx=${cx}&key=${this.adverseKey}&num=10&filter=0` ;
		}
		else if(this.amlUserData["companyName"]){
			this.adverseMediaQuery = `https://www.googleapis.com/customsearch/v1?q=${this.amlUserData.companyName}&cx=${cx}&key=${this.adverseKey}&num=10&filter=0` ;
		}
		else if(this.amlUserData["firstName"]){
			this.adverseMediaQuery = `https://www.googleapis.com/customsearch/v1?q=${this.amlUserData.firstName}&cx=${cx}&key=${this.adverseKey}&num=10&filter=0` ;
		}

		if(this.amlUserData["exactTerms"] && this.amlUserData["exactTerms"].length){
			this.adverseMediaQuery = this.adverseMediaQuery + `&exactTerms=${this.amlUserData["exactTerms"]}`;
		}
		if(this.amlUserData["excludeTerms"] && this.amlUserData["excludeTerms"].length){
			this.adverseMediaQuery = this.adverseMediaQuery + `&excludeTerms=${this.amlUserData["excludeTerms"]}`;
		}
		if(this.amlUserData["dateRestrict"] && this.amlUserData["dateRestrict"].length){
			this.adverseMediaQuery = this.adverseMediaQuery + `&dateRestrict=${this.amlUserData["dateRestrict"]}`;
		}
		if(this.amlUserData["cr"] && this.amlUserData["cr"].length){
			this.adverseMediaQuery = this.adverseMediaQuery + `&cr=${this.amlUserData["cr"].trim()}`;
		}
		if(this.amlUserData["lr"] && this.amlUserData["lr"].length){
			this.adverseMediaQuery = this.adverseMediaQuery + `&lr=${this.amlUserData["lr"].trim()}`;
		}
		if(this.amlUserData["orTerms"] && this.amlUserData["orTerms"].length){
			this.adverseMediaQuery = this.adverseMediaQuery + `&orTerms=${this.amlUserData["orTerms"].trim()}`;
		}
		console.log(this.amlUserData["category"])
		if(this.amlUserData.category["name"]&& this.amlUserData.category["name"].length){
			this.adverseMediaQuery = this.adverseMediaQuery + `&category=${this.amlUserData.category["name"].trim()}`;
		}
		console.log(this.adverseMediaQuery);
		this.doAdverseMediaSearch(this.adverseMediaQuery , true);
	}

	clearAdverseFilters(){
		let cx = this.amlUserData["category"].cx ;
		let oldKey = "AIzaSyDNF4O0QlGsON-HdVsAIyG3AuJdDFlM2Eo" ;
		let newKey = "AIzaSyBEJoqv95Mt6Kly71KOhOoNuNgwEYIW3S8" ;
		this.amlUserData["exactTerms"] = null ;
		this.amlUserData["excludeTerms"] = null ;
		this.amlUserData["dateRestrict"] = null ;
		this.amlUserData["cr"] = null ;
		this.amlUserData["lr"] = null ;
		this.amlUserData["orTerms"] = null ;
		if(this.amlUserData["firstName"] && this.amlUserData["lastName"] ){
			this.adverseMediaQuery = `https://www.googleapis.com/customsearch/v1?q=${this.amlUserData.firstName}+${this.amlUserData.lastName}&cx=${cx}&key=${this.adverseKey}&num=10&filter=0` ;
		}
		else if(this.amlUserData["fullName"]){
			this.adverseMediaQuery = `https://www.googleapis.com/customsearch/v1?q=${this.amlUserData.fullName}&cx=${cx}&key=${this.adverseKey}&num=10&filter=0` ;
		}
		else if(this.amlUserData["companyName"]){
			this.adverseMediaQuery = `https://www.googleapis.com/customsearch/v1?q=${this.amlUserData.companyName}&cx=${cx}&key=${this.adverseKey}&num=10&filter=0` ;
		}
		else if(this.amlUserData["firstName"]){
			this.adverseMediaQuery = `https://www.googleapis.com/customsearch/v1?q=${this.amlUserData.firstName}&cx=${cx}&key=${this.adverseKey}&num=10&filter=0` ;
		}
		this.doAdverseMediaSearch(this.adverseMediaQuery , true);
	}

	parseAdverMediaQuery(){
		console.log(this.adverseMediaQuery);
	}

	doAdverseMediaSearch(query , showLoader?:boolean){
		let data = {
			"url"   : query,
			"source": "GOOGLE"
		}
		if(showLoader){
			this._data.changeLoaderVisibility(true);
		}
		this._app.doAdverseMediaSearch(data , this.hashId)
		.subscribe(
			(res)=>{
				this._data.changeLoaderVisibility(false);
				this.amlData["media"] = JSON.parse(res.data) ;				
				// if(!showLoader){
					this.totalAdverseMediaResults = this.amlData["media"].searchInformation.totalResults
				// }
				console.log(this.amlData["media"]);
				//Sending empty arr , when no results found 
				console.log(this.totalAdverseMediaResults);
				console.log(typeof this.totalAdverseMediaResults);				
				if(this.totalAdverseMediaResults == 0){
					let obj = {
						"hasDoneAdverseSearch"   : true,
						"gotAdverseSearchResults": false,
						"adverseMediaItems"      :  this.adverseSelectedResult
					}
					this.getAdverseResults.emit(obj);
				}
				else{
					let obj = {
						"hasDoneAdverseSearch"   : true,
						"gotAdverseSearchResults": true,
						"adverseMediaItems"      :  this.adverseSelectedResult
					}
					this.getAdverseResults.emit(obj);
				}
			},
			(err)=> { 
				let obj = {
						"hasDoneAdverseSearch"   : true,
						"gotAdverseSearchResults": false,
						"adverseMediaItems"      :  this.adverseSelectedResult
					}
				this.getAdverseResults.emit(obj);
				this._data.changeLoaderVisibility(false) 
			}
		);
	}

	async getSelectedAMLData(key , page , sort){
    	let query = {
			"sourceType": key,
			"page"      : page,
			"size"      : this.itemsPerPage,
			"sort"      : sort
		}
		if(this.canChangeCheckStatus && this.amlEntityGuid){
			this.amlData[key] = (await this.getAMlData(query).pipe(first()).toPromise()).data;
		}
		else if(!this.canChangeCheckStatus){
			this.amlData[key] = (await this.getSavedAMlData(query).pipe(first()).toPromise()).data;
		}
		this.patchForDataCleaning(key);
		console.log(this.amlData);

	}

	getAMlData(query){
		query["sourceType"] = query["sourceType"].toUpperCase();
		if(this.amlEntityGuid){
			return this._app.getSelectedAMLData(this.hashId , this.amlEntityGuid , query) ;
		}
		else{
			// return null ;
			return of(null);
		}
	}

	getSavedAMlData(query){
		query["amlCheckGUID"] = this.getEndUserCheckID(query["sourceType"]);
		query["sourceType"] = query["sourceType"].toUpperCase();
		return this._app.getSavedAMlData(query) ;
	}

	patchForDataCleaning(key){
		if(key == "pep"){
			this.amlData["pep"] = (this.amlData.pep && this.amlData.pep["pepCheck"]) ? this.amlData.pep["pepCheck"] : null ;
		}
		if(key == "crime"){
			this.amlData["crime"] = (this.amlData.crime && this.amlData.crime["crimeCheck"]) ? this.amlData.crime["crimeCheck"] : null;
		}
		if(key == "sanction"){
			this.amlData["sanction"] = (this.amlData.sanction && this.amlData.sanction["sanctionCheck"]) ? this.amlData.sanction["sanctionCheck"] : null  ;
		}

		// if(this.amlData[key] && this.amlData[key].matchFound){
			if(this.amlData[key]){
		this.amlSelectedResults.gotAMLSearchResults = true ;
			this.getAMLSelectedResults.emit(this.amlSelectedResults) ;
		}
	}

	flagAMLCheck(data , key){
		// let prevStatus = this.amlData[key].results.content.filter(obj=>obj[key].guid == data[key].guid)[0].isActive ;
		// this.amlData[key].results.content.filter(obj=>obj[key].guid == data[key].guid)[0].isActive = (prevStatus == "ACTIVE") ? "INACTIVE" : "ACTIVE" ;
		// let updatedSatus = this.amlData[key].results.content.filter(obj=>obj[key].guid == data[key].guid)[0].isActive;

		if(key == "pep"){
			// let indexValue = this.amlSelectedResults["crimeSourceGUIDs"].indexOf(data.amlEntity);
			 let indexValue = this.amlSelectedResults["pepSourceGUIDs"].indexOf(data.guid);
			 if(indexValue !== -1){
				 this.amlSelectedResults["pepSourceGUIDs"].splice(indexValue, 1);
				 
			 }
			 else{
				this.amlSelectedResults["pepSourceGUIDs"].push(data.guid) ;
				//this.amlSelectedResults["pepSourceGUIDs"].push(data.amlEntity) ;
				 console.log(data.amlEntity);
				 console.log()
				 console.log(data.guid)
			 }
		}

		if(key == "crime"){
			//  let indexValue = this.amlSelectedResults["crimeSourceGUIDs"].indexOf(data.amlEntity);
			let indexValue = this.amlSelectedResults["crimeSourceGUIDs"].indexOf(data.guid);
			 if(indexValue !== -1){
			 	this.amlSelectedResults["crimeSourceGUIDs"].splice(indexValue, 1);
			 }
			 else{
				 // this.amlSelectedResults["crimeSourceGUIDs"].push(data.amlEntity) ;
				 this.amlSelectedResults["crimeSourceGUIDs"].push(data.guid) ;
			 }
		}

		if(key == "sanction"){
			//  let indexValue = this.amlSelectedResults["sanctionSourceGUIDs"].indexOf(data.amlEntity);
			let indexValue = this.amlSelectedResults["sanctionSourceGUIDs"].indexOf(data.guid);
			 if(indexValue !== -1){
			 	this.amlSelectedResults["sanctionSourceGUIDs"].splice(indexValue, 1);
			 }
			 else{
				 // this.amlSelectedResults["sanctionSourceGUIDs"].push(data.amlEntity) ;
				 this.amlSelectedResults["sanctionSourceGUIDs"].push(data.guid) ;
			 }
		}

		for(var objKey in this.amlData){
			if(objKey !== 'media'){
				if(this.amlData[objKey].matchFound){
					this.amlSelectedResults.gotAMLSearchResults = true ;
				}
			}
		}
		this.amlSelectedResults.amlComment = this.amlComment ;
		this.getAMLSelectedResults.emit(this.amlSelectedResults) ;
		console.log(this.amlSelectedResults);
	}

	sendComment(){
		for(var objKey in this.amlData){
			if(objKey !== 'media'){
				// if(this.amlData[objKey].matchFound){
					if(this.amlData[objKey] === null){
					this.amlSelectedResults.gotAMLSearchResults = true ;
				}
			}
		}
		this.amlSelectedResults.amlComment = this.amlComment ;
		this.getAMLSelectedResults.emit(this.amlSelectedResults) ;
	}

	flagAdverseResult(data){
		console.log(data);
		let flag =true;
		if(this.adverseSelectedResult.length){
			for(let i = 0 ; i < this.adverseSelectedResult.length ; i++){
				if(this.adverseSelectedResult[i].cacheId === data.cacheId){
					console.log("splice" + i);
					this.adverseSelectedResult.splice(i, 1);
					flag=false;
					break;
				}
				
			}
			if(flag){
				console.log("push" + flag,data);
				this.adverseSelectedResult.push(data) ;
				
			}
		}
		else{
			this.adverseSelectedResult.push(data) ;
		}
		let obj = {
			"hasDoneAdverseSearch"   : this.hasDoneAdverseSearch ,
			"gotAdverseSearchResults": true,
			"adverseMediaItems"      : this.adverseSelectedResult
		}
		this.getAdverseResults.emit(obj);
		console.log(this.adverseSelectedResult);
	}

	isAdverseItemSelected(data){
		for( let i = 0 ; i < this.adverseSelectedResult.length ; i++){
			if(this.adverseSelectedResult[i].cacheId === data.cacheId){
				return true ;
			}
		}
		return false;
	}

	isMarked(data , key){
		if(key == "pep"){
			//let indexValue = this.amlSelectedResults["sanctionSourceGUIDs"].indexOf(data.amlEntity);
			let indexValue = this.amlSelectedResults["pepSourceGUIDs"].indexOf(data.guid);
			if(indexValue == -1){
				return false;
				
			}
		}
		if(key == "sanction"){
			//let indexValue = this.amlSelectedResults["sanctionSourceGUIDs"].indexOf(data.amlEntity);
			let indexValue = this.amlSelectedResults["sanctionSourceGUIDs"].indexOf(data.guid);
			if(indexValue == -1){
				return false;
			}
		}
		if(key == "crime"){
			//let indexValue = this.amlSelectedResults["crimeSourceGUIDs"].indexOf(data.amlEntity);
			let indexValue = this.amlSelectedResults["crimeSourceGUIDs"].indexOf(data.guid);
			if(indexValue == -1){
				return false;
			}
		}
		return true;
	}

	getEndUserCheckID(type){
		let slug = type.toLowerCase() == 'sanction' ? "sanctions-check" : `${type.toLowerCase()}-check`
		let arr = ["sanctions-check" , "pep-check" , "crime-check" ];
		let isPresent = this.usersAMLChecks.filter(obj=>obj.standardCheck.slug == slug) ;
		if(isPresent.length){
			return isPresent[0].endUserCheckID ;
		}
		else{
			return null;
		}
	}

    getPaginatedData(isNext , key){
        this.amlData[key].results.number = isNext ? this.amlData[key].results.number + 1 : this.amlData[key].results.number - 1 ;
        if(this.amlData[key].results.number < this.amlData[key].results.totalPages){
            this.getSelectedAMLData(key , this.amlData[key].results.number , "desc");
        }
    }

    getPaginatedText(key){
        let a = (this.amlData[key].results.number * this.itemsPerPage) + 1 ;
        let b ;
        if(this.amlData[key].results.number < (this.amlData[key].results.totalPages - 1)){
            b = (this.amlData[key].results.number + 1 ) * this.itemsPerPage;
        }
        else if(this.amlData[key].results.number == (this.amlData[key].results.totalPages - 1 )){
            b = this.amlData[key].results.totalElements;
        }
        return  a+"-"+b ;
	}
	// Page bar and jumpto functionalities
	

    getAdverseMediaNewImage(data){
		if(data.pagemap 
			&& data.pagemap.cse_image 
			&& data.pagemap.cse_image.length
			&& data.pagemap.cse_image[0] && data.pagemap.cse_image[0]){
			return data.pagemap.cse_image[0].src ;
		}
		else if(data.pagemap 
			&& data.pagemap.cse_thumbnail 
			&& data.pagemap.cse_thumbnail.length
			&& data.pagemap.cse_thumbnail[0] && data.pagemap.cse_thumbnail[0]){
			return data.pagemap.cse_thumbnail[0].src ;
		}
		else{
			// return "https://www.kychub.com/dashboard/assets/img/avatar/avatar.png"
			return "assets/images/profile-picture.png" ;
		}
    }

    getAdversePaginatedData(startIndex){
    	let query = this.adverseMediaQuery ;
    	query = query + `&start=${startIndex}`
    	this.doAdverseMediaSearch(query , true);
    }

    showNewsDetail(news){
    	const initialState = {
			title   : `News Detail`,
			type    : "news-detail" ,
			newsData: news
        };
        this.bsModalRef = this.modalService.show(KycModalComponent, {
            initialState,
            class: "modal-lg"
        });
        this.bsModalRef.content.closeBtnName = "Close";
        console.log(this.bsModalRef.content.onClose);
        this.bsModalRef.content.onClose.subscribe(result => {
            console.log("results", result);
        });
    }

    getHeroName(){
    	let providedFName = (this.amlUserData && this.amlUserData["firstName"]) ? this.amlUserData["firstName"] : null ; 
    	let providedLName = (this.amlUserData && this.amlUserData["lastName"]) ? this.amlUserData["lastName"] : null ; 
    	let providedFullName = (this.amlUserData && this.amlUserData["companyName"]) ? this.amlUserData["companyName"] : null ; 
		var reqName ;
		for(var key in this.amlData){
			if(this.amlData[key] 
    		&& this.amlData[key].results 
    		&& this.amlData[key].results.content 
    		&& this.amlData[key].results.content.length
    		&& this.amlData[key].results.content[0][key]){
				reqName =  this.amlData[key].results.content[0][key].amlEntity.fullName
    		}
		}
		if(reqName){
			return reqName;
		}
		else if(providedFName && providedLName){
			return `${providedFName}  ${providedLName} `;
		}
		else if(providedFName && !providedLName){
			return `${providedFName}`;
		}
		else if(!providedFName && providedLName){
			return `${providedLName}`;
		}
		else if(!providedFName && !providedLName){
			return `${providedFullName}` ;
		}
		return "No Name Found" ;
    }

}
