import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PipesModule } from "./../../../pipes/pipes.module" ;
import { AmlSelectedResultComponent } from "./aml-selected-result.component" ;

import { ShowImageModule } from './../../../directives/show-image/show-image.module';

import { FormsModule } from "@angular/forms";

@NgModule({
  declarations: [ AmlSelectedResultComponent ],
  imports: [
    CommonModule,
    FormsModule,
    PipesModule,
    ShowImageModule
  ],
  exports: [ AmlSelectedResultComponent ]
})
export class AmlSelectedResultModule { }
