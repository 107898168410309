import { Component,Input, OnInit,ElementRef, ViewChild, AfterViewInit,Inject, } from "@angular/core";
import { Router, ActivatedRoute, Params, NavigationEnd } from "@angular/router";
import { DataService } from "../../../../services/data.service";
import { AppService } from "../../../../services/app.service";
import { AmlSearchService } from "./../../aml-search.service";
import { Output, EventEmitter  } from '@angular/core';
import { forkJoin } from "rxjs";
import { first } from "rxjs/operators";
import { Observable, of } from "rxjs";
// import { TreeModel, NodeEvent } from 'ng2-tree';
//import * as vis from "vis";
import * as jsPDF from "jspdf";
// import html2canvas from "html2canvas";

import * as html2canvas from "html2canvas";
import { async } from "@angular/core/testing";
// declare var html2canvas: any;

// declare var vis: any;

// import {
//     VisEdges,
//     VisNetworkData,
//     VisNetworkOptions,
//     VisNetworkService,
//     VisNode,
//     VisNodes } from './components/index'

@Component({
  selector: 'app-profile-details',
  templateUrl: './profile-details.component.html',
  styleUrls: ['./profile-details.component.scss']
})
export class ProfileDetailsComponent implements OnInit {
    guid: string;
    @Input() amlDetails: any;
  
    adverseMediaScore:any=0;
    adverseMediaInteliigenceScore:any=0;
    selectedOption: any = "Overview";
    isLvlOneGraphLoaded: boolean = false;
    activeRelations: any[] = [];
    idAMLCheck:any;
    guidAMLSubmit:any;
    leftPanelItems: any[] = [
      {
        name: "Overview",
        icon: "fa-list-alt",
        value: null,
      },
      {
        name: "Summary",
        icon: "fa-info-circle",
        value: null,
      },
      {
        name: "Aliases [Names & DOBs]",
        icon: "fa-user",
        value: null,
      },
      {
        name: "Identifiers",
        icon: "fa-id-card-o",
        value: null,
      },
      {
        name: "Relationships",
        icon: "fa-users",
        // "value" : relationKeys
        value: null,
      },
      {
        name: "Addresses",
        icon: "fa-address-book-o",
        value: null,
      },
      {
        name: "Adverse Media",
        icon: "fa-newspaper-o",
        value: null,
      },
      {
        name: "Data Sources",
        icon: "fa-external-link",
        value: null,
      },
    ];
  
    loadExternalFile: Promise<any>;
  
    directLinkAccess: boolean = false;
  
    //Visjs
    //@ViewChild("vis") element: ElementRef;
    //network: vis.Network;
  
    nodes: any[] = [];
    edges: any[] = [];
    relations: any[] = [];
  
    heroNodeId: any;
    subCategory: any;
    isNews:boolean=true;
    showCrime:boolean=false;
    showSanction:boolean=false;
    showPep:boolean=false;
    isTagFound:boolean=false;
    articledatas:any=[];
    advmedialist:boolean=false;
    pepData:any=[];
    santionData:any=[];
    newsData:any=[];
    distTags:any=[];
    watchList:any=[];
    crimeData:any=[];
    showWatchlist:boolean=false;
  
    loopCount=[1,2,3];
  
    constructor(
      @Inject("Window") private window: Window,
      private _data: DataService,
      private _app: AppService,
      public router: Router,
      private _aml: AmlSearchService,
      private activatedRoute: ActivatedRoute
    ) {
      this.activatedRoute.params.subscribe(params => {
        if (Object.keys(params).length) {
          this.guid = params["guid"];
          if (!this.amlDetails) {
            this.directLinkAccess = true;
            this.getDetailedRecord(this.guid);
          }
        }
      });
    }
  
    ngOnInit() {
      this._data.changeLoaderVisibility(true);
      console.log("aml details:",this.amlDetails)
      this.showCrime=false;
      this.showSanction=false;
      this.hideLeftPanel();
      this._data.changeLoaderVisibility(true);
      let payload={
        name:this.amlDetails.basicProfile.name
      }
      this._aml.getRecentArticles(payload).subscribe(result=>{
        this.newsData=result;
        
      })
        if(this.amlDetails.relatedData.length>0){
          if(this.amlDetails.profileRisk && this.amlDetails.profileRisk.tags){
            this.amlDetails.profileRisk.tags.forEach(element => {
              let isAvail= this.distTags.length>0?this.distTags.some(d=>d.name==element.name):false;
              console.log("is Avail: ",isAvail);
              if(isAvail == false)
              {
                this.distTags.push(element);
              }
            }); 
          }
          this.amlDetails.relatedData.forEach(obj => {
            if(obj.service=='AdverseMedia'){
              this.amlDetails["AdverseMedia"] = obj;  
              var tags = this.amlDetails["AdverseMedia"]['data']['value']['tags'];
              console.log("adverse media:",this.amlDetails["AdverseMedia"]['data']['value']['tags'], tags);
              this.isTagFound=true;
              for(var i=0; tags.length>i; i++){
                  this.adverseMediaScore += tags[i].aggregateScore;
                  if(i==0){
                    this.getArticle(tags[i].article);
                  }
              }
              this.adverseMediaInteliigenceScore = this.adverseMediaScore/tags.length;
            }
            else if(obj.category.toUpperCase()=='PEP'){
              this.pepData.push(obj);  
              this.showPep = true;
            }
            else if(obj.category.toUpperCase()=='PERSON'){
              this.amlDetails["person"] = obj;  
            }
            else if(obj.category.toUpperCase()=='CRIME'){
              this.crimeData.push(obj); 
              this.showCrime = true; 
            }
            else if(obj.category.toUpperCase()=='SANCTION'){
              this.santionData.push(obj)
              this.showSanction = true;
            }
            else if(obj.service.toUpperCase()== 'ADVERSEMEDIA'){
              this.amlDetails["AdverseMedia"]=obj;
            }
            else if(obj.category.toUpperCase()== 'WATCHLIST'){
              this.watchList.push(obj)
              this.showWatchlist=true;
            }
  
            console.log("crime; ",this.crimeData);
            console.log("pep: ",this.pepData)
            console.log("sanction; ",this.santionData);
            console.log("adverse : ",this.amlDetails["AdverseMedia"])
  
            console.log("watch list",this.watchList);
          });
        }
        this._data.changeLoaderVisibility(false);
      if (!this.directLinkAccess) {
        // this.lvlOneGraphData();
        // this.getGraphFilters();
      }
      // this.amlDetails = AMLResultData.data.content[1] ;
      // console.log(this.amlDetails);
      // console.log("Details",this.amlDetails.subCategory.name);
      // this.subCategory=this.amlDetails.subCategory.name.split("_")
      // if(this.subCategory[1]==undefined){
      //   this.subCategory[1]="";
      // }
      // console.log("ind",this.subCategory)
      // this.loadExternalFile = new Promise((resolve) => {
      //        this.loadFiles();
      //        resolve(true);
      //    });
  
  
    }
  
    @Output() searchRelation: EventEmitter<string> = new EventEmitter<string>();
  
    doSearchRelation(name){
      console.log(name);
      this.searchRelation.emit(name);
      }
  
    hideLeftPanel() {
      var leftElem = document.getElementById("wrapper");
      leftElem.classList.add("forced");
      leftElem.classList.add("enlarged");
    }
  
    // ngAfterViewInit(): void {
    //   const nodes = new vis.DataSet([
    //     { id: 1, label: "Node 1" },
    //     { id: 2, label: "Node 2" },
    //     { id: 3, label: "Node 3" },
    //     { id: 4, label: "Node 4" },
    //     { id: 5, label: "Node 5" },
    //   ]);
  
    //   const edges = new vis.DataSet([
    //     { from: 1, to: 3 },
    //     { from: 1, to: 2 },
    //     { from: 2, to: 4 },
    //     { from: 2, to: 5 },
    //   ]);
  
    //   const data = {
    //     nodes: nodes,
    //     edges: edges,
    //   };
  
    //   const options = {};
  
    //   this.network = new vis.Network(this.element.nativeElement, data, options);
    // }
  
    getRelationLvlGraph(rel) {
      console.log(rel);
      let dataQuery = {
        id: this.heroNodeId ? this.heroNodeId : null,
        relation: rel,
      };
      this.nodes = [];
      this.edges = [];
      this._aml.getNodeRelation(dataQuery).subscribe(response => {
        let nodes = response.nodes;
        let edges = response.links;
        if (nodes && nodes.length && edges && edges.length) {
          for (var i = 0; i < nodes.length; i++) {
            nodes[i].shape = "circularImage";
            nodes[i].id = nodes[i].nodeId;
            nodes[i].image =
              "https://www.thetaranights.com/wp-content/uploads/2018/fiverr_reviews/Benf97/cropped(1).png";
            nodes[i].title = nodes[i].description;
            nodes[i].label = ` ${nodes[i].fullName ||
              nodes[i].name ||
              nodes[i].companyName}`;
            this._aml.pushToArray(this.nodes, nodes[i]);
          }
          for (var i = 0; i < edges.length; i++) {
            edges[i].from = edges[i].source;
            edges[i].to = edges[i].target;
          }
          // this.nodes = nodes;
          this.edges = edges;
          console.log(response);
        }
      });
    }
  
    getGraphFilters() {
      let dataQuery = {
        name: this.amlDetails.fullName,
        guid: this.amlDetails.guid,
      };
      this._aml.getGraphFilters(dataQuery).subscribe(response => {
        this.leftPanelItems.filter(
          obj => obj.name == "Relationships"
        )[0].value = response;
        // this.activeRelations = response;
        console.log(response);
      });
    }
  
    lvlOneGraphData() {
      let dataQuery = {
        name: this.amlDetails.fullName,
        guid: this.amlDetails.guid,
      };
      this._aml.lvlOneGraphData(dataQuery).subscribe(response => {
        let nodes = response.nodes;
        let edges = response.links;
        if (nodes && nodes.length && edges && edges.length) {
          this.isLvlOneGraphLoaded = true;
          for (var i = 0; i < nodes.length; i++) {
            nodes[i].shape = "circularImage";
            nodes[i].id = nodes[i].nodeId;
            nodes[i].image =
              "https://www.thetaranights.com/wp-content/uploads/2018/fiverr_reviews/Benf97/cropped(1).png";
            nodes[i].title = nodes[i].description;
            nodes[i].label = ` ${nodes[i].fullName ||
              nodes[i].name ||
              nodes[i].companyName}`;
            this._aml.pushToArray(this.nodes, nodes[i]);
          }
          var nodeId = response.nodes.findIndex(e => {
            return e.guid === this.amlDetails.guid;
          });
          console.log('node : ', response.nodes[nodeId]);
          // console.log(this._aml.pushToEdge(this.nodes,response.links, response.nodes[nodeId].nodeId));
          var data : any  = this._aml.pushToEdge(this.nodes,response.links, response.nodes[nodeId].nodeId);
          edges = data.final;
          console.log(data);
          this.relations = data.relations;
  
          console.log(edges);
          for (var i = 0; i < edges.length; i++) {
            edges[i].from = edges[i].source;
            edges[i].to = edges[i].target;
          }
          console.log('index of node : ',this.nodes)
          this.leftPanelItems.filter(obj => obj.name == "Relationships")[0].value = (this.nodes.length) ? 1 : null;
          // this.nodes = nodes;
          this.edges = edges;
          // this.heroNodeId = edges[0].from;
          console.log(response);
        }
      });
    }
  
    // loadFiles(){
    // 	var isFound = false;
    //     var scripts = document.getElementsByTagName("script")
    //     for (var i = 0; i < scripts.length; ++i) {
    //         if (scripts[i].getAttribute('src') != null && scripts[i].getAttribute('src').includes("vis")) {
    //             isFound = true;
    //         }
    //     }
  
    //     if (!isFound) {
    //         var dynamicScripts = ["https://cdnjs.cloudflare.com/ajax/libs/vis/4.21.0/vis.min.js"];
    //         for (var i = 0; i < dynamicScripts .length; i++) {
    //             let node = document.createElement('script');
    //             node.src = dynamicScripts [i];
    //             node.type = 'text/javascript';
    //             node.async = false;
    //             node.charset = 'utf-8';
    //             document.getElementsByTagName('head')[0].appendChild(node);
    //         }
    //     }
    // }
  
    changeSelectedItem(item) {
      this.selectedOption = item;
      // let block = option == 'profile' ? "start": "end" ;
      var elem = document.getElementById(item);
      elem.scrollIntoView({
        behavior: "smooth",
        block: item == "profile" ? "start" : "end",
      });
    }
  
    getDetailedRecord(guid) {
      this._data.changeLoaderVisibility(true);
      this._aml.getDetailedRecord(guid).subscribe(res => {
        this._data.changeLoaderVisibility(false);
        this.amlDetails = res.data.content[0];
        this.lvlOneGraphData();
        this.getGraphFilters();
        this.getDocumentImage();
        console.log("AML DETAILS: ", this.amlDetails);
        // setTimeout(()=>{
        // 	this.doGraphThing()	;
        // },2000)
      });
    }
  
    async getDocumentImage() {
      if (
        this.amlDetails &&
        this.amlDetails.identifiers &&
        this.amlDetails.identifiers.length
      ) {
        for (var i = 0; i < this.amlDetails.identifiers.length; i++) {
          let country = this.amlDetails.identifiers[i].country.name;
          let identifierType = this.amlDetails.identifiers[i].identifierType;
          let documentImage = (await this._app
            .getAllowedDocs(country)
            .pipe(first())
            .toPromise()).data;
          let allowedIdDocs = documentImage.validIdentityDocs;
          let isDocPresent = allowedIdDocs.filter(
            obj => obj.docName.toLowerCase() == identifierType.toLowerCase()
          );
          if (isDocPresent && isDocPresent.length) {
            this.amlDetails.identifiers[i].documentImage =
              isDocPresent[0].docImageUrl;
          } else {
            this.amlDetails.identifiers[i].documentImage = null;
          }
        }
      }
    }
  
    download() {
      const div = document.getElementById("detailCont");
      const options = {
        height: div.clientHeight,
        width: div.clientWidth,
      };
  
      console.log(options);
  
      html2canvas(div, options).then(canvas => {
        console.log("from html ");
        //Initialize JSPDF
        let doc = new jsPDF("p", "mm", "a4");
  
        var width = doc.internal.pageSize.getWidth();
        var height = doc.internal.pageSize.getHeight();
  
        //Converting canvas to Image
        let imgData = canvas.toDataURL("image/PNG");
        //Add image Canvas to PDF
        doc.addImage(imgData, "PNG", 0, 0, width, height);
  
        let pdfOutput = doc.output();
        // using ArrayBuffer will allow you to put image inside PDF
        let buffer = new ArrayBuffer(pdfOutput.length);
        let array = new Uint8Array(buffer);
        for (let i = 0; i < pdfOutput.length; i++) {
          array[i] = pdfOutput.charCodeAt(i);
        }
  
        //Name of pdf
        const fileName = `${this.amlDetails.fullName}.pdf`;
  
        // Make file
        doc.save(fileName);
      });
    }
    removeDot(d){
      if(d){
        if(d.includes('.')){
          return d;
        }else{
          return d + '.';
        }
      }else{
        return d;
      }
    }
    
    saveAMLSearchData(){
      // console.log(this.amlDetails.details[0]);
      let data = this.amlDetails.details[0];
      let dataQuery={};
      if(data.entityType.toUpperCase() === 'INDIVIDUAL'){
      dataQuery={
          firstName: data.firstName,
          lastName:data.lastName,
          identityInfo:"",
          dob:"",
          mobileNumber:"",
          countryCode:"",
          identityInfoType:"NAME",
          remoteChecks:null,
          dashboardChecks:["sanctions-check","crime-check","pep-check"],
          endUserType: data.entityType.toUpperCase()
        };
      }else if(data.entityType.toUpperCase() === 'ORGANISATION'){
        dataQuery={
            companyName: data.fullName,
            identityInfoType:"NAME",
            remoteChecks:null,
            dashboardChecks:["sanctions-check","crime-check"],
            endUserType: "CORPORATE"
          };
        }
      // console.log(dataQuery);
      
      this._aml.saveAMLSearchData(dataQuery).subscribe(
        response =>{
          console.log(response);
          console.log(response.data.checks.endUser.identityInfo);
          this.idAMLCheck = response.data.checks.id;
          this.guidAMLSubmit = response.data.checks.endUser.identityInfo;
          this.submitAMLSearchData(data.guid,this.idAMLCheck)
          // this._data.toastrShow("Aml search result saved successfully",'success')
        },
        err => {
          console.log(err)
        }
      );
      console.log(this.guidAMLSubmit,this.idAMLCheck);
     
      
    }   
    submitAMLSearchData(guidAMLSubmit,idAMLCheck){
      let data = this.amlDetails.details[0];
      let dataSubmitQuery = {}
      if(data.category.name.toUpperCase() === 'PEP'){
        dataSubmitQuery = {
          pepSourceGUIDs:[guidAMLSubmit],
          sanctionSourceGUIDs: [],
          crimeSourceGUIDs: [],
          gotAMLSearchResults: true
        }
      }else if(data.category.name.toUpperCase() === 'SANCTION'){
        dataSubmitQuery = {
          pepSourceGUIDs:[],
          sanctionSourceGUIDs: [guidAMLSubmit],
          crimeSourceGUIDs: [],
          gotAMLSearchResults: true
        }
      }else if(data.category.name.toUpperCase() === 'CRIME'){
        dataSubmitQuery = {
          pepSourceGUIDs:[],
          sanctionSourceGUIDs: [],
          crimeSourceGUIDs: [guidAMLSubmit],
          gotAMLSearchResults: true
        }
      }else{
        dataSubmitQuery = {
            pepSourceGUIDs:[],
            sanctionSourceGUIDs: [],
            crimeSourceGUIDs: [],
            gotAMLSearchResults: false
          }
      }
      
      this._aml.submitAMLSearchData(dataSubmitQuery,idAMLCheck).subscribe(
        response =>{
          console.log(response);
          this._data.toastrShow("Aml search result saved successfully",'success')
        },
        err => {
          console.log(err)
        }
      );
    } 
  
    // akash : save and exit API
    saveAMLData(){
      let dataQuery={
        amlGUIDs:this.amlDetails.guid,
        identityInfoType: "NAME",
        dashboardChecks: [(this.amlDetails.category.name).toLowerCase()+"-check"],
        endUserType:  (this.amlDetails.entityType =='Organisation') ? "CORPORATE":this.amlDetails.entityType.toUpperCase() 
      };
      this._aml.saveAMLData(dataQuery).subscribe(
        response =>{
          console.log(response);
          this._data.toastrShow("Aml search result saved successfully",'success')
        },
        err => {
          console.log(err)
        }
      );
    } 
    
    getArticle(val){
      this.articledatas=[];
      for (let article of val){
        this._aml.ittsArticle(article.id).subscribe((res) =>{
          this.articledatas.push(res.data);       
        });
      }
      this.advmedialist=true;
      console.log("article data: ",this.articledatas)
    }

  }

  export const relationKeys: any[] = [
    {
      name: "Family",
      slug: "Family",
      value: null,
      relation: null,
    },
    {
      name: "Close Associates",
      slug: "associates",
      value: null,
      relation: null,
    },
    {
      name: "Organisations & Corporates",
      slug: "org",
      value: null,
      relation: null,
    },
    {
      name: "Person of Significant Control (PSC)",
      slug: "psc",
      value: null,
      relation: null,
    },
    {
      name: "Ultimate Beneficial Owner (UBO)",
      slug: "ubo",
      value: null,
      relation: null,
    },
  ];
  
  export const graphRelations: any[] = [
    {
      name: "Jurisdiction",
      value: "JURADICTION_OF",
    },
    {
      name: "Details",
      value: "DETAIL_INFO",
    },
    {
      name: "Officer",
      value: "OFFICER_OF",
    },
    {
      name: "PSC",
      value: "PSC_OF",
    },
    {
      name: "Address",
      value: "ADDRESS",
    },
    {
      name: "Category",
      value: "Category",
    },
    {
      name: "SubCategory",
      value: "SubCategory",
    },
    {
      name: "Source",
      value: "From_Source",
    },
    {
      name: "Primary Country",
      value: "Primary_Country_of",
    },
    {
      name: "Allegation Country",
      value: "Allegation_Country_of",
    },
    {
      name: "Son",
      value: "Son",
    },
    {
      name: "Father",
      value: "Father",
    },
    {
      name: "Associate",
      value: "Associate",
    },
  ];
  

  
