import { Component, OnInit , Input , ViewChildren , ViewChild , ElementRef} from '@angular/core';
import { Router, ActivatedRoute, Params, NavigationEnd } from "@angular/router";
import { DataService } from "../../services/data.service";
import { AppService } from "../../services/app.service";
import { DomSanitizer} from '@angular/platform-browser';
import { first } from 'rxjs/operators';

import {Address} from "../../models/Address.model";
import {UserDoc} from "../../models/UserDoc.model";
@Component({
  selector: 'app-show-preview',
  templateUrl: './show-preview.component.html',
  styleUrls: ['./show-preview.component.scss']
})
export class ShowPreviewComponent implements OnInit {

	@Input() hashId         : any;
	
	// permanentAddress     : Address ; 
	// correspondenceAddress: Address ; 
	
	addressDocProcessedInfo : any ;
	amlProcessedInfo        : any = {
			"crimeCheck"   : null,
			"pepCheck"     : null,
			"sanctionCheck": null
	};
	identityDocProcessedInfo: any ;
	livenessProcessedInfo   : any ;

	constructor(
		private _data : DataService,
		public router: Router,
		public sanitizer:DomSanitizer,
		private _app : AppService){
	}

	ngOnInit(){
		this.getPreviewData();
	}

	getPreviewData(){
		this._app.getPreviewData(this.hashId)
		.subscribe(
			(res)=>{
				this.mapResponseData(res.data);
			}
		);
	}

	transform(html) {
    	return this.sanitizer.bypassSecurityTrustResourceUrl(html);
  	}

	mapResponseData(data){
		this.addressDocProcessedInfo   = data.addressDocProcessedInfo;
		this.amlProcessedInfo          = data.amlProcessedInfo;
		this.identityDocProcessedInfo  = data.identityDocProcessedInfo;
		this.livenessProcessedInfo     = data.livenessProcessedInfo;
		if(this.identityDocProcessedInfo){
			this.getDocFromServer(this.identityDocProcessedInfo.endUserDocInfo.endUserCheck.endUserCheckID , true);
			this.identityDocProcessedInfo.faceUrl = `data:image/png;base64,${this.identityDocProcessedInfo.faceUrl}`;
		}
		if(this.addressDocProcessedInfo){
			this.getDocFromServer(this.addressDocProcessedInfo.endUserDocInfo.endUserCheck.endUserCheckID , false);
		}

		//Remove null keys
		if(this.amlProcessedInfo){
			for(var key in this.amlProcessedInfo){
				if (this.amlProcessedInfo[key] === null || this.amlProcessedInfo[key] === undefined) {
			      delete this.amlProcessedInfo[key];
			    }
			}
		}
	}

	getDocFromServer(checkGuid , isIdDoc){
		this._app.getDocFromServer(checkGuid)
		.subscribe(
			(res)=>{
				if(isIdDoc){
					this.identityDocProcessedInfo.endUserDocInfo["docFrontUrl"] = `data:image/jpeg;base64,${res.data["frontDoc"]}`;
					this.identityDocProcessedInfo.endUserDocInfo["docRearUrl"] = `data:image/jpeg;base64,${res.data["rearDoc"]}`;
				}
				else{
					this.addressDocProcessedInfo.endUserDocInfo["docFrontUrl"] = `data:image/jpeg;base64,${res.data["frontDoc"]}`;
					this.addressDocProcessedInfo.endUserDocInfo["docRearUrl"] = `data:image/jpeg;base64,${res.data["rearDoc"]}`;
				}
				console.log(this.identityDocProcessedInfo);
				console.log(this.addressDocProcessedInfo);
			}
		);
	}


}
