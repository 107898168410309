import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { LiveCheckComponent } from "./live-check.component";

import { KycCheckComponent } from "./kyc-check/kyc-check.component";
import { IdCheckComponent } from "./id-check/id-check.component";
import { AddressCheckComponent } from "./address-check/address-check.component";
import { AmlCheckComponent } from "./aml-check/aml-check.component";
import { OcSearchComponent } from "./oc-search/oc-search.component";

import { IndividualFormComponent } from "./individual-form/individual-form.component";
import { CorpFormComponent } from "./corp-form/corp-form.component";
import { CompanyDataComponent } from "./company-data/company-data.component";
import { VerificationResultComponent } from "./verification-result/verification-result.component";

import { FormsModule } from "@angular/forms";
import { ReactiveFormsModule } from "@angular/forms";
import { ArchwizardModule, WizardState } from "angular-archwizard";
import { PipesModule } from "./../../pipes/pipes.module";
import { DndModule } from "./../../directives/dnd/dnd.module";
import { ShowImageModule } from "./../../directives/show-image/show-image.module";

import { AmlDetailsModule } from "./../../pages/report-details/aml-details/aml-details.module";
import { AmlSelectedResultModule } from "./../../pages/live-check/aml-selected-result/aml-selected-result.module";

import { LiveCheckRoutingModule } from "./live-check-routing.module";
import { TabsModule } from "ngx-bootstrap/tabs";
import { CustomFormsModule } from "ng2-validation";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { SecondaryIdCheckComponent } from './secondary-id-check/secondary-id-check.component'

// import { SavedCompanyDataComponent } from './saved-company-data/saved-company-data.component';
import { SavedCompanyDataModule } from "./saved-company-data/saved-company-data.module";

// import { ImageCropperModule } from 'ngx-image-cropper';
// import { AmlSelectedResultComponent } from './aml-selected-result/aml-selected-result.component'

@NgModule({
  declarations: [
    LiveCheckComponent,
    KycCheckComponent,
    IdCheckComponent,
    SecondaryIdCheckComponent,
    AddressCheckComponent,
    AmlCheckComponent,
    OcSearchComponent,

    IndividualFormComponent,
    CorpFormComponent,
    CompanyDataComponent,
    VerificationResultComponent,
    // AmlSelectedResultComponent
  ],
  imports: [
    TabsModule,
    FormsModule,
    SavedCompanyDataModule,
    // ImageCropperModule,
    CustomFormsModule,
    ReactiveFormsModule,
    ArchwizardModule,
    PipesModule,
    DndModule,
    ShowImageModule,
    CommonModule,
    AmlDetailsModule,
    AmlSelectedResultModule,
    LiveCheckRoutingModule,
    BsDatepickerModule.forRoot(),
  ],
  exports: [LiveCheckComponent],
  // schemas: [ CUSTOM_ELEMENTS_SCHEMA , NO_ERRORS_SCHEMA ],
})
export class LiveCheckModule {}
