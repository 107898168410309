import { Component, OnInit , Input , Output , EventEmitter } from '@angular/core';
import { Router, ActivatedRoute, Params, NavigationEnd } from "@angular/router";
import { DataService } from "../../../services/data.service";
import { AppService } from "../../../services/app.service";
import { first } from 'rxjs/operators';

import {Verification} from "../../../models/Verification.model";
import {MatchAttributeStatus , VerificationStates} from "../../../utils/status";
import {AdverseMedia , CR , LR} from "../../../utils/adverseMedia";
@Component({
  selector: 'app-aml-check',
  templateUrl: './aml-check.component.html',
  styleUrls: ['./aml-check.component.scss']
})
export class AmlCheckComponent implements OnInit {

	// For individual componentizing of Checks
	// @Input() endUserCheck: any ;

	// For cumulative handling of AML Checks
	@Input() usersAMLChecks        : any[] ;
	@Input() hashId                : any ;
	@Input() basicUserData         : any ;
	
	amlUser                        : any = {}; //for Input Fields
	
	@Input() traversalState        : any ;
	//Emitting the current state of the Selected Check
	@Output() checkState           : EventEmitter<any> = new EventEmitter();
	@Output() getAMLSelectedResults: EventEmitter<any> = new EventEmitter();
	@Output() getAdverseResults	   : EventEmitter<any> = new EventEmitter();
	
	verification                   : Verification ;

	// possibleAMLChecks : any = [
	// 	"crimeCheck" , "pepCheck" , "sanctionCheck"
	// ];

	// amlCheckData        : any = null;

	// Newer Flow , Search Results
	AreFiltersActive     : boolean = false ;
	amlSearchResultsData : any[] = [] ;
	countries            : any[] = [] ;
	dobYears             : any[] = [] ;
	genders              : any[] = [] ;
	searchType			 : any = "STRICT";  //STRICT, FUZZY, MORE_FUZZY

	showCardView         : boolean = false ;
	
	//Pagination
	currentPage          : number = 0;
	itemsPerPage         : number = 10 ;
	totalItems           : number ;
	maxSize              : number ;
	sort                 : any = null;
	
	buildQuery           : any = {
		'searchType': this.searchType ,
		'size'      : this.itemsPerPage,
		'page'      : this.currentPage,
		'dobYears'  : [] ,
		'countries' : [] ,
		'genders'   : [] ,
		'sort'      : this.sort 
	};
	
	showAMLSelectedResult : boolean = false ;
	showAMLSearchResult   : boolean = false ;
	amlEntityGuid         : any = null; 
	
	// adverseMediaResults: any[] = [];
	adverseMediaQuery     : any ;
	adverseCategory       : any[] = AdverseMedia ;
	cr                    : any[] = CR ;
	lr                    : any[] = LR ;
	showuserDetails: boolean;
	showcompanyDetails: boolean;
	pageList: any[];
	startIndex: number=0;
	endIndex: number=10;
	jump: number;
	constructor(
		private _data: DataService,
		private _app : AppService,
		public router: Router){
	}

	ngOnInit(){
		if(this.basicUserData.firstName == "" ||this.basicUserData.firstName ==undefined){
			this.showuserDetails=false;
		}
		else this.showuserDetails=true;
		if(this.basicUserData.companyName == "" ||this.basicUserData.companyName ==undefined){
			this.showcompanyDetails=false;
		}
		else this.showcompanyDetails=true;
		console.log(this.usersAMLChecks);
		console.log(this.basicUserData);
		this.amlUser["firstName"]   = this.basicUserData["firstName"];
		this.amlUser["lastName"]    = this.basicUserData["lastName"];
		this.amlUser["companyName"] = this.basicUserData["companyName"] ? this.basicUserData["companyName"] : this.basicUserData["registrationName"];
		this.verification           = new Verification();

		this.getAMLFilters(this.searchType);
		this.jump=this.currentPage+1;
		// this.getAMLCheckDetails();
	}

	getAMLFilters(searchType){
		this._app.getAMLFilters(this.hashId , searchType)
		.subscribe(
			(res)=>{
				console.log(res);
				this.countries = res.data.countries
				this.dobYears  = res.data.dobYears ;
				this.genders   = res.data.genders ;
			}
		);
	}

	clearInput(){
		this.currentPage = 0 ;
		this.buildQuery  = {
			'searchType': this.searchType ,
			'size'      : this.itemsPerPage,
			'page'      : this.currentPage,
			'dobYears'  : [] ,
			'countries' : [] ,
			'genders'   : [] ,
			'sort'      : this.sort 
	    };
	}

	onSearchTypeChange(searchType){
		console.log(searchType);
		this.getAMLFilters(searchType);
	}

    doFilter(filterName , value){
        this.currentPage = 0;
        this.buildQuery["page"] = 0;
        if(filterName == 'sort'){
            this.buildQuery[filterName] = value;
        }
        else if(filterName == 'searchType'){
        	this.clearInput();
        	this.buildQuery[filterName] = value;
        	this.getAMLFilters(value);
        }
        else{
            var indexValue = this.buildQuery[filterName].indexOf(value);
            if(indexValue === -1){
                this.buildQuery[filterName].push(value);
            }
            else{
                this.buildQuery[filterName].splice(indexValue, 1);
            }
        }
        this.doAMLCheckSearch(false);
    }

    getMoreUsersReports(){
        console.log("getMoreUsersData");
        this.currentPage = this.currentPage + 1;
        this.buildQuery["page"] = this.buildQuery["page"] +  1 ;
        if(this.currentPage < this.maxSize){
            this.doAMLCheckSearch(false);
        }
    }

    getPaginatedData(isNext){
        console.log("getMoreUsersData");
        this.currentPage = isNext ? this.currentPage + 1 : this.currentPage - 1 ;
        this.buildQuery["page"] = isNext ? this.buildQuery["page"] + 1 : this.buildQuery["page"] - 1 ; ;
        if(this.currentPage < this.maxSize){
			this.doAMLCheckSearch(false);
			this.pageSize();
			this.jump=this.currentPage+1;
        }
    }

    getPaginatedText(){
        let a = (this.currentPage * this.itemsPerPage) + 1 ;
        let b ;
        if(this.currentPage < (this.maxSize - 1)){
            b = (this.currentPage + 1 ) * this.itemsPerPage;
        }
        else if(this.currentPage == (this.maxSize - 1 )){
            b = this.totalItems;
        }
        return  a+"-"+b ;
	}
	getPagedData(index) {
        this.currentPage = index
        this.buildQuery["page"] = index;
        if (this.currentPage < this.maxSize) {
            this.doAMLCheckSearch(false);
            this.pageSize()
            this.jump=this.currentPage+1;
        }

    }
    pageSize() {
        this.pageList = []
        let totalPages = this.getPage(this.totalItems)
        this.startIndex = (this.currentPage - 4) < 0 ? 0 : this.currentPage - 4
        this.endIndex = (this.currentPage + 4) > this.getPage(this.totalItems) ? totalPages : this.currentPage + 4
        for (let i = 0; i <= this.currentPage + 4; i++) {
            if (i >= this.currentPage - 4 && i < totalPages) { this.pageList.push(i) }
        }

    }
    getPage(length) {
       return (Math.round(length / 10));
    }
    jumpTo() {
        // let index=parseInt(this.jump)
        if (this.jump <= this.maxSize) {
        if(Math.sign(this.jump) != -1 && Math.sign(this.jump) != 0)
        this.getPagedData(this.jump - 1)
        }
    }
	// navigateToAmlCheckData(){
	// 	this.onAMLEntitySelect(this.basicUserData)
	// }
	navigateToAmlCheckData(){
		 this.onAMLEntityNotSelect(this.basicUserData)
		 }
		onAMLEntityNotSelect(data){
	 this.amlUser["firstName"]  = data["firstName"];
		  this.amlUser["lastName"]   = data["lastName"];
	 this.showAMLSelectedResult = true ;
		}
    onAMLEntitySelect(data){
    	console.log(data);
		console.log(this.adverseMediaQuery);
		this.amlUser["firstName"]  = data.details[0].firstName;
		this.amlUser["lastName"]   = data.details[0].lastName;
		this.amlUser["fullName"]   = data.details[0].fullName;
		// this.amlUser["firstName"]  = data["firstName"];
		// this.amlUser["lastName"]   = data["lastName"];
		// this.amlUser["fullName"]   = data["fullName"];
		console.log(this.amlUser);
    	// if(data["firstName"] || data["lastName"]){
    	// 	this.createAdverseMediaQuery(data["firstName"] , data["lastName"] , null) ;
    	// }
    	// else{
    	// 	this.createAdverseMediaQuery(null , null , data["fullName"]) ;
    	// }
		this.showAMLSelectedResult = true ;
		this.amlEntityGuid         = data.details[0].guid; 
		console.log(this.amlEntityGuid);
    }

	// saveAMLChecks(){
	// 	console.log(this.amlUser);
	// 	this._data.changeLoaderVisibilityAML(true);
	// 	setTimeout(()=>{
	// 		this._app.saveAMLChecks(this.hashId)
	// 		.subscribe(
	// 			(res)=>{
	// 				this._data.changeLoaderVisibilityAML(false);
	// 				this._data.toastrShow(res["message"] , "info");
	// 				this.mapAmlData(res.data);
	// 			},
	// 			(err)=>{
	// 				console.log(err);
	// 				this._data.changeLoaderVisibilityAML(false);
	// 			}
	// 		);
	// 	},8000);
	// }

	doAMLCheckSearch(showAMLLoader){
		console.log();
		if(showAMLLoader){
			this._data.changeLoaderVisibilityAML(true);
			setTimeout(()=>{  
				this.callAMLCheckSearch() ;
			},2000);
		}
		else{
			this._data.changeLoaderVisibility(true);
			this.callAMLCheckSearch();
		}
		this._data.storeId(this.hashId)
	}

	callAMLCheckSearch(){
		console.log(this.buildQuery);
		console.log(this.buildQuery["countries"]);
		console.log(typeof this.buildQuery["countries"]);
		if(typeof this.buildQuery["countries"] == 'string'){
			let country = this.buildQuery["countries"];
			this.buildQuery["countries"] = [];
			this.buildQuery["countries"].push(country);
		}
		if(typeof this.buildQuery["genders"] == 'string'){
			let country = this.buildQuery["genders"];
			this.buildQuery["genders"] = [];
			this.buildQuery["genders"].push(country);
		}
		this._app.doAMLCheckSearch(this.hashId , this.buildQuery)
		.subscribe(
			(res)=>{
				this.showAMLSearchResult = true ;
                let amlSearchResultsData = res.data.data.content ;
                this.amlSearchResultsData = [];
                for(var i = 0 ; i < amlSearchResultsData.length ; i++){
                    this.amlSearchResultsData.push(amlSearchResultsData[i]);
                }
                if(this.currentPage === 0){
                    this.totalItems = res.data.data.totalElements;
					this.maxSize = res.data.data.totalPages;
					this.pageSize();
                }
                this._data.changeLoaderVisibilityAML(false);
                this._data.changeLoaderVisibility(false);
			},
			(err)=>{ this._data.changeLoaderVisibilityAML(false); }
		);
	}

	createAdverseMediaQuery(firstName , lastName , fullName){
		// console.log(this.amlUser);
		// let cx = this.amlUser["category"].cx ;
		// let oldKey = "AIzaSyDNF4O0QlGsON-HdVsAIyG3AuJdDFlM2Eo" ;
		// let newKey = "AIzaSyBEJoqv95Mt6Kly71KOhOoNuNgwEYIW3S8" ;
		// if(firstName || lastName){
		// 	this.adverseMediaQuery = `https://www.googleapis.com/customsearch/v1?q=${firstName}+${lastName}&cx=${cx}&key=${oldKey}&num=10&filter=0` ;
		// }
		// else{
		// 	this.adverseMediaQuery = `https://www.googleapis.com/customsearch/v1?q=${fullName}&cx=${cx}&key=${oldKey}&num=10&filter=0` ;
		// }

		// if(this.amlUser["exactTerms"] && this.amlUser["exactTerms"].length){
		// 	this.adverseMediaQuery = this.adverseMediaQuery + `&exactTerms=${this.amlUser["exactTerms"]}`;
		// }
		// if(this.amlUser["excludeTerms"] && this.amlUser["excludeTerms"].length){
		// 	this.adverseMediaQuery = this.adverseMediaQuery + `&excludeTerms=${this.amlUser["excludeTerms"]}`;
		// }
		// if(this.amlUser["dateRestrict"] && this.amlUser["dateRestrict"].length){
		// 	this.adverseMediaQuery = this.adverseMediaQuery + `&dateRestrict=${this.amlUser["dateRestrict"]}`;
		// }
		// if(this.amlUser["cr"] && this.amlUser["cr"].length){
		// 	this.adverseMediaQuery = this.adverseMediaQuery + `&cr=${this.amlUser["cr"].trim()}`;
		// }
		// if(this.amlUser["lr"] && this.amlUser["lr"].length){
		// 	this.adverseMediaQuery = this.adverseMediaQuery + `&lr=${this.amlUser["lr"].trim()}`;
		// }
		// if(this.amlUser["orTerms"] && this.amlUser["orTerms"].length){
		// 	this.adverseMediaQuery = this.adverseMediaQuery + `&orTerms=${this.amlUser["orTerms"].trim()}`;
		// }
		// console.log(this.adverseMediaQuery);
		// this.adverseMediaQuery  = `https://cse.google.com/cse/element/v1?rsz=filtered_cse&num=10&hl=en&source=gcsc&gss=.com&start=10&cx=000780095111183747947:acdmugiyy0m&q=${this.amlUser["firstName"]}+${this.amlUser["lastName"]}&safe=off&cse_tok=AKaTTZh2wyXtEJff2f5AvFIH1OTc:1555059602533&sort=&exp=csqr&callback=google.search.cse.api19257`;
		// this.adverseMediaQuery  =  `https://cse.google.com/cse/element/v1?rsz=filtered_cse&num=10&hl=en&source=gcsc&gss=.com&cx=${cx}&q=${this.amlUser["firstName"]}+${this.amlUser["lastName"]}&safe=off&cse_tok=AKaTTZi2K9S3hAP3l4Jf-2VyhdV1:1555152266960&sort=&exp=csqr&oq=${this.amlUser["firstName"]}+${this.amlUser["lastName"]}&callback=google.search.cse.api1394`;
	}

	// doAdverseMediaSearch(){
	// 	console.log("doAdverseMediaSearch");
	//  	let query = `https://cse.google.com/cse/element/v1?rsz=filtered_cse&num=10&hl=en&source=gcsc&gss=.com&start=10&cx=000780095111183747947:acdmugiyy0m&q=${this.amlUser["firstName"]}+${this.amlUser["lastName"]}&safe=off&cse_tok=AKaTTZh2wyXtEJff2f5AvFIH1OTc:1555059602533&sort=&exp=csqr&callback=google.search.cse.api19257`;
	// 	let data = {
	// 		"url" : query
	// 	}
	// 	this._app.doAdverseMediaSearch(data)
	// 	.subscribe(
	// 		(res)=>{
	// 			this.adverseMediaResults = JSON.parse(res.data) ;
	// 			console.log(this.adverseMediaResults);
	// 		}
	// 	);
	// }

	onGettingAMLResult(event){
		this.getAMLSelectedResults.emit(event);
	}

	onGettingAdverseResult(event){
		this.getAdverseResults.emit(event);
	}

	updateCheckState($event){
		this.checkState.emit($event);
	}

}
