import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { BulkUploadComponent } from "./bulk-upload.component";
import { BatchDetailsComponent } from "./batch-details/batch-details.component";
import { NotificationLogComponent } from "./notification-log/notification-log.component";

const routes: Routes = [
  { path: "", component: BulkUploadComponent },
  { path: ":id", component: BatchDetailsComponent },
  { path: ":id/notification-log", component: NotificationLogComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class BulkUploadRoutingModule {}
