import { Injectable, PLATFORM_ID, Inject } from "@angular/core";
import { isPlatformBrowser, DOCUMENT } from "@angular/common";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root"
})
export class LayoutService {
  // layoutMenu: any = [
  //   {
  //     title: "Home",
  //     router: "/home",
  //     count: 0,
  //     role: "admin",
  //     customer:['india','international'],
  //     icon: "fa fa-home",
  //     children: []
  //   },
  //   // {
  //   //   title: "Home",
  //   //   router: "/home/india",
  //   //   count: 0,
  //   //   role: "admin",
  //   //   customer:['india'],
  //   //   icon: "fa fa-home",
  //   //   children: []
  //   // },
  //   {
  //     title: "User Area",
  //     router: "/user-home",
  //     count: 0,
  //     role: "admin",
  //     customer:['india','international'],
  //     icon: "fa fa-user",
  //     children: []
  //   },
  //   {
  //     title: "Reports",
  //     router: null,
  //     count: 0,
  //     role: "admin",
  //     customer:['india','international'],
  //     icon: "fa fa-file",
  //     children: [
  //       {
  //         title: "Status",
  //         count: 0,
  //         router: "/new-ind-report",
  //         india:true
  //       },
  //       {
  //         title: "User Submissions",
  //         count: 0,
  //         router: "/user-reports",
  //         india:true
  //       },
  //       {
  //         title: "AML Alerts",
  //         count: 0,
  //         router: "/aml-alerts",
  //         india:false
  //       },
  //       {
  //         title: "Expired Incomplete Report",
  //         count: 0,
  //         router: "/expired-checks",
  //         india:false
  //       },
  //       {
  //         title: "Override Report",
  //         count: 0,
  //         router: "/override-report",
  //         india:false
  //       }
  //     ]
  //   },
  //   // {
  //   //   title: "Analytics",
  //   //   router: "/analytics",
  //   //   count: 0,
  //   //   role: "admin",
  //   //   icon: "fa fa-line-chart",
  //   //   children : []
  //   // },
  //   {
  //     title: "AML Search",
  //     count: 0,
  //     role: "admin",
  //     customer:['international'],
  //     icon: "fa fa-search",
  //     router: "/aml-search",
  //     children: []
  //   },
  //   {
  //     title: "Profile Search",
  //     count: 0,
  //     role: "admin",
  //     customer:['international'],
  //     icon: "fa fa-search",
  //     router: "/profile-search",
  //     children: []
  //   },
  //   {
  //     title: "Corporate Search",
  //     count: 0,
  //     role: "admin",
  //     customer:['international'],
  //     icon: "fa fa-building-o",
  //     router: "/corporate-search",
  //     children: []
  //   },
  //   {
  //     title: "Do Live Check",
  //     count: 0,
  //     role: "admin",
  //     customer:['india','international'],
  //     icon: "fa fa-check",
  //     router: "/live-check",
  //     children: []
  //   },
  //   {
  //     title: "Digital Verification",
  //     count: 0,
  //     role: "admin",
  //     customer:['india','international'],
  //     icon: "fa fa-check",
  //     router: "/digital-verification",
  //     children: []
  //   },
  //   {
  //     title: "Bulk Upload",
  //     count: 0,
  //     role: "admin",
  //     customer:['international','india'],
  //     icon: "fa fa-upload",
  //     router: "/bulk-upload",
  //     children: []
  //   },
  //   {
  //     title: "Bulk Upload Results",
  //     router: "/bulk-upload-result",
  //     count: 0,
  //     role: "admin",
  //     customer:['international','india'],
  //     icon: "fa fa-file",
  //     children: []
  //   },
  //   {
  //     title: "Doc Extraction",
  //     count: 0,
  //     role: "admin",
  //     customer:['international'],
  //     icon: "fa fa-recycle",
  //     router: "/doc-extraction",
  //     children: []
  //   },
  //   {
  //     title: "CO Activity",
  //     count: 0,
  //     role: "admin",
  //     customer:['international'],
  //     icon: "fa fa-history",
  //     router: "/activity-log",
  //     children: []
  //   },
  //   {
  //     title: "Settings",
  //     router: null,
  //     count: 0,
  //     role: "admin",
  //     customer:['international'],
  //     icon: "fa fa-gears",
  //     children: [
  //       {
  //         title: "General",
  //         count: 0,
  //         router: "/settings"
  //       },
  //       {
  //         title: "Customize",
  //         count: 0,
  //         router: "/customize"
  //       },
  //       {
  //         title: "Theming",
  //         count: 0,
  //         router: "/theming",
  //         access_slug: "theming"
  //       }
  //     ]
  //   },
  //   {
  //     title: "Billings",
  //     router: null,
  //     count: 0,
  //     role: "admin",
  //     customer:['international','india'],
  //     icon: "fa fa-gbp",
  //     children: [
  //       {
  //         title: "One Time Setup",
  //         count: 0,
  //         router: "/one-time-setup",
  //         india:true
  //       },
  //       {
  //         title: "Billings & Payments",
  //         count: 0,
  //         router: "/invoices",
  //         india:true
         
  //       },
  //       {
  //         title: "Coupons",
  //         count: 0,
  //         router: "/coupons"
  //       },
  //       {
  //         title: "Plan & Subscriptions",
  //         count: 0,
  //         router: "/subscriptions",
  //       }
  //     ]
  //   },
  //   {
  //     title: "Roles",
  //     router: "/roles",
  //     count: 0,
  //     role: "admin",
  //     customer:['international'],
  //     icon: "fa fa-question-circle",
  //     children: []
  //   },
  //   {
  //     title: "Contact Us",
  //     router: "/contact",
  //     count: 0,
  //     role: "admin",
  //     customer:['international','india'],
  //     icon: "fa fa-code",
  //     children: []
  //   },
  //   {
  //     title: "Terms and Conditions",
  //     router: "/tnc",
  //     count: 0,
  //     role: "admin",
  //     customer:['international','india'],
  //     icon: "fa fa-file-code-o",
  //     children: []
  //   },
  //   {
  //     title: "Cancellation and Refund Policy",
  //     router: "/cancel-refund",
  //     count: 0,
  //     role: "admin",
  //     customer:['international','india'],
  //     icon: "fa fa-file-code-o",
  //     children: []
  //   },
  //   {
  //     title: "Privacy Policy",
  //     router: "/privacy-policy",
  //     count: 0,
  //     role: "admin",
  //     customer:['international','india'],
  //     icon: "fa fa-file-code-o",
  //     children: []
  //   },
  //   {
  //     title: "Developer",
  //     router: "/developer",
  //     count: 0,
  //     role: "admin",
  //     customer:['international'],
  //     icon: "fa fa-code",
  //     children: []
  //   },
  //   {
  //     title: "FAQ",
  //     router: "/faq",
  //     count: 0,
  //     role: "admin",
  //     customer:['international'],
  //     icon: "fa fa-question-circle",
  //     children: []
  //   }
  // ];

  layoutMenu:any=[
    {
      "title": "Home",
      "router": "/home",
      "count": 0,
      "role": ["admin", "ROLE_SUPERADMIN"],
      "customer": [
        "india",
        "international"
      ],
      "icon": "fa fa-home",
      "children": [],
      "slug": "home"
    },
    {
      "title": "User Area",
      "router": "/user-home",
      "count": 0,
      "role": ["admin", "ROLE_SUPERADMIN"],
      "customer": [
        "india",
        "international"
      ],
      "icon": "fa fa-user",
      "children": [],
      "slug": "user-area"
    }, {
      "title": "Reports",
      "router": null,
      "count": 0,
      "role": ["admin", "ROLE_SUPERADMIN"],
      "customer": [
        "india",
        "international"
      ],
      "icon": "fa fa-file",
      "children": [
        {
          "title": "Status",
          "count": 0,
          "router": "/new-ind-report",
          "india": true
        },
        {
          "title": "User Submissions",
          "count": 0,
          "router": "/user-reports",
          "india": true
        },
        {
          "title": "AML Alerts",
          "count": 0,
          "router": "/aml-alerts",
          "india": false
        },
        {
          "title": "Expired Incomplete Report",
          "count": 0,
          "router": "/expired-checks",
          "india": false
        },
        {
          "title": "Override Report",
          "count": 0,
          "router": "/override-report",
          "india": false
        }
      ],
      "slug": "reports"
    }, {
      "title": "Consumption Reports",
      "router": '/consumption-report',
      "count": 0,
      "role": ["admin", "ROLE_SUPERADMIN"],
      "customer": [
        "international"
      ],
      "icon": "fa fa-file",
      "children": [],
      "slug": "consumption-report"
    }, {
      "title": "AML Search",
      "count": 0,
      "role": ["admin", "ROLE_SUPERADMIN"],
      "customer": [
        "international"
      ],
      "icon": "fa fa-search",
      "router": "/aml-search",
      "children": [],
      "slug": "aml-search"
    }, {
      "title": "Profile Search",
      "count": 0,
      "role": ["admin", "ROLE_SUPERADMIN"],
      "customer": [
        "international"
      ],
      "icon": "fa fa-search",
      "router": "/profile-search",
      "children": [],
      "slug": "profile-search"
    }, {
      "title": "Corporate Search",
      "count": 0,
      "role": ["admin", "ROLE_SUPERADMIN"],
      "customer": [
        "international"
      ],
      "icon": "fa fa-building-o",
      "router": "/corporate-search",
      "children": [],
      "slug": "corporate-search"
    }, {
      "title": "Do Live Check",
      "count": 0,
      "role": ["admin", "ROLE_SUPERADMIN"],
      "customer": [
        "india",
        "international"
      ],
      "icon": "fa fa-check",
      "router": "/live-check",
      "children": [],
      "slug": "do-live-check"
    }, {
      "title": "DEV-OPS",
      "count": 0,
      "role": ["ROLE_SUPERADMIN"],
      "customer": [
        "india",
        "international"
      ],
      "icon": "fa fa-check",
      "children": [ {
        "title": "User-Area",
        "count": 0,
        "router": "/dev-ops"
      },
      {
        "title": "AML-Alerts",
        "count": 0,
        "router": "/aml-false-positive"
      }],
      "slug": "dev-ops"
    }, {
      "title": "Digital Verification",
      "count": 0,
      "role": ["admin", "ROLE_SUPERADMIN"],
      "customer": [
        "india",
        "international"
      ],
      "icon": "fa fa-check",
      "router": "/digital-verification",
      "children": [],
      "slug": "digital-verification"
    }, {
      "title": "Bulk Upload",
      "count": 0,
      "role": ["admin", "ROLE_SUPERADMIN"],
      "customer": [
        "international",
        "india"
      ],
      "icon": "fa fa-upload",
      "router": "/bulk-upload",
      "children": [],
      "slug": "bulk-upload"
    }, {
      "title": "Bulk Upload Results",
      "router": "/bulk-upload-result",
      "count": 0,
      "role": ["admin", "ROLE_SUPERADMIN"],
      "customer": [
        "international",
        "india"
      ],
      "icon": "fa fa-file",
      "children": [],
      "slug": "bulk-upload-results"
    }, {
      "title": "Doc Extraction",
      "count": 0,
      "role": ["admin", "ROLE_SUPERADMIN"],
      "customer": [
        "international"
      ],
      "icon": "fa fa-recycle",
      "router": "/doc-extraction",
      "children": [],
      "slug": "doc-extraction"
    }, {
      "title": "CO Activity",
      "count": 0,
      "role": ["admin", "ROLE_SUPERADMIN"],
      "customer": [
        "international"
      ],
      "icon": "fa fa-history",
      "router": "/activity-log",
      "children": [],
      "slug": "co-activity"
    }, {
      "title": "Settings",
      "router": null,
      "count": 0,
      "role": ["admin", "ROLE_SUPERADMIN"],
      "customer": [
        "international"
      ],
      "icon": "fa fa-gears",
      "children": [
        {
          "title": "General",
          "count": 0,
          "router": "/settings"
        },
        {
          "title": "Customize",
          "count": 0,
          "router": "/customize"
        },
        {
          "title": "Theming",
          "count": 0,
          "router": "/theming",
          "access_slug": "theming"
        }
      ],
      "slug": "settings"
    }, {
      "title": "Billings",
      "router": null,
      "count": 0,
      "role": ["admin", "ROLE_SUPERADMIN"],
      "customer": [
        "international"
      ],
      "icon": "fa fa-gbp",
      "children": [
        {
          "title": "Pricing",
          "count": 0,
          "router": "/pricing"
        },
        {
          "title": "Invoices",
          "count": 0,
          "router": "/invoices"
        },
        {
          "title": "Coupons",
          "count": 0,
          "router": "/coupons"
        },
        {
          "title": "Plan & Subscriptions",
          "count": 0,
          "router": "/subscriptions"
        }
      ],
      "slug": "billing"
    }, {
      "title": "Roles",
      "router": "/roles",
      "count": 0,
      "role": ["admin", "ROLE_SUPERADMIN"],
      "customer": [
        "international"
      ],
      "icon": "fa fa-question-circle",
      "children": [],
      "slug": "roles"
    }, {
      "title": "Contact Us",
      "router": "/contact",
      "count": 0,
      "role": ["admin", "ROLE_SUPERADMIN"],
      "customer": [
        "international",
        "india"
      ],
      "icon": "fa fa-code",
      "children": [],
      "slug": "contact-us"
    }, {
      "title": "Terms and Conditions",
      "router": "/tnc",
      "count": 0,
      "role": ["admin", "ROLE_SUPERADMIN"],
      "customer": [
        "international",
        "india"
      ],
      "icon": "fa fa-file-code-o",
      "children": [],
      "slug": "terms-and-conditions"
    }, {
      "title": "Cancellation and Refund Policy",
      "router": "/cancel-refund",
      "count": 0,
      "role": ["admin", "ROLE_SUPERADMIN"],
      "customer": [
        "international",
        "india"
      ],
      "icon": "fa fa-file-code-o",
      "children": [],
      "slug": "cancellation-and-refund-policy"
    }, {
      "title": "Privacy Policy",
      "router": "/privacy-policy",
      "count": 0,
      "role": ["admin", "ROLE_SUPERADMIN"],
      "customer": [
        "international",
        "india"
      ],
      "icon": "fa fa-file-code-o",
      "children": [],
      "slug": "privacy-policy"
    }, {
      "title": "Developer",
      "router": "/developer",
      "count": 0,
      "role": ["admin", "ROLE_SUPERADMIN"],
      "customer": [
        "international"
      ],
      "icon": "fa fa-code",
      "children": [],
      "slug": "developer"
    }, {
      "title": "FAQ",
      "router": "/faq",
      "count": 0,
      "role":["admin", "ROLE_SUPERADMIN"],
      "customer": [
        "international"
      ],
      "icon": "fa fa-question-circle",
      "children": [],
      "slug": "faq"
    }
  ]

  layoutMenuTiny: any = [
    "/home",
    "/user-reports",
    "/aml-reports",
    "check-status",
    "/history",
    "/live-check",
    "/doc-extraction",
    "/settings",
    "/doc-extraction",
    "/roles",
    "/developer",
    "/faq"
  ];

  constructor( private http: HttpClient,
    @Inject("API_URL") public API_URL: any) {
    // this.layoutMenuTiny = this.layoutMenu.map((x)=>{
    //   return x.router;
    // });
  }

  getLayout(role: string, usermenu = []) {
    var menus = [];
    if (usermenu.length > 0) {
      usermenu.forEach((item) => {
        let menu = this.layoutMenu.find((obj) => item.pageSlug == obj.slug);
        if (menu != undefined && menu != null) {
          menus.push(menu);
        }
      });
    } else {
      menus = this.layoutMenu;
    }
    return menus.filter((layout: any) => {
      return layout.role.includes(role);
    });
  }
}
