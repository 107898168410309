import { Component, OnInit, ViewChild } from "@angular/core";
import { BsModalService } from "ngx-bootstrap/modal";
import { BsModalRef } from "ngx-bootstrap/modal";
import { BehaviorSubject } from "rxjs";
import { Role } from "../../models/Role.model";

import { AppService } from "../../services/app.service";
import { DataService } from "../../services/data.service";
import { DevOpsService } from "../../pages/dev-ops/services/dev-ops.service";
import { finalize, first } from "rxjs/operators";
import { Router } from "@angular/router";
import { ImageCroppedEvent } from "ngx-image-cropper";
import { ImageCropperComponent } from "ngx-image-cropper";

import * as RecordRTC from "recordrtc";
import { invokeSaveAsDialog } from "recordrtc";
import { TrackingService } from 'src/app/services/tracking.service';
import { FormBuilder, FormGroup } from "@angular/forms";

@Component({
  selector: "app-kyc-modal",
  templateUrl: "./kyc-modal.component.html",
  styleUrls: ["./kyc-modal.component.scss"]
})
export class KycModalComponent implements OnInit {
  //Generic Variables
  title: string;
  type:
    | "create-new-user"
    | "show-image"
    | "create-custom-theme"
    | "do-live-check"
    | "all-user-checks"
    | "confirmation-modal"
    | "pricing-structure"
    | "checkout"
    | "checkout-new"
    | "show-coupons"
    | "override-checks"
    | "set-aml-alert"
    | "schedule-check"
    | "regenerate-keys"
    | "crop-image"
    | "user-logout"
    | "news-detail"
    | "edit-bulk-upload-record"
    | "create-new-batch"
    | "final-submit-confirmation"
    | "test-webhook"
    | "capture-doc"
    | "crop-image-b64"
    | "advanced-aml-search"
    | "add-new-risk-policy"
    | "edit-extracted-data"
    | "advanced-aml-search"
    | "add-new-risk-policy"
    | "test-webhook"
    | "redo-check"
    | "reinvite-check"
    | "status-details"
    | "terms-and-condition"
    | "warning-message"
    | "enterprise-plan"
    | "false-positive-alerts"
    | "DevOps-Override"
    frequency: any[] = ["DAILY", "WEEKLY", "MONTHLY", "QUARTERLY", "YEARLY", "NOW"];
    userName : string;
    idvWarningMessage :string;
    addressWarningMessage :string;
    selfieWarningMessage: string;
    devOpsOverrideData: any;
    livenessWarningMessage:string;
    endUserBookmarkedChangeAlertsData: any = '';
    editableUserInfo: boolean;
    

            addressdoccheck: any;
            adversemediacheck: any;
            corporatecheck: any;
            crimecheck: any;
            iddoccheck: any;
            livenesscheck: any;
            pepcheck: any;
            sanctionscheck: any;
            selfiecheck: any;
            corporateUserInfo:any;
            docMatch: any;
            idDocCheck2: any;
            adversemediacheck2: any;
            amlCheck2: any;
            emailCheck: any;
            phoneCheck: any;



  public onClose = new BehaviorSubject<any>({});

  //vars for roles i.e "create-new-user"
  isUpdate: boolean;
  user: Role;

  //variables for "show-image"
  image: string;

  //variable for "confirmation-modal"
  heroText: string;

  //vars for live check
  userType: any;
  userInfo: any;

  //vars for "all-user-checks"
  email: any;

  //vars for "regenerate-keys"
  account: {
    email: string;
    password: string;
  } = {
    email: "",
    password: ""
  };

  //vars for plan (planStatus: any;)
  planData: any;
  planStatus: any;
  planDuration: any;
  priceCurrency: any;

  //vars for overridden checks
  overriddenChecks: any;

  //vars for set-aml-alert || schedule-check
  endUserInfo: any;

  // vars for news data
  newsData: any = null;

  //vars data for bulkupload-record-edit
  recordTobeEdited: any;

  //vars for final submission of bulk upload
  totalCredit: any;

  //vars for web hook testing functonality
  webHookResponse: null;
  webhook_endpoint: any;
  webhook_sampleData: any;

  //vars for crop image
  imageChangedEvent: any;
  croppedImage: any;
  loadedImageEvent: any;
  showCropper: boolean = false;
  imageFile: any = null;

  //capture video
  showVideos: any = {
    showLiveWebCam: false,
    showRecordedWebCam: false
  };
  _selfie: any = Object.create({});
  videoRecorder: any;
  imgAsb64: any;

  extractedData: any;
  typeOfReq: any;
  allCaps: any = /([A-Z])/g;

  localStream: any;

  dropdownList = [];
  selectedItems = [];
  dropdownSettings = {
    idField: "id",
    textField: "key",
    allowSearchFilter: true,
    singleSelection: false
  };
  riskName: any;
  isTermsAndCondition : boolean = false;

  verification=false;

  @ViewChild(ImageCropperComponent) imageCropper: ImageCropperComponent;

  //var for countries
  countries: any;
  redoCheckMessage: any;
  reprocessCheckMessage : any;
  deviceInfo: any;
  ip: any;
  enterprisePlanMessage :any;

  overrideDocForm:FormGroup=this.formbuilder.group({
    fname:[''],
    lname:[''],
    fullName:[''],
    sn:[''],
    dob:[''],
    issue:[''],
    expary:[''],
    docname: [''],
    address: [''],
    issueYear:[''],
    expiryYear:[''],
    score: [''],
    status:['SUCCESS']
  });
  individualFrom: FormGroup = this.formbuilder.group({
    name: [''],
    fname:[''],
    lname:[''],
    sn: [''],
    dob: [''],
    issue: [''],
    expary: [''],
    docname: [''],
    address: [''],
    issueYear:[''],
    expiryYear:[''],
  })

  constructor(
    public modalService: BsModalService,
    public bsModalRef: BsModalRef,
    private _app: AppService,
    private data: DataService,
    private devops:DevOpsService,
    private _track:TrackingService,
    private _router:Router,
    private formbuilder: FormBuilder
  ) {}

  ngOnInit() {

    console.log(this.devOpsOverrideData)
    if (this.type == "advanced-aml-search") {
      this.getAllCountries();
    }

    if (this.type == "advanced-aml-search") {
      this.getAllCountries();
    }

    if (this.type == "crop-image") {
      console.log(this.loadedImageEvent);
      var image: any = new Image();
      // image.onload = function() {
      //     var width = image.naturalWidth ;
      //     var height = image.naturalHeight;
      //     window.URL.revokeObjectURL( image.src );
      //     console.log("width" + width);
      //     console.log("height" + height);
      // };
      var file: File = this.loadedImageEvent[0]
        ? this.loadedImageEvent[0]
        : this.loadedImageEvent.target.files[0];
      this.imageFile = this.loadedImageEvent[0]
        ? this.loadedImageEvent[0]
        : this.loadedImageEvent.target.files[0];
      var name = file.name;
      if (file) {
        var reader = new FileReader();
        var that = this;
        reader.onloadend = function() {
          // console.log(reader.result);
        };
        reader.readAsDataURL(file);
      }
    }
    if (this.type == "crop-image-b64") {
      this.imageFile = this.imgAsb64;
      console.log("Speaking from feas modal", this.imgAsb64);
    }
    
  }
  onItemSelect(item: any) {
    console.log(item);
  }
  onSelectAll(items: any) {
    console.log(items);
  }
  onNewUserCreate($event) {
    //new role
    console.log($event);
    this.closeModal($event);
  }

  saveScheduleChecks(){
    this.bsModalRef.hide();
    this.onClose.next(this.endUserInfo)
  }

  onUpdateUser($event) {
    console.log($event);
    this.closeModal($event);
  }

  onPaymentCompletion($event) {
    console.log($event);
    this.closeModal($event);
  }

  saveThefalsePositive(){
    this.bsModalRef.hide();
    const data = {
      comment: this.endUserBookmarkedChangeAlertsData
    }
    this.onClose.next(data);
  }

  closeModal(data?: any) {
    var that = this;
    if (this.videoRecorder) {
      this.videoRecorder.stopRecording(function(url) {
        console.log(url);
        that._selfie.videoStream.getTracks().forEach(function(track) {
          track.stop();
        });
      });
    }
    console.log(data)
    this.onClose.next(data);
    this.bsModalRef.hide();
  }

  clickClose() {
    this.bsModalRef.hide();
    if (this.type == "checkout") {
      window.location.reload();
    }else if(this.type == "capture-doc"){
      this.closeModal(null);
    }else if(this.type == "checkout-new"){
      this.onClose.next({closed : true});
      const currentUrl = this._router.url;
      this._router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
          this._router.navigate([currentUrl]);
      });
    }else{
      this.onClose.next({});
    }
  }
  sendRemoteLink() {
    let showRedoBtn = true;
    let query={
      username : this.userName,
      message : this.redoCheckMessage
    }
    this.data.changeLoaderVisibility(true);
    this._app.sendRemoteLinkForRedo(query).subscribe(res => {
      showRedoBtn = false;
      this.data.changeLoaderVisibility(false);
      this.data.toastrShow(res.message,"info")
      this.closeModal(showRedoBtn);
      this._track.activityLog();
    },
    err => {
      this.data.changeLoaderVisibility(false);
    });
  }
  sendPlanRequest(){
    
    let query={
      
      message : this.enterprisePlanMessage
    }
    this.data.changeLoaderVisibility(true);
    this._app.enterprisePlan(query).subscribe(res => {
      
      // this.data.changeLoaderVisibility(false);
      this.data.toastrShow(res.message,"info")
      this.data.changeLoaderVisibility(false);
      this.closeModal();
      
    },
    err => {
      this.data.changeLoaderVisibility(false);
    });
  }
  
  sendReprocessLink() {
    // let showRedoBtn = true;
    let query={
      username : this.userName,
      message : this.reprocessCheckMessage
    }
    this.data.changeLoaderVisibility(true);
    this._app.resendRemoteLink(query).subscribe(res => {
      // showRedoBtn = false;
      this.data.changeLoaderVisibility(false);
      this.data.toastrShow(res.message,"info")
      this.closeModal();
      this._track.activityLog();
    },
    err => {
      this.data.changeLoaderVisibility(false);
    });
  }
  

  onNewKycUserCreate($event) {
    console.log($event);
    if ($event) {
      let content = {
        endUser: $event.data.checks.endUser,
        corporateUserInfo: null,
        individualUserInfo: $event.data.individualUserInfo
      };
      if ($event.data["pendingChecks"]) {
        content["pendingChecks"] = $event.data["pendingChecks"];
      }
      if ($event.data["totalChecks"]) {
        content["totalChecks"] = $event.data["totalChecks"];
      }
      this.closeModal(content);
    } else {
      this.closeModal(null);
    }
  }

  onCouponRedemption($event) {
    if ($event) {
      console.log($event);
      this.closeModal($event);
    } else {
      this.closeModal(null);
    }
  }

  onOverrideChecks($event) {
    if ($event) {
      console.log($event);
      this.closeModal($event);
    } else {
      this.closeModal(null);
    }
  }

  onGettingId($event) {
    this.closeModal($event);
  }

  sendRequest(sendNow: boolean) {
    this._track.activityLog();
    let obj = {
      sendNow: sendNow
    };
    this.onClose.next(obj);
    this.bsModalRef.hide();
  }

  doLogout(shouldLogout: boolean) {
    let obj = {
      shouldLogout: shouldLogout
    };
    this.onClose.next(obj);
    this.bsModalRef.hide();
  }

  doFinalSubmit(shouldPay: boolean) {
    let obj = {
      shouldPay: shouldPay
    };
    this.onClose.next(obj);
    this.bsModalRef.hide();
  }

  reGenerateKeys() {
    this.closeModal(this.account);
  }

  getAdverseMediaNewImage(data) {
    if (
      data.pagemap &&
      data.pagemap.cse_image &&
      data.pagemap.cse_image.length &&
      data.pagemap.cse_image[0] &&
      data.pagemap.cse_image[0]
    ) {
      return data.pagemap.cse_image[0].src;
    } else if (
      data.pagemap &&
      data.pagemap.cse_thumbnail &&
      data.pagemap.cse_thumbnail.length &&
      data.pagemap.cse_thumbnail[0] &&
      data.pagemap.cse_thumbnail[0]
    ) {
      return data.pagemap.cse_thumbnail[0].src;
    } else {
      return "assets/images/profile-picture.png";
    }
  }

  getAllCountries() {
    this._app.getAllCountries().subscribe(res => {
      this.countries = res["data"];
      console.log(res);
    });
  }

  //functions for crop image

  cropImage() {
    let data = {
      image: this.croppedImage
    };
    this.closeModal(data);
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    console.log(event);
  }
  imageLoaded() {
    this.showCropper = true;
    console.log("Image loaded");
  }
  cropperReady() {
    console.log("Cropper ready");
  }
  loadImageFailed() {
    console.log("Load failed");
  }
  rotateLeft() {
    this.imageCropper.rotateLeft();
  }
  rotateRight() {
    this.imageCropper.rotateRight();
  }
  flipHorizontal() {
    this.imageCropper.flipHorizontal();
  }
  flipVertical() {
    this.imageCropper.flipVertical();
  }

  //batch creation function
  onNewBatchCreate($event) {
    this.closeModal($event);
  }

  //update a single record for bulk-upload
  onEditRecord($event) {
    this.closeModal($event);
  }
  // schdule check function
  onNewScheduleEvent($event) {
    this.closeModal($event);
  }

  //function for testing webhook api
  //test webhook
  testWebHook() {
    this._app.testWebHoook().subscribe(
      res => {
        this.webHookResponse = res;
        console.log("WEBHOOK TESTING: ", res);
      },
      err => {
        console.log(err);
      }
    );
  }
  checkForPermission() {
    var that = this;
    navigator.mediaDevices
      .getUserMedia({
        video: true
      })
      .then(
        successCallback => {
          console.log("runn callback", successCallback);
          this.showVideos.showLiveWebCam = true;
          this.showVideos.showRecordedWebCam = false;
          setTimeout(() => {
            var video = <HTMLMediaElement>(
              document.getElementById("liveWebcamVideo")
            );
            video.srcObject = successCallback;
            video.play();
            that.localStream = video;
            this._selfie.videoStream = successCallback;
            this.startVideoRecording();
          }, 0);
        },
        errorCallback => {
          let alertObj = {
            message: "Please Allow Camera",
            status: "danger",
            autoDismiss: true,
            timeToDismiss: 10000
          };
          console.log(alertObj);
          // this._data.displayUniversalAlert(alertObj);
          // this._data.toastrShow("Please Allow Camera", "info");
        }
      );
  }

  startVideoRecording() {
    this.videoRecorder = RecordRTC(this._selfie.videoStream, {
      type: "video",
      frameInterval: 45,
      frameRate: 30
    });
    this.videoRecorder.startRecording();
  }

  triggerSnapshot() {
    var canvas = <HTMLCanvasElement>document.getElementById("snapCanvas");
    var context = canvas.getContext("2d");
    var video = <any>document.getElementById("liveWebcamVideo");
    context.drawImage(video, 0, 0, canvas.width, canvas.height);
    // context.drawImage(video, 0, 0, 640, 480, 0, 0, 640, 480);

    var data = canvas.toDataURL("image/png");
    console.log(data);
    if (this.type === "capture-doc") {
      this.closeModal(data);
    }
    // console.log(data);
  }
  saveRiskPolicy() {
    this.closeModal([
      {
        riskName: this.riskName,
        selectedItems: this.selectedItems
      }
    ]);
  }

  overrideDocVerify(){
    this.closeModal(this.overrideDocForm.value)
  }

    editOverride(editType){
      if(editType === 'name'){
          var payload = {
              "checkGuid" : this.devOpsOverrideData.checkGuid,
              "key" : "name",
              "value": this.individualFrom.value.name!=""?this.individualFrom.value.name:null
          }
      }else if(editType === 'fname'){
        var payload = {
            "checkGuid" : this.devOpsOverrideData.checkGuid,
            "key" : "firstName",
            "value": this.individualFrom.value.fname!=""?this.individualFrom.value.fname:null
          }
      }else if(editType === 'lname'){
      var payload = {
          "checkGuid" : this.devOpsOverrideData.checkGuid,
          "key" : "lastName",
          "value": this.individualFrom.value.lname!=""?this.individualFrom.value.lname:null
          }
      }else if(editType === 'sn'){
          var payload = {
              "checkGuid" :this.devOpsOverrideData.checkGuid,
              "key" : "serialNo",
              "value": this.individualFrom.value.sn!=""?this.individualFrom.value.sn:null
          }
      }else if(editType === 'dob'){
          var payload = {
              "checkGuid" : this.devOpsOverrideData.checkGuid,
              "key" : "dob",
              "value": this.individualFrom.value.dob!=""?this.individualFrom.value.dob:null
          }
      } else if(editType === 'issue'){
          var payload = {
              "checkGuid" : this.devOpsOverrideData.checkGuid,
              "key" : "issueDate",
              "value": this.individualFrom.value.issue!=""?this.individualFrom.value.issue:null
          }
      } else if(editType === 'address'){
          var payload = {
              "checkGuid" : this.devOpsOverrideData.checkGuid,
              "key" : "address",
              "value": this.individualFrom.value.address!=""?this.individualFrom.value.address:null
          }
      } else if(editType === 'expary'){
          var payload = {
              "checkGuid" : this.devOpsOverrideData.checkGuid,
              "key" : "expiryDate",
              "value": this.individualFrom.value.expary!=""?this.individualFrom.value.expary:null
          }
      } 
      this.data.changeLoaderVisibility(true);
      this.devops.editIndividualUserArea(this.devOpsOverrideData.clientId, payload).pipe(finalize(() => this.data.changeLoaderVisibility(false))).subscribe(() =>{
        let popup = {
          message : editType + " " + "updated successfully",
          status: 'success'
        }
        this.data.toastrShow(popup.message, popup.status);
      })
    }

    okOverride(editType){
      if(editType === 'name'){
          var payload = {
              "checkGuid" : this.devOpsOverrideData.checkGuid,
              "key" : "name",
              "status": "PASS"
          }
      }else if(editType === 'fname'){
        var payload = {
            "checkGuid" : this.devOpsOverrideData.checkGuid,
            "key" : "firstName",
            "status": "PASS"
          }
      }else if(editType === 'lname'){
      var payload = {
          "checkGuid" : this.devOpsOverrideData.checkGuid,
          "key" : "lastName",
          "status": "PASS"
          }
      }else if(editType === 'sn'){
          var payload = {
              "checkGuid" : this.devOpsOverrideData.checkGuid,
              "key" : "sn",
              "status": "PASS"
          }
      }else if(editType === 'dob'){
          var payload = {
              "checkGuid" : this.devOpsOverrideData.checkGuid,
              "key" : "dob",
              "status": "PASS"
          }
      } else if(editType === 'issue'){
          var payload = {
              "checkGuid" : this.devOpsOverrideData.checkGuid,
              "key" : "issueDate",
              "status": "PASS"
          }
      } else if(editType === 'address'){
          var payload = {
              "checkGuid" : this.devOpsOverrideData.checkGuid,
              "key" : "address",
              "status": "PASS"
          }
      } else if(editType === 'expary'){
          var payload = {
              "checkGuid" : this.devOpsOverrideData.checkGuid,
              "key" : "expiryDate",
              "status": "PASS"
          }
      } 
      this.data.changeLoaderVisibility(true);
      this.devops.overrideIndividualStatusUserArea(this.devOpsOverrideData.clientId, payload).pipe(finalize(() => this.data.changeLoaderVisibility(false))).subscribe(() =>{
        let popup = {
          message : editType + " " + "verified successfully",
          status: 'success'
        }
        this.data.toastrShow(popup.message, popup.status);
    })
    }

    toggle(){
      this.verification=!this.verification;
    }
}
