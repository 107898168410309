import { Injectable } from '@angular/core';
import { Router, CanDeactivate, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from "rxjs";
import { DataService } from '../services/data.service';

import { TemplateRef } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { SavaDataComponent } from './save-data-modal';

declare var $ : any;

@Injectable()
export class SaveData implements CanDeactivate<any>{
    modalRef: BsModalRef;
    message: string;
    template:any;
    constructor(
        private _router: Router, 
        private data: DataService,
        private modalService: BsModalService
    ) {}
    
    async canDeactivate (component) : Promise<any> {
        if (component.hasUnsavedData()) {
            return await this.openModal();
        }else{
            return true;
        }
    }
    async openModal(){
        let modalRef = this.modalService.show(SavaDataComponent);
        let promise = new Promise((resolve, reject) => {
            modalRef.content.currentMessage.subscribe(val => {
                if(val === 'confirm') resolve(true);
                if(val === 'cancel') resolve(false);
            });
        });
        return promise;
    }
}