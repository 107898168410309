import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
// import { AngularFireAuth } from '@angular/fire/auth';
// import { AngularFireMessaging } from '@angular/fire/messaging';
import * as firebase from 'firebase';
import { DataService } from "../services/data.service";
import { NotificationService } from "../services/notification.service";

import { mergeMapTo } from 'rxjs/operators';
import { take } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs'

@Injectable()
export class MessagingService {

  currentMessage = new BehaviorSubject(null);
  messaging : any;

  constructor(
    private _data: DataService,
    private _noti: NotificationService,
    ) {
      if(firebase.messaging.isSupported()){
      this.messaging = firebase.messaging();
      }
  }

  requestPermission() {
    this.messaging
      .requestPermission()
      .then(() => {
          console.log('Notification permission granted.');
          return this.messaging.getToken()
      })
     .then(token => {
          console.log(token);
          let firebaseToken = this._data.getCookiesByName('fb_token');
          if(!firebaseToken){
              this.updateToken(token);
          }
      })
      .catch((err) => {
          console.log('Unable to get permission to notify.', err);
      });
  }

  updateToken(firebaseToken) {
        this._noti.registerDeviceId(firebaseToken).subscribe((response)=>{
            // console.log(response)
            this._data.setCookieByName('fb_token', firebaseToken);
        }, error=>{
            // console.log(error);
        });
    }

  receiveMessage() {
    this.messaging.onMessage((payload) => {
        // console.log("Message received. ", payload);
        this.currentMessage.next(payload)
    });
  }
}