import { Component, OnInit } from "@angular/core";
import * as moment from "moment";

@Component({
  selector: "app-history",
  templateUrl: "./history.component.html",
  styleUrls: ["./history.component.scss"]
})
export class HistoryComponent implements OnInit {
  //datepicker config
  startDate: any;
  endDate: any;
  dateSelected: any;
  alwaysShowCalendars: boolean;
  ranges: any = {
    Today: [moment(), moment()],
    Yesterday: [moment().subtract(1, "days"), moment().subtract(1, "days")],
    "Last 7 Days": [moment().subtract(6, "days"), moment()],
    "Last 30 Days": [moment().subtract(29, "days"), moment()],
    "This Month": [moment().startOf("month"), moment().endOf("month")],
    "Last Month": [
      moment()
        .subtract(1, "month")
        .startOf("month"),
      moment()
        .subtract(1, "month")
        .endOf("month")
    ]
  };
  maxDate: moment.Moment = moment();

  constructor() {}

  ngOnInit() {}

  choosedDateRanges($event) {
    if ($event.startDate)
      this.startDate =
        $event.startDate.year() +
        "-" +
        $event.startDate.month() +
        "-" +
        $event.startDate.date();
    if ($event.endDate)
      this.endDate =
        $event.endDate.year() +
        "-" +
        $event.endDate.month() +
        "-" +
        $event.endDate.date();
    // console.log(this.startDate, this.endDate);
  }
  choosedDate($event) {
    this.dateSelected = 1;
  }
}
