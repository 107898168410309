export const StripeKey = "pk_test_51K7FT0JTN00GakIFsr8l4vndW8dgK1O30baKedMGGFVB4fwsJgYVpoB3wd7x2ppTJwthLTYqoy1BKtcHr82H4JBs006zDemTNR" ; //DEV
//export const StripeKey = "pk_live_JqFknkpP6c2IKPqdLtVhzFtn00WRITdyg1" ; //PROD


// export const Invoices = [
// 	{
// 	  "id": "4269938471",
// 	  "amount": 30,
// 	  "status": "outstanding",
// 	  "startdate": "12/11/2018",
// 	  "enddate": "12/3/2018"
// 	}, {
// 	  "id": "6329521751",
// 	  "amount": 67,
// 	  "status": "outstanding",
// 	  "startdate": "3/9/2018",
// 	  "enddate": "6/29/2018"
// 	}, {
// 	  "id": "5390786696",
// 	  "amount": 97,
// 	  "status": "paid",
// 	  "startdate": "6/2/2018",
// 	  "enddate": "6/4/2018"
// 	}, {
// 	  "id": "7839739962",
// 	  "amount": 96,
// 	  "status": "overdue",
// 	  "startdate": "11/13/2018",
// 	  "enddate": "6/9/2018"
// 	}, {
// 	  "id": "9059929993",
// 	  "amount": 17,
// 	  "status": "overdue",
// 	  "startdate": "10/12/2018",
// 	  "enddate": "2/22/2018"
// 	}, {
// 	  "id": "3722673070",
// 	  "amount": 87,
// 	  "status": "paid",
// 	  "startdate": "5/9/2018",
// 	  "enddate": "7/13/2018"
// 	}, {
// 	  "id": "9495437296",
// 	  "amount": 61,
// 	  "status": "paid",
// 	  "startdate": "10/1/2018",
// 	  "enddate": "7/8/2018"
// 	}, {
// 	  "id": "8989224985",
// 	  "amount": 51,
// 	  "status": "outstanding",
// 	  "startdate": "7/14/2018",
// 	  "enddate": "9/14/2018"
// 	}, {
// 	  "id": "4441785763",
// 	  "amount": 80,
// 	  "status": "outstanding",
// 	  "startdate": "6/5/2018",
// 	  "enddate": "10/19/2018"
// 	}, {
// 	  "id": "0968843174",
// 	  "amount": 99,
// 	  "status": "paid",
// 	  "startdate": "10/9/2018",
// 	  "enddate": "8/22/2018"
// 	}, {
// 	  "id": "4173088914",
// 	  "amount": 45,
// 	  "status": "outstanding",
// 	  "startdate": "8/13/2018",
// 	  "enddate": "10/4/2018"
// 	}, {
// 	  "id": "1161197699",
// 	  "amount": 47,
// 	  "status": "paid",
// 	  "startdate": "10/20/2018",
// 	  "enddate": "11/12/2018"
// 	}, {
// 	  "id": "1917254873",
// 	  "amount": 2,
// 	  "status": "outstanding",
// 	  "startdate": "7/4/2018",
// 	  "enddate": "1/18/2019"
// 	}, {
// 	  "id": "2937828434",
// 	  "amount": 53,
// 	  "status": "overdue",
// 	  "startdate": "7/29/2018",
// 	  "enddate": "11/24/2018"
// 	}, {
// 	  "id": "8510116881",
// 	  "amount": 80,
// 	  "status": "paid",
// 	  "startdate": "1/6/2019",
// 	  "enddate": "4/16/2018"
// 	}, {
// 	  "id": "6808721297",
// 	  "amount": 99,
// 	  "status": "overdue",
// 	  "startdate": "6/28/2018",
// 	  "enddate": "10/3/2018"
// 	}, {
// 	  "id": "8199602120",
// 	  "amount": 44,
// 	  "status": "paid",
// 	  "startdate": "12/6/2018",
// 	  "enddate": "6/17/2018"
// 	}, {
// 	  "id": "9861252010",
// 	  "amount": 99,
// 	  "status": "paid",
// 	  "startdate": "12/7/2018",
// 	  "enddate": "10/22/2018"
// 	}, {
// 	  "id": "9573071096",
// 	  "amount": 71,
// 	  "status": "outstanding",
// 	  "startdate": "8/18/2018",
// 	  "enddate": "5/12/2018"
// 	}, {
// 	  "id": "9492369192",
// 	  "amount": 97,
// 	  "status": "overdue",
// 	  "startdate": "10/25/2018",
// 	  "enddate": "10/1/2018"
// 	}
// ];