import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from "@angular/forms";
import { ReactiveFormsModule } from "@angular/forms";
// import { NgxStripeModule } from 'ngx-stripe';
import { ArchwizardModule, WizardState } from "angular-archwizard";

// import { CouponsModule } from "../../pages/coupons/coupons.module" ;

import { CheckoutNewComponent } from './checkout-new.component';

@NgModule({
  declarations: [ CheckoutNewComponent ],
  imports: [
    CommonModule,
  	FormsModule,
  	ReactiveFormsModule,
  	ArchwizardModule,
    // CouponsModule,
  	// KycModalModule,
    // NgxStripeModule.forRoot(),
  ],
  exports: [ CheckoutNewComponent ]
})
export class CheckoutNewModule { }
