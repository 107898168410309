import { Directive, Input, OnChanges, SimpleChanges } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator, ValidatorFn, Validators } from '@angular/forms';

/** A hero's name can't match the given regular expression */
export function passwordValidator(password: any): ValidatorFn {
  return (control: AbstractControl): {[key: string]: any} | any => {
    let isPassword;
    if(password !== '_blank'){
    	isPassword = !(password === control.value) ? '*Password should be equal':false;
    }else{
	    isPassword = isPasswordCorrect(control.value);
    }
    return isPassword ? {'isPassword': {value: isPassword}} : null;
  };
}
export function isPasswordCorrect(check){
		if (check.length < 8) {
	        return '*Your password length should be more than 8 characters'; 
	    }else{
		    if (check.search(/[a-z]/i) < 0) {
		        return '*Your password must contain a lower case letter';
		    }else{
			    if (check.search(/[0-9]/) < 0) {
			        return '*Your password should contain a number'; 
			    }else{
			    	if(check.search(/[A-Z]/) < 0){
				        return '*Your password must contain an uppercase letter.'; 
			    	}else{
					    if (check.length > 0) {
					        return false;
					    }
			    	}
			    }
		    }
	    }
	}

@Directive({
  selector: '[password]',
  providers: [{provide: NG_VALIDATORS, useExisting: PasswordValidatorDirective, multi: true}]
})
export class PasswordValidatorDirective implements Validator {
  @Input('password') passwordValue: string;

  validate(control: AbstractControl): {[key: string]: any} | any {
	return this.passwordValue ? passwordValidator('_blank')(control) : '_blank';
  }
}

