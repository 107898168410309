import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CouponsComponent } from './coupons.component';

import { CouponsRoutingModule } from './coupons-routing.module';

@NgModule({
  declarations: [ CouponsComponent ],
  imports: [
    CommonModule,
    CouponsRoutingModule
  ],
  exports: [ CouponsComponent ]
})
export class CouponsModule { }
