import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { HistoryComponent } from "./pages/history/history.component";
import { LoginComponent } from "./pages/auth/login/login.component";
import { RegisterComponent } from "./pages/auth/register/register.component";
import { PasswordComponent } from "./pages/auth/password/password.component";
import { ClientConfigComponent } from "./components/client-config/client-config.component";
import { BulkUploadResultComponent } from "./pages/bulk-upload-result/bulk-upload-result.component";
import { PaymentComponent } from "./pages/payment/payment.component";
import { ContactComponent } from "./pages/contact/contact.component";
import { TermsComponent } from "./pages/terms/terms.component";
import { PrivacypolicyComponent } from "./pages/privacypolicy/privacypolicy.component";
import { ReturnpolicyComponent } from "./pages/returnpolicy/returnpolicy.component";
import { NotfoundComponent } from "./shared/notfound/notfound.component";
import { VerifyComponent } from "./shared/verify/verify.component";

//Guards
import { AuthGuard } from "./guards/auth.guard";
import { DeGuard } from "./guards/deactivate.guard";
import { AccessGuard } from "./guards/access.guard";
import { PaymentGuard } from "./guards/payment.guard";
import { NewReportPageComponent } from './pages/reports/new-report-page/new-report-page.component';
import { ApiDocComponent } from "./pages/api-doc/api-doc.component";
import { ProfileSearchComponent } from "./pages/aml-search/profile-search/profile-search.component";
import { PageComponentResolver } from "./resolvers/page-component.resolver";
import { AmlFalsePositiveComponent } from "./pages/aml-false-positive/aml-false-positive.component";

const routes: Routes = [
  {
    path: "",
    redirectTo: "home",
    pathMatch: "full"
  },
  // {
  //   path: "home",
  //   component: DashboardComponent,
  //   canActivate : [AuthGuard]
  // },
  {
    path: "home",
    pathMatch: "full",
    loadChildren: "./pages/dashboard/dashboard.module#DashboardModule",
    canActivate: [AuthGuard],
    resolve:{ compList: PageComponentResolver }
  },

  // {
  //   path: "user-reports",
  //   component: ReportsComponent,
  //   canActivate : [AuthGuard]
  // },
  {
    path: "user-reports",
    loadChildren: "./pages/reports/reports.module#ReportsModule",
    canActivate: [AuthGuard]
  },

  {
    path: "new-ind-report",
    component: NewReportPageComponent,
    canActivate: [AuthGuard]
  },
  {
    path: "bulk-upload-result",
   loadChildren:"./pages/bulk-upload-result/bulk-upload-result.module#BulkUploadResultModule",
    canActivate: [AuthGuard]
  },

  // {
  //   path: "user-reports/:userName",
  //   component: ReportDetailsComponent,
  //   canActivate : [AuthGuard]
  // },
  {
    path: "user-reports/:userName",
    loadChildren:
      "./pages/report-details/report-details.module#ReportDetailsModule",
    canActivate: [AuthGuard]
  },
  // {
  //   path: "aml-alerts/:userName",
  //   component: AmlAlertDetailsComponent,
  //   canActivate : [AuthGuard]
  // },
  // {
  //   path: "corp-reports/:userName",
  //   component: CorpReportComponent,
  //   canActivate : [AuthGuard]
  // },
  {
    path: "corp-reports/:userName",
    loadChildren: "./pages/corp-report/corp-report.module#CorpReportModule",
    canActivate: [AuthGuard]
  },
  // {
  //   path: "aml-alerts",
  //   component: AmlAlertsComponent,
  //   canActivate : [AuthGuard]
  // },
  {
    path: "aml-alerts",
    loadChildren: "./pages/aml-alerts/aml-alerts.module#AmlAlertsModule",
    canActivate: [AuthGuard]
  },
  {
    path: "expired-checks",
    loadChildren: "./pages/check-status/check-status.module#CheckStatusModule",
    canActivate: [AuthGuard]
  },
  {
    path: "override-report",
    loadChildren: "./pages/override-report/override-report.module#OverrideReportModule",
    canActivate: [AuthGuard]
  },
  {
    path: "consumption-report",
    loadChildren: "./pages/consumtion-report/consumtion-report.module#ConsumtionReportModule",
    canActivate: [AuthGuard]
  },

  // {
  //   path: "user-home",
  //   component: UserHomeComponent,
  //   canActivate : [AuthGuard]
  // },
  {
    path: "user-home",
    loadChildren: "./pages/user-home/user-home.module#UserHomeModule",
    canActivate: [AuthGuard]
  },

  {
    path: "notifications",
    loadChildren: "./pages/notification/notification.module#NotificationModule",
    canActivate: [AuthGuard]
  },

  {
    path: "doc-extraction",
    loadChildren:
      "./pages/doc-extraction/doc-extraction.module#DocExtractionModule",
    canActivate: [AuthGuard, AccessGuard]
  },
  {
    path: "activity-log",
    loadChildren:
      "./pages/activity-log/activity-log.module#ActivityLogModule",
    canActivate: [AuthGuard]
  },
  {
    path:"dev-ops",
    loadChildren:
      "./pages/dev-ops/dev-ops.module#DevOpsModule",
      canActivate: [AuthGuard]
  },
  {
    path: "analytics",
    component: HistoryComponent,
    canActivate: [AuthGuard]
  },
  // {
  //   path: "company",
  //   component: CorporateCheckComponent,
  //   canActivate : [AuthGuard]
  // },
  {
    path: "company",
    loadChildren:
      "./components/corporate-check/corporate-check.module#CorporateCheckModule",
    canActivate: [AuthGuard]
  },

  // {
  //   path: "coupons",
  //   component: CouponsComponent,
  //   canActivate : [AuthGuard]
  // },
  {
    path: "coupons",
    loadChildren: "./pages/coupons/coupons.module#CouponsModule",
    canActivate: [AuthGuard]
  },
  // {
  //   path: "pricing",
  //   component: PricingStructureComponent,
  //   canActivate : [AuthGuard]
  // },
  //   {
  //     path: "pricing",
  //     loadChildren:
  //       "./pages/pricing-structure/pricing-structure.module#PricingStructureModule",
  //     canActivate: [AuthGuard]
  //   },
  // {
  //   path: "live-check",
  //   // component: LiveCheckComponent,
  //   canActivate : [AuthGuard],
  //   children: [
  //     // {path: '', redirectTo: 'category' , pathMatch : "full"  },
  //     {path: '', component: LiveCheckComponent  },
  //     // {path: ':category/basic-info', component: LiveCheckComponent},
  //     {path: ':category/:hashId', component: LiveCheckComponent}
  //   ]
  // },
  // {
  //   path: "live-check",
  //   component: LiveCheckComponent,
  //   canActivate : [AuthGuard]
  // },
  // {
  //   path: "live-check/:category/:hashId",
  //   component: LiveCheckComponent,
  //   canActivate : [AuthGuard]
  // },
  {
    path: "one-time-setup",
    loadChildren:
      "./pages/pricing-new/pricing-new.module#PricingNewModule",
    canActivate: [AuthGuard],
  },

  {
    path: "aml-search",
    loadChildren: "./pages/aml-search/aml-search.module#AmlSearchModule",
    canActivate: [AuthGuard, AccessGuard],
    runGuardsAndResolvers: "always"
  },
  {
    path: "profile-search",
    loadChildren: "./pages/aml-screening/aml-screening.module#AmlScreeningModule",
    canActivate: [AuthGuard, AccessGuard],
    runGuardsAndResolvers: "always"
  },
  {
    path: "corporate-search",
    loadChildren:
      "./pages/corporate-search/corporate-search.module#CorporateSearchModule",
    canActivate: [AuthGuard, AccessGuard],
    runGuardsAndResolvers: "always"
  },
  {
    path: "digital-verification",
    loadChildren:
      "./pages/digital-verification/digital-verification.module#DigitalVerificationModule",
    canActivate: [AuthGuard, AccessGuard],
    runGuardsAndResolvers: "always"
  },
  {
    path: "live-check",
    loadChildren: "./pages/live-check/live-check.module#LiveCheckModule",
    canActivate: [AuthGuard]
  },
  // {
  //   path: "settings",
  //   component: SettingsComponent,
  //   canActivate : [AuthGuard]
  // },
  {
    path: "settings",
    loadChildren: "./pages/settings/settings.module#SettingsModule",
    canActivate: [AuthGuard]
  },
  // {
  //   path: "invoices",
  //   component: InvoicesComponent,
  //   canActivate : [AuthGuard]
  // },
  {
    path: "invoices",
    loadChildren: "./pages/invoices/invoices.module#InvoicesModule",
  },

  // {
  //   path: "invoices/:id",
  //   component: InvoicesComponent,
  //   canActivate : [AuthGuard]
  // },
  // {
  //   path: "subscriptions",
  //   component: SubscriptionsComponent,
  //   canActivate : [AuthGuard]
  // },
  {
    path: "subscriptions",
    loadChildren:
      "./pages/subscriptions/subscriptions.module#SubscriptionsModule",
    canActivate: [AuthGuard]
  },

  // {
  //   path: "roles",
  //   component: RolesComponent,
  //   canActivate : [AuthGuard]
  // },
  {
    path: "roles",
    loadChildren: "./pages/roles-new/roles.module#RolesModule",
    canActivate: [AuthGuard, AccessGuard]
  },

  {
    path: "access-denied",
    loadChildren:
      "./pages/access-denied/access-denied.module#AccessDeniedModule",
    canActivate: [AuthGuard]
  },
  // {
  //   path: "developer",
  //   component: DeveloperComponent,
  //   canActivate : [AuthGuard]
  // },
  // {
  //   path: "faq",
  //   component: FaqComponent,
  //   canActivate : [AuthGuard]
  // },
  {
    path: "faq",
    loadChildren: "./pages/faq/faq.module#FaqModule",
    canActivate: [AuthGuard]
  },
  {
    path: "bulk-upload",
    loadChildren: "./pages/bulk-upload/bulk-upload.module#BulkUploadModule",
    canActivate: [AuthGuard, AccessGuard]
  },

  {
    // path: "services",
    path: "payment",
    loadChildren: "./pages/payment/payment.module#PaymentModule",
    canActivate: [DeGuard]
  },

  // {
  //   path: "payment",
  //   component: PaymentComponent
  // },

  {
    path: "login",
    component: LoginComponent,
    canActivate: [DeGuard]
  },
  {
    path: "contact",
    component: ContactComponent,
    canActivate: [AuthGuard]
  },
  {
    path: "tnc",
    component: TermsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: "privacy-policy",
    component: PrivacypolicyComponent,
    canActivate: [AuthGuard]
  },
  {
    path: "cancel-refund",
    component: ReturnpolicyComponent,
    canActivate: [AuthGuard]
  },
  {
    path: "register",
    component: RegisterComponent,
    canActivate: [DeGuard]
  },
  // {
  //   path: "test",
  //   component: TestComponent
  // },
  {
    path: "test",
    loadChildren: "./shared/test/test.module#TestModule",
    canActivate: [AuthGuard]
  },
  // {
  //   path: "theming",
  //   component: ThemingComponent,
  //   canActivate : [AuthGuard]
  // },
  {
    path: "theming",
    loadChildren: "./pages/theming/theming.module#ThemingModule",
    canActivate: [AuthGuard, AccessGuard]
  },
  {
    path: "customize",
    loadChildren: "./pages/customize/customize.module#CustomizeModule",
    canActivate: [AuthGuard]
  },
  //Password Related
  {
    path: "reset-password",
    component: PasswordComponent,
    canActivate: [DeGuard]
  },
  {
    path: "setup-account",
    component: PasswordComponent,
    canActivate: [DeGuard]
  },
  {
    path: "forgot-password",
    component: PasswordComponent,
    canActivate: [DeGuard]
  },
  {
    path: "verify-email",
    component: PasswordComponent,
    canActivate: [DeGuard]
  },

  {
    path: "change-password",
    component: PasswordComponent,
    canActivate: [AuthGuard]
  },
  {
    path: "api-doc",
    component: ApiDocComponent,
    canActivate: [AuthGuard]
  },
  {
    path: "404",
    component: NotfoundComponent
  },
  {
    path: "verify",
    component: VerifyComponent,
  },
  {
    path:"aml-false-positive",
    component:AmlFalsePositiveComponent,
    canActivate:[AuthGuard]
  },
  {
    path: "**",
    redirectTo: "/404"

    // children: [
    //   {path: '', redirectTo: '404' , pathMatch : "full"  },
    //   {path: '404', component: NotfoundComponent  }    ]
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: "enabled",
      useHash: false,
      anchorScrolling: "enabled",
      onSameUrlNavigation: "reload",
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
