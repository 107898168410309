import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PipesModule } from "./../../../pipes/pipes.module" ;
import { SavedCompanyDataComponent } from "./saved-company-data.component" ;

import { ShowImageModule } from './../../../directives/show-image/show-image.module';

import { TabsModule } from "ngx-bootstrap/tabs";
import { FormsModule } from "@angular/forms";

@NgModule({
  declarations: [ SavedCompanyDataComponent ],
  imports: [
    CommonModule,
    FormsModule,
    PipesModule,
    TabsModule,
    ShowImageModule
  ],
  exports: [ SavedCompanyDataComponent ]
})
export class SavedCompanyDataModule { }
