import { Injectable, PLATFORM_ID, Inject } from "@angular/core";
import { isPlatformBrowser, DOCUMENT } from "@angular/common";
import { Observable } from "rxjs";
import { BehaviorSubject } from "rxjs";
import { ToastrService } from "ngx-toastr";
import { CookieService } from "ngx-cookie";
import { LayoutService } from "./layout.service";
import { DomSanitizer } from "@angular/platform-browser";
import { Subscription } from "rxjs/Subscription";
import { Router } from "@angular/router";
// import { TourService } from 'ngx-tour-ngx-bootstrap';
// import { TourService } from 'ngx-tour-core';
// import { TourService } from 'ngx-tour-ngx-bootstrap';
@Injectable({
  providedIn: "root"
})
export class DataService {
  private token = new BehaviorSubject<Object>("");
  tokenInfo = this.token.asObservable();

  public showLoader = new BehaviorSubject<boolean>(false);
  loaderInfo = this.showLoader.asObservable();

  public themeName = new BehaviorSubject<any>('');
  themeFlag(flag: any){
    this.themeName.next(flag)
  }

  changeLoaderVisibility(shouldShow: boolean) {
    this.showLoader.next(shouldShow);
  }
  public country = new BehaviorSubject<any>('');
  countryVal(val){
      this.country.next(val);
  }
  public showLoaderAML = new BehaviorSubject<boolean>(false);
  loaderInfoAML = this.showLoaderAML.asObservable();

  changeLoaderVisibilityAML(shouldShow: boolean) {
    this.showLoaderAML.next(shouldShow);
  }

  public clientData = new BehaviorSubject<object>(null);
  loadClientData = this.clientData.asObservable();

  public changeClientData(value) {
    this.clientData.next(value);
  }
  public clientStandardServices = new BehaviorSubject<object>(null);
  loadClientStandardServices = this.clientStandardServices.asObservable();

  public changeClientStandardServices(value) {
    this.clientStandardServices.next(value);
  }

  public clientStandardChecks = new BehaviorSubject<object>(null);
  loadClientStandardChecks = this.clientStandardChecks.asObservable();

  public changeClientStandardChecks(value) {
    this.clientStandardChecks.next(value);
  }
  corporateId:any[] = [];
  subscriptions : Subscription[] = []; 

  allowedSidebarServices : string[] = 
      [ 
        "aml-search",
        "corporate-search",
        "doc-extraction",
        "bulk-upload"
      ];

  allowedLiveCheckServices : string[] = 
      [ 
       "id-doc-check",
       "selfie-check",
       "liveness-check",
       "address-doc-check",
       "sanctions-check",
       "crime-check",
       "pep-check", 
       "adverse-media-check", 
       "corporate-check",      
      ];

  constructor(
    private toastr: ToastrService,
    private _cookieService: CookieService,
    private _layoutService: LayoutService,
    // public tourService : TourService,
    public sanitizer: DomSanitizer,
    public _router:Router
  ) {}

  getCookiesByName(slug: any) {
    return this._cookieService.get(slug);
  }

  getCookieObjectByName(slug: any) {
    return this._cookieService.getObject(slug);
  }

  setCookieByName(slug: any, value: any, expire?: any) {
    let expires;
    if (expire instanceof Date) {
      expires = expire;
    } else if (!expire) {
      expires = "";
    } else {
      expires = this.getCookieSessionTime(expire);
    }
    this._cookieService.put(slug, value, {
      expires: expires
    });
  }

  pushToArray(arr, obj) {
    const index = arr.findIndex(e => e.id === obj.id);
    if (index === -1) {
      arr.push(obj);
    } else {
      arr[index] = obj;
    }
  }

  setCookieObject(slug: any, value: any, expire?: any) {
    let expires;
    if (expire instanceof Date) {
      expires = expire;
    } else if (!expire) {
      expires = "";
    } else {
      expires = this.getCookieSessionTime(expire);
    }
    this._cookieService.putObject(slug, value, {
      expires: expires
    });
  }

  getNormalizedDate(inputDate) {
    if (inputDate) {
      var tzoffset = new Date().getTimezoneOffset() * 60000;
      // var localISOTime = new Date(inputDate)
      var localISOTime = new Date(inputDate - tzoffset)
        .toISOString()
        .split("T")[0];
      return localISOTime;
    }
    return inputDate;
  }

  getRoundOffValue(val) {
    if (val) {
      return parseFloat(val).toFixed(2);
    } else {
      return 0;
    }
  }

  removeAllCookie() {
    this._cookieService.removeAll();
  }

  getCookieSessionTime(days: any) {
    var session = new Date();
    days = days ? days : 1;
    session.setTime(session.getTime() + days * 24 * 60 * 60 * 1000);
    return session;
  }

  changeToken(token: any) {
    this.token.next(token);
  }

  removeQueryParams(url) {
    let currentUrl = url.substring(0, url.indexOf("?")).length
      ? url.substring(0, url.indexOf("?"))
      : url;
    return currentUrl;
  }

  transform(html) {
    // return this.sanitizer.bypassSecurityTrustResourceUrl(html);
    // return this.sanitizer.bypassSecurityTrustStyle(html);
    // return this.sanitizer.bypassSecurityTrustResourceUrl(html);
    // return this.sanitizer.bypassSecurityTrustUrl(html);
    return this.sanitizer.bypassSecurityTrustResourceUrl(
      "data:image/jpeg;base64," + html
    );
  }

  toastrShow(message: any, type: any) {
    this.toastr.clear();
    switch (type) {
      case "info": {
        this.toastr.info("", message);
        break;
      }
      case "error": {
        this.toastr.error("", message);
        break;
      }
      case "success": {
        this.toastr.success("", message);
        break;
      }
      case "expiry":{
      let mm=this.toastr.error("",message)
       break;
      }
      default: {
        this.toastr.warning("", message);
        break;
      }
    }
  }

  elementInViewport(el) {
    var top = el.offsetTop;
    var left = el.offsetLeft;
    var width = el.offsetWidth;
    var height = el.offsetHeight;

    while (el.offsetParent) {
      el = el.offsetParent;
      top += el.offsetTop;
      left += el.offsetLeft;
    }

    return (
      top < window.pageYOffset + window.innerHeight &&
      left < window.pageXOffset + window.innerWidth &&
      top + height > window.pageYOffset &&
      left + width > window.pageXOffset
    );
  }
  jsonify = data => (data ? JSON.parse(data) : {});

  getKeys = data => (data ? Object.keys(data) : []);

  attrToWords = (a, regx, out) =>
    a.charAt(0).toUpperCase() +
    a
      .slice(1)
      .replace(regx, out)
      .trim();

  covertKeyValuesToString = a =>
    this.attrToWords(Object.keys(a).join("&"), /([A-Z])/g, " $1") +
    "&" +
    Object.values(a).join("&");

  covertStringToKeyValues(a) {
    var data = {},
      i = 0;
    var arr = a.split("&amp;");
    var half = arr.length / 2;
    while (i < half) {
      data[arr[i].replace(/\"\'/g, "")] = arr[i + half];
      i++;
    }
    return data;
  }
  stringToDateFormated(date) {
    var str = JSON.stringify(date)
      .split('":')
      .map(e =>
        e
          .split('"')
          .map(d => {
            console.log(d);
            var date_regex1 = /^(0[1-9]|1\d|2\d|3[01])\/(0[1-9]|1[0-2])\/(19|20)\d{2}$/;
            var date_regex2 = /^(0[1-9]|1\d|2\d|3[01])\-(0[1-9]|1[0-2])\-(19|20)\d{2}$/;
            return date_regex1.test(d) || date_regex2.test(d)
              ? new Date(
                  d
                    .split("/")
                    .reverse()
                    .join("-")
                ).toISOString()
              : d;
          })
          .join('"')
      )
      .join('":');

    return JSON.parse(str);
  }
  // startTour(){
  //        this.tourService.initialize([{
  //            anchorId: 'Home',
  //            content: 'Welcome to KycHub',
  //            placement: 'bottom',
  //            title: `Welcome `,
  //            route: 'home'
  //        }],{
  //            route: 'home',
  //        });
  //        this.tourService.start();
  //    }

  isSidebarServicesPresent(services){
    services = [].concat(services)
                 .map(obj=> obj.slug);

    return this.allowedSidebarServices
               .map(item => services.includes(item) ? false : item)
               .filter(obj => obj);
  }

  isLiveCheckServicesPresent(services){

    var checks = [];
    services.forEach(function(s){
      if(s.slug == 'idv'){
        checks.push('id-doc-check');
        checks.push('selfie-check');
        checks.push('liveness-check');
      }else if(s.slug == 'address-check'){
        checks.push('address-doc-check');
      }else if(s.slug == 'aml-check'){
        checks.push('sanctions-check');
        checks.push('crime-check');
        checks.push('pep-check'); 
        checks.push('adverse-media-check'); 
      }else{
        checks.push(s.slug)
      }
    });
    return this.allowedLiveCheckServices
               .map(item => checks.includes(item) ? false : item)
               .filter(obj => obj);
  }

  unsubscribeAll(){
    this.subscriptions.map(s => {
      s.unsubscribe();
    })
  }

  storeId(id:any){
    this.corporateId.push(id);
  }
  clearStoreId(){
    this.corporateId=[];
  }
}
