import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AmlScreeningRoutingModule } from './aml-screening-routing.module';
import { AmlScreeningComponent } from './aml-screening.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AmlScreeningReportComponent } from './aml-screening-report/aml-screening-report.component';
import { AmlScreeningCorpReportComponent } from './aml-screening-corp-report/aml-screening-corp-report.component';
import { PaginationModule } from 'ngx-bootstrap/pagination';

@NgModule({
  declarations: [AmlScreeningComponent, AmlScreeningReportComponent, AmlScreeningCorpReportComponent],
  imports: [
    CommonModule,
    AmlScreeningRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    PaginationModule.forRoot(),
  ]
})
export class AmlScreeningModule { }
