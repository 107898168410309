export class CreditExpenditure{
	creditSpentAddressDoc : any = 0 ;
	creditSpentAml        : any = 0 ;
	creditSpentIdentityDoc: any = 0 ;
	creditSpentLiveness   : any = 0 ;
	creditSpentSelfie     : any = 0 ;
	creditSpentTotal      : any = 0 ;
}

export class BasicData{
	completedChecks   : string ;
	startDate         : string ;
	completionDate    : string ;
	appSources        : any ;
	creditSpentTotal  : any ;
	totalChecks       : string ;
	totalAddressDocs  : any ;
	totalIdentityDocs : any ;
	totalDocs         : any ;
	identityInfo      : any ;
	identityInfoType  : any ;
	activeExpenditure : CreditExpenditure
	archiveExpenditure: CreditExpenditure
}
declare function scoreCircle(): any;

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router, ActivatedRoute, Params, NavigationEnd } from "@angular/router";
import { BsModalService } from "ngx-bootstrap/modal";
import { BsModalRef } from "ngx-bootstrap/modal/bs-modal-ref.service";
import { KycModalComponent } from "../../../shared/kyc-modal/kyc-modal.component";
import { DataService } from "../../../services/data.service";
import { AppService } from "../../../services/app.service";
import { finalize, first } from 'rxjs/operators';
import { forkJoin } from "rxjs";
import swal from 'sweetalert';
import * as FileSaver from 'file-saver';
import {Verification} from "../../../models/Verification.model";
import { TrackingService } from '../../../../app/services/tracking.service';
import { DevOpsService } from '../services/dev-ops.service';

@Component({
  selector: "app-user-report-devops",
  templateUrl: "./user-report.component.html",
  styleUrls: ["./user-report.component.scss"],
})
export class UserReportComponent implements OnInit {
  bsModalRef: BsModalRef;
  userName: string;
  isOpen: any[] = [];
  verification: any = {};
  @Input() appuserName:any;
  @Output() close = new EventEmitter<any>();
  reportResult: any[] = [
    {
      name: "Warnings",
      icon: "fa-exclamation-circle",
      type: "warnings",
      data: null,
      status: null,
    },
    {
      name: "Identity Report",
      icon: "fa-id-card",
      type: "identityDocProcessedInfo",
      data: null,
      status: null,
    },
    {
      name: "Identity Report",
      icon: "fa-id-card",
      type: "identityDocProcessedInfo2",
      data: null,
      status: null,
    },
    {
      name: "Comparison Report",
      icon: "fa-id-card",
      type: "docMatchProcessedInfo",
      data: null,
      status: null,
    },
    {
      name: "Address Report",
      icon: "fa-address-card",
      type: "addressDocProcessedInfo",
      data: null,
      status: null,
    },
    {
      name: "Selfie Report",
      icon: "fa-eye",
      type: "selfieProcessedInfo",
      data: null,
      status: null,
    },
  ];

  colors: any[]= [
    {key:'sip',color:'#ED7C15'},
    {key:'pep',color:'#8B60D4'},
    {key:'sie',color:'#ED7C15'},
    {key:'crime',color:'#E05B3B'},
    {key:'watchlist',color:'#D262BA'},
    {key:'sanction',color:'#DCAF0E'}
  ];

  categories: any[] = [];
  

  knOptions = {
    readOnly: true,
    size: 120,
    unit: "%",
    textColor: "#ffffff",
    fontSize: "18",
    fontWeigth: "400",
    fontFamily: "Roboto",
    valueformat: "percent",
    value: 0,
    max: 100,
    trackWidth: 15,
    barWidth: 15,
    trackColor: "#e1e1e1",
    barColor: "#ff0000",
  };

  // score              : any = 77.5 ;
  score: any = null;

  isheroAPILoaded: boolean = false;

  basicData: BasicData = new BasicData();
  endUserInfo: any;
  userSelfie: any = null;

  activityLog: any = "Activity Data Comes Here";
  expenditure: any;

  verificationID: Verification = new Verification();
  verificationID2: Verification = new Verification();
  verificationAddress: Verification = new Verification();

  //Result Data
  addressDocProcessedInfo: any;
  amlProcessedInfo: any = {
    crimeProcessedInfo: null,
    pepProcessedInfo: null,
    sanctionProcessedInfo: null,
  };
  adverseMediaProcessedInfo2: any;
  identityDocProcessedInfo: any;
  identityDocProcessedInfo2:any;
  livenessProcessedInfo: any;

  //for aml-details component ;
  amlCheckData: any = null;
  amlCheckLinks: any = null;

  //for amlComment
  amlComment: any = null;

  //for header bar overall
  overAllStatus: any;

  hasScheduledAlert: boolean = false;

  //for biometrics
  biometrics: any;
  reprocessWarningMessage =
	" Reprocessing this record will overwrite any existing preprocessing and manual over-rides. Do you wish to proceed? ";
  showReprocessBtn = true;
  overAllReportStatus: string;	
	activateResendButton: any;
  activateRedoButton : any;
  activateNotify : any;
	showRedoBtn: any;
	redoPopupMessage: any;
  last: string;
  result: boolean;
  idvWarningMessage: any;
  idvWarningMessage2: any;
  
  selfieWarningMessage: any;
  livenessWarningMessage: any;
  addressWarningMessage: any;
  guid: any=null;
  crrResponse: any;
  clientId:any;

  constructor(
    private _data: DataService,
    private modalService: BsModalService,
    public router: Router,
    private _app: AppService,
    private devops:DevOpsService,
    private activatedRoute: ActivatedRoute,
    private _track:TrackingService
  ) {
    
    this.activatedRoute.params.subscribe(params => {
      this.clientId = params["id"];
    });
     
  }
   
  
  SwalOverlayColor(){
    setTimeout(function(){
      $(".swal-modal").css({"background-color":"#2f3e47","width":"337px", "font-family":"serif"});
      $(".swal-button-container").css({"margin":"35px"});
      $(".swal-title").css({"color": "#999999","font-size":"22px"});
      $(".swal-text").css({"color": "#999999"});
    },200);
  }
  showWarningBox() {
    let toastermessage :any
    this.SwalOverlayColor()
    swal({
      title: "Are you sure?",
      text:" Reprocessing this record will overwrite any existing preprocessing and manual over-rides.  ",
      icon: "warning",
      buttons:[true,true],
      dangerMode: true,
      
      
  })
  .then((willDelete) => {
      if (willDelete) {
        this._data.changeLoaderVisibility(true);
        this.getActivityLog();
        this._app.reprocessExistingReport(this.userName).subscribe((res) => {
          this._track.activityLog();
        toastermessage = res.message;
      this._data.changeLoaderVisibility(false);
      this.showReprocessBtn = false;
      this._data.toastrShow(toastermessage, "info");
      this.getUserNameDetails();
        this.getUsersScheduledAlerts();
        this.getBasicUserInfo();
      },
      (err) => {
      this._data.changeLoaderVisibility(false);
      this._data.toastrShow("An error occurred while processing", "error");
      });
      } else {
          return false
      }
  });
  console.log("condition",this.result)
	// const result = window.confirm(this.reprocessWarningMessage);
    if (this.result) {
    //   this._data.changeLoaderVisibility(true);
    //   this._app.reprocessExistingReport(this.userName).subscribe((res) => {
		//   toastermessage = res.message;
		// this._data.changeLoaderVisibility(false);
		// this.showReprocessBtn = false;
		// this._data.toastrShow(toastermessage, "info");
	  // },
	  // (err) => {
		// this._data.changeLoaderVisibility(false);
		// this._data.toastrShow("An error occurred while processing", "error");
	  // });
    }
  }

  showRedoWarningBox() {
  let toastermessage :any
  this.SwalOverlayColor()
  swal({
    title: "Do you wish to continue ?",
    text:this.redoPopupMessage,
    icon: "warning",
    buttons:[true,true],
    dangerMode: true,
    
    
})
.then((willDelete) => {
    if (willDelete) {
      this.openRedoModal();
      this.getActivityLog();
      (err) => {
        this._data.changeLoaderVisibility(false);
        this._data.toastrShow("An error occurred while processing", "error");
      };
    } else {
        return false
    }
});
  // const result = window.confirm(this.redoPopupMessage);
  // if (result) {
  
  // this.openRedoModal();
	
	// (err) => {
	//   this._data.changeLoaderVisibility(false);
	//   this._data.toastrShow("An error occurred while processing", "error");
	// };
  // }
}
  showResendWarningBox() {
	
  let toastermessage
  this.SwalOverlayColor()
    swal({
      title: "Do you wish to continue ?",
      text:" A remote link will be delivered to the email address.   ",
      icon: "warning",
      buttons:[true,true],
      dangerMode: true,
      
      
  })
  .then((willDelete) => {
      if (willDelete) {
        this.openReinviteModal();
        this.getActivityLog();
      // this._data.changeLoaderVisibility(true);
      
	  (err) => {
		this._data.changeLoaderVisibility(false);
		this._data.toastrShow("An error occurred while processing", "error");
	  };
      } else {
          return false
      }
  });
	// const result = window.confirm("A remote link will be delivered to the email address. Do you wish to continue ?");
  //   if (result) {
  //     this.openReinviteModal();
  //     // this._data.changeLoaderVisibility(true);
      
	//   (err) => {
	// 	this._data.changeLoaderVisibility(false);
	// 	this._data.toastrShow("An error occurred while processing", "error");
	//   };
  //   }
  }

  getBiometricSelfie(checkGuid) {
    this.devops.getBiometricSelfie(checkGuid,this.clientId).subscribe((res) => {
      console.log(res);
      this.userSelfie = res.data ? `data:image/png;base64,${res.data}` : null;
    });
  }
  openRedoModal() {
    const initialState = {
      title              : `Redo check`,
	  type               : "redo-check",
	  userName           : this.userName
    };
    this.bsModalRef = this.modalService.show(KycModalComponent, {
        initialState,
        class: "modal-lg"
    });
    this.bsModalRef.content.closeBtnName = "Close";
    console.log(this.bsModalRef.content.onClose);
    this.bsModalRef.content.onClose.subscribe(res => {
        this.showRedoBtn = res;
    });
  }
  openReinviteModal() {
    const initialState = {
      title              : `Reinvite check`,
	  type               : "reinvite-check",
	  userName           : this.userName
    };
    this.bsModalRef = this.modalService.show(KycModalComponent, {
        initialState,
        class: "modal-lg"
    });
    this.bsModalRef.content.closeBtnName = "Close";
    console.log(this.bsModalRef.content.onClose);
    this.bsModalRef.content.onClose.subscribe(res => {
        this.showRedoBtn = res;
    });
  }
  openWarningMessageModal() {
    const initialState = {
      title              : `Warning Details`,
	  type               : "warning-message",
    idvWarningMessage           : this.idvWarningMessage,
    idvWarningMessage2          : this.idvWarningMessage2,
    addressWarningMessage           : this.addressWarningMessage,
    selfieWarningMessage           : this.selfieWarningMessage,
    livenessWarningMessage           : this.livenessWarningMessage,
    
    
    };
    this.bsModalRef = this.modalService.show(KycModalComponent, {
        initialState,
        class: "modal-lg"
    });
    this.bsModalRef.content.closeBtnName = "Close";
    console.log(this.bsModalRef.content.onClose);
    this.bsModalRef.content.onClose.subscribe(res => {
        this.showRedoBtn = res;
    });
  }
  openDetailsModal(val){
    const isTableDisable = val.every((e) => e.riskUnitRes.length === 0);
    console.log(29,val,isTableDisable)
    const initialState = {
      title: `Risk Breakdown`,
      type: "details-modal",
      arrayList: val.map(e=>({...e,tableTitle:e.key.slice(4),totalCount:(e.score*e.weight).toFixed(2)})),
      isTableDisable,
    };
    this.bsModalRef = this.modalService.show(KycModalComponent, {
        initialState,
        class: "modal-lg"
    });
    this.bsModalRef.content.closeBtnName = "Close";
    console.log(this.bsModalRef.content.onClose);
    this.bsModalRef.content.onClose.subscribe(res => {
        this.showRedoBtn = res;
    })

  }
  
  getUsersScheduledAlerts() {
    let query = {
      size: 1,
      page: 0,
    };
    this.devops.getUsersScheduledAlerts(this.userName, query,this.clientId).subscribe((res) => {
      let usersAlerts = res.data.content;
      if (usersAlerts && usersAlerts.length) {
        // this.hasScheduledAlert = true;
      }
    });
  }
  reMapAmlData(res){
    console.log(31,res.data.activeChecks.amlProcessedInfo)
    let temp = res.data.activeChecks.amlProcessedInfo
    
    if(temp.pepProcessedInfo && temp.pepProcessedInfo.pepProcessedInfo.resultList && temp.pepProcessedInfo.pepProcessedInfo.resultList.length > 0 && temp.pepProcessedInfo.pepProcessedInfo.resultList[0].amlSearchContent){
      let pepArr = temp.pepProcessedInfo.pepProcessedInfo.resultList[0].amlSearchContent.details
      temp.crimeProcessedInfo.crimeProcessedInfo.resultList[0].amlSearchContent = {...temp.pepProcessedInfo.pepProcessedInfo.resultList[0].amlSearchContent}
      temp.sanctionProcessedInfo.sanctionProcessedInfo.resultList[0].amlSearchContent = {...temp.pepProcessedInfo.pepProcessedInfo.resultList[0].amlSearchContent}
    
      temp.pepProcessedInfo.pepProcessedInfo.resultList[0].amlSearchContent['details'] = pepArr.filter(e=>e.category.findIndex(p=>p.slug ==='pep') !== -1)
      temp.crimeProcessedInfo.crimeProcessedInfo.resultList[0]['amlSearchContent']['details']= pepArr.filter(e=>e.category.findIndex(p=>p.slug ==='crime') !== -1)
      temp.sanctionProcessedInfo.sanctionProcessedInfo.resultList[0]['amlSearchContent']['details'] =  pepArr.filter(e=>e.category.findIndex(p=>p.slug ==='sanction') !== -1)
      
      
      temp.crimeProcessedInfo.crimeProcessedInfo.matchFound= temp.crimeProcessedInfo.crimeProcessedInfo.resultList[0]['amlSearchContent']['details'].length ? true : false
      temp.sanctionProcessedInfo.sanctionProcessedInfo.matchFound=temp.sanctionProcessedInfo.sanctionProcessedInfo.resultList[0]['amlSearchContent']['details'].length ? true : false
      
      temp.sanctionProcessedInfo.sanctionProcessedInfo.resultList[0].riskFactor = temp.sanctionProcessedInfo.sanctionProcessedInfo.matchFound ? 'HIGH' : 'LOW'
      res.data.activeChecks.amlProcessedInfo = temp
      console.log(31,res)
    }
    return res
    

  }
  getUserNameDetails() {
    this._data.changeLoaderVisibility(true);
    this.devops.getUserNameDetails(this.userName,this.clientId).subscribe(
      (res) => {
        let result = res
        this._data.changeLoaderVisibility(false);

        // Adding for Comparision Report

        if(result.data.activeChecks){
          this.reportResult.forEach(item => {
            if(item.type == 'docMatchProcessedInfo'){
              item['data'] = result.data.activeChecks.docMatchProcessedInfo
            }
            else if(item.type == 'amlProcessedInfo'){
              item['data'] = result.data.activeChecks;
              if(result.data.activeChecks.amlProcessedInfo &&  result.data.activeChecks.amlProcessedInfo.amlProcessedInfo){
                const amlResponse = result.data.activeChecks.amlProcessedInfo.amlProcessedInfo.endUserAmlChecks;
                this.getAmlCategoryList(amlResponse);
              }
            }
          })
        }

        console.log("26",result);
        // TEMP SOLUTION , remapping PEP data into CRIME,PEP,SANCTION using below function 

        // if(result.data.activeChecks && result.data.activeChecks.amlProcessedInfo && result.data.activeChecks.amlProcessedInfo.pepProcessedInfo){
        //   result = this.reMapAmlData(result);
        // }
        this.basicData.totalChecks = result.data.totalChecks;
        this.basicData.completedChecks = result.data.completedChecks;
        // this.basicData.identityInfo       = res.data.identityInfo ;
        this.basicData.identityInfo = result.data.identityInfo;
        this.basicData.identityInfoType = result.data.identityInfoType;
        this.basicData.startDate = result.data.startDate;
        this.basicData.completionDate = result.data.completionDate;
        this.basicData.appSources = Array.from(new Set(result.data.appSources));
        this.basicData.totalAddressDocs = result.data.totalAddressDocs;
        this.basicData.totalIdentityDocs = result.data.totalIdentityDocs;
        this.basicData.creditSpentTotal = result.data.creditSpentTotal;
        // this.idvWarningMessage = res.data.activeChecks.identityDocProcessedInfo.docProcessedInfo.warningMessage;
        this.idvWarningMessage = (!(result.data.activeChecks.identityDocProcessedInfo) ? null : result.data.activeChecks.identityDocProcessedInfo.docProcessedInfo.warningMessage);
        this.idvWarningMessage2 = (!(result.data.activeChecks.identityDocProcessedInfo2) ? null : result.data.activeChecks.identityDocProcessedInfo2.docProcessedInfo.warningMessage);
        this.addressWarningMessage = (!(result.data.activeChecks.addressDocProcessedInfo) ? null : result.data.activeChecks.addressDocProcessedInfo.docProcessedInfo.warningMessage);
        this.selfieWarningMessage = (!(result.data.activeChecks.selfieProcessedInfo) ? null : result.data.activeChecks.selfieProcessedInfo.selfieProcessedInfo.warningMessage);
        this.livenessWarningMessage = (!(result.data.activeChecks.livenessProcessedInfo) ? null : result.data.activeChecks.livenessProcessedInfo.livenessProcessedInfo.warningMessage);
        // this.addressWarningMessage = res.data.activeChecks.identityDocProcessedInfo.docProcessedInfo.warningMessage;
       // this.selfieWarningMessage = res.data.activeChecks.selfieProcessedInfo.selfieProcessedInfo.warningMessage;
        //this.livenessWarningMessage = res.data.activeChecks.livenessProcessedInfo.livenessProcessedInfo.warningMessage;
      
        const t = result.data.activeChecks.emailPhoneProcessedInfo;
        if (t) {
          let ri = this.reportResult.findIndex(
            (e) => e.type === "emailReport"
          );
          if(ri && ri!=-1){
            this.reportResult[ri].data = t.emailInfo ? t.emailInfo.data : null;
            ri = this.reportResult.findIndex((e) => e.type === "contactInfo");
            this.reportResult[ri].data = t.phoneInfo ? t.phoneInfo.data : null;
            console.log("271", this.reportResult);
          }
         
        }

        this.basicData.activeExpenditure = new CreditExpenditure();
        this.basicData.archiveExpenditure = new CreditExpenditure();
        console.log(this.selfieWarningMessage);
        console.log(this.idvWarningMessage);
        console.log(this.idvWarningMessage2);
        console.log(this.addressDocProcessedInfo);
        console.log(this.livenessWarningMessage);
        this.score = result.data.overallScore;
        this.activateResendButton = result.data.resendRemoteFlag;
        this.activateRedoButton = result.data.redoFlag;
        this.activateNotify = result.data.checkCompletionEmail;
        this.overAllReportStatus = result.data.overallStatus;
        // this.verification.verificationStatus = res.data.overallStatus ;
        // this.verification.verificationStatus = res.data.overallStatus ? res.data.overallStatus : "PENDING";
        setTimeout(() => scoreCircle());

        for (var key in this.basicData.activeExpenditure) {
          this.basicData.activeExpenditure[key] = result.data.activeChecks[key];
        }

        for (var key in this.basicData.archiveExpenditure) {
          this.basicData.archiveExpenditure[key] = result.data.archiveChecks[key];
        }
        this.biometrics = result.data["biometrics"];
        // this.getOverAllStatus(res.data);
        this.mapResponseData(result.data["activeChecks"]);
        
        this.isheroAPILoaded = true;
      },
      (err) => {
        this._data.changeLoaderVisibility(false);
      }
    );
  }

  getAmlCategoryList(response){
    if(response.length){
      for(let res of response){
        if(res.amlVerificationResult){
          const parseData = JSON.parse(res.amlVerificationResult);
          
          if(parseData.data && parseData.data.content && parseData.data.content.length){
            if(parseData.data.content[0].masterData){
              if(parseData.data.content[0].masterData && parseData.data.content[0].masterData.category && parseData.data.content[0].masterData.category.length){
                for(let cat of parseData.data.content[0].masterData.category){
                  this.colors.forEach((col) => {
                    if(col.key == cat.slug){
                      cat['color'] = col.color
                    }
                  })
                  this.categories.push(cat);
                }
              }
              if(parseData.data.content[0].masterData && parseData.data.content[0].masterData.guid && parseData.data.content[0].masterData.guid.length){
                for(let guid of parseData.data.content[0].masterData.guid){
                  if(guid){
                    this.guid = guid;
                  }
                }
              }
            }
          }
        }
      }
    }
    this.getCrrData()
  }

  getOverAllStatus(response) {
    console.log(this.overAllStatus);
    this.overAllStatus = {
      idv: { status: null, risk: null, count: null },
      address: { status: null, risk: null, count: null },
      pep: { status: null, risk: null, count: null },
      sanction: { status: null, risk: null, count: null },
      crime: { status: null, risk: null, count: null },
      media: { status: null, risk: null, count: null },
    };

    this.overAllStatus.idv.status = response.overallStatus;

    if (
      response.activeChecks.addressDocProcessedInfo &&
      response.activeChecks.addressDocProcessedInfo.check
    ) {
      this.overAllStatus.address.status =
        response.activeChecks.addressDocProcessedInfo.check[
          "verificationStatus"
        ];
    }
    if (
      response.activeChecks.adverseMediaProcessedInfo2 &&
      response.activeChecks.adverseMediaProcessedInfo2.check
    ) {
      if (
        response.activeChecks.adverseMediaProcessedInfo2.adverseMediaResults
          .matchFound
      ) {
        this.overAllStatus.media.status =
          response.activeChecks.adverseMediaProcessedInfo2.check[
            "verificationStatus"
          ];
        this.overAllStatus.media.count =
          response.activeChecks.adverseMediaProcessedInfo2.adverseMediaResults.results.totalElements;
      }
    }
    if (
      response.activeChecks.amlProcessedInfo &&
      response.activeChecks.amlProcessedInfo.crimeProcessedInfo &&
      response.activeChecks.amlProcessedInfo.crimeProcessedInfo.check
    ) {
      if (
        response.activeChecks.amlProcessedInfo.crimeProcessedInfo
          .crimeProcessedInfo.matchFound
      ) {
        this.overAllStatus.crime.status =
          response.activeChecks.amlProcessedInfo.crimeProcessedInfo.check[
            "verificationStatus"
          ];
        this.overAllStatus.crime.risk =
          response.activeChecks.amlProcessedInfo.crimeProcessedInfo.crimeProcessedInfo.results.content[0].riskFactor;
        this.overAllStatus.crime.count =
          response.activeChecks.amlProcessedInfo.crimeProcessedInfo.crimeProcessedInfo.results.totalElements;
      }
    }
    if (
      response.activeChecks.amlProcessedInfo &&
      response.activeChecks.amlProcessedInfo.pepProcessedInfo &&
      response.activeChecks.amlProcessedInfo.pepProcessedInfo.check
    ) {
      if (
        response.activeChecks.amlProcessedInfo.pepProcessedInfo.pepProcessedInfo
          .matchFound
      ) {
        this.overAllStatus.pep.status =
          response.activeChecks.amlProcessedInfo.pepProcessedInfo.check[
            "verificationStatus"
          ];
        this.overAllStatus.pep.risk =
          response.activeChecks.amlProcessedInfo.pepProcessedInfo.pepProcessedInfo.results.content[0].riskFactor;
        this.overAllStatus.pep.count =
          response.activeChecks.amlProcessedInfo.pepProcessedInfo.pepProcessedInfo.results.totalElements;
      }
    }
    if (
      response.activeChecks.amlProcessedInfo &&
      response.activeChecks.amlProcessedInfo.sanctionProcessedInfo &&
      response.activeChecks.amlProcessedInfo.sanctionProcessedInfo.check
    ) {
      if (
        response.activeChecks.amlProcessedInfo.sanctionProcessedInfo
          .sanctionProcessedInfo.matchFound
      ) {
        this.overAllStatus.sanction.status =
          response.activeChecks.amlProcessedInfo.sanctionProcessedInfo.check[
            "verificationStatus"
          ];
        this.overAllStatus.sanction.risk =
          response.activeChecks.amlProcessedInfo.sanctionProcessedInfo.sanctionProcessedInfo.results.content[0].riskFactor;
        this.overAllStatus.sanction.count =
          response.activeChecks.amlProcessedInfo.sanctionProcessedInfo.sanctionProcessedInfo.results.totalElements;
      }
    }

    if (this.basicData.completedChecks == this.basicData.totalChecks) {
      this.verification.verificationStatus = "COMPLETE";
      if (
        this.overAllStatus.address.status == "FAILED" ||
        this.overAllStatus.address.status == "FAILED" ||
        this.overAllStatus.pep.count ||
        this.overAllStatus.crime.count ||
        this.overAllStatus.media.count ||
        this.overAllStatus.sanction.count
      ) {
        this.verification.caution = "Required Confirmation";
      }
    } else {
      this.verification.verificationStatus = "PENDING";
    }
    console.log(this.overAllStatus);
  }

  getBasicUserInfo() {
    this.devops.getBasicUserInfo(this.userName,this.clientId).subscribe((res) => {
      this.endUserInfo = res.data;
      let index = this.reportResult.findIndex(
        (obj) => obj.type == "endUserInfo"
      );
      if (index && index!=-1) {
        this.reportResult[index].data = this.endUserInfo;
      }
    });
  }

  getActivityLog() {
    this.activityLog = "Response Comes Here";
    let idIDVData = this.reportResult.filter(
      (obj) => obj.type == "identityDocProcessedInfo" 
    );
    let idIDVData2 = this.reportResult.filter(
      (obj) => obj.type == "identityDocProcessedInfo2" 
    );
    let isIDVPresent2 = idIDVData2?  idIDVData2[0].data : null;
    let isIDVPresent = idIDVData ? idIDVData[0].data : null;
    let idAddressData = this.reportResult.filter(
      (obj) => obj.type ==  "addressDocProcessedInfo"
    );
    console.log(idAddressData)
    let isAddressPresent = idAddressData ? idAddressData[0].data : null;

    let index = this.reportResult.findIndex((obj) => obj.type == "activityLog");
    if (index && index!=-1 && (isIDVPresent || isAddressPresent || isIDVPresent2)) {
      this.reportResult[index].data = this.activityLog;
    }
  }

  getSubscriptionExpense() {
    // this._app.getSubscriptionExpense()
    // .subscribe(
    // 	(res)=>{
    // 		this.expenditure = res.data ;
    // 		let index = this.reportResult.findIndex(obj=>obj.type == "expenditure");
    // 		if(index){
    // 			this.reportResult[index].data = this.expenditure;
    // 		}
    // 	}
    // );
  }

  mapDataForAMLDetails(amlProcessedInfo) {
    // this.amlCheckData = {
    // 	"crimeCheck"   : null,
    // 	"pepCheck"     : null,
    // 	"sanctionCheck": null
    // };

    // this.amlCheckData["crimeCheck"]    = amlProcessedInfo["crimeProcessedInfo"] ?  amlProcessedInfo["crimeProcessedInfo"].amlProcessedInfo : amlProcessedInfo["crimeProcessedInfo"];
    // this.amlCheckData["pepCheck"]      = amlProcessedInfo["pepProcessedInfo"] ? amlProcessedInfo["pepProcessedInfo"].amlProcessedInfo : amlProcessedInfo["pepProcessedInfo"] ;
    // this.amlCheckData["sanctionCheck"] = amlProcessedInfo["sanctionProcessedInfo"] ? amlProcessedInfo["sanctionProcessedInfo"].amlProcessedInfo : amlProcessedInfo["sanctionProcessedInfo"] ;
    this.amlCheckData = {
      crime: null,
      pep: null,
      sanction: null,
    };

    this.amlCheckLinks = [];

    this.amlCheckData["crime"] = amlProcessedInfo["crimeProcessedInfo"]
      ? amlProcessedInfo["crimeProcessedInfo"].crimeProcessedInfo
      : amlProcessedInfo["crimeProcessedInfo"];
    this.amlCheckData["pep"] = amlProcessedInfo["pepProcessedInfo"]
      ? amlProcessedInfo["pepProcessedInfo"].pepProcessedInfo
      : amlProcessedInfo["pepProcessedInfo"];
    this.amlCheckData["sanction"] = amlProcessedInfo["sanctionProcessedInfo"]
      ? amlProcessedInfo["sanctionProcessedInfo"].sanctionProcessedInfo
      : amlProcessedInfo["sanctionProcessedInfo"];

    if (amlProcessedInfo["crimeProcessedInfo"]) {
      this.amlCheckLinks.push(amlProcessedInfo["crimeProcessedInfo"].check);
    }
    if (amlProcessedInfo["pepProcessedInfo"]) {
      this.amlCheckLinks.push(amlProcessedInfo["pepProcessedInfo"].check);
    }
    if (amlProcessedInfo["sanctionProcessedInfo"]) {
      this.amlCheckLinks.push(amlProcessedInfo["sanctionProcessedInfo"].check);
    }

    this.amlComment = amlProcessedInfo["amlComment"];
  }

  mapResponseData(activeChecks) {
    this.addressDocProcessedInfo = activeChecks.addressDocProcessedInfo;
    this.identityDocProcessedInfo = activeChecks.identityDocProcessedInfo;
    this.identityDocProcessedInfo2 = activeChecks.identityDocProcessedInfo2;
    this.amlProcessedInfo = activeChecks.amlProcessedInfo;
    this.livenessProcessedInfo = activeChecks.livenessProcessedInfo;
    this.adverseMediaProcessedInfo2 = activeChecks.adverseMediaProcessedInfo2;
    // console.log(31,this.amlProcessedInfo.pepProcessedInfo.pepProcessedInfo.resultList[0].amlSearchContent,this.amlProcessedInfo.crimeProcessedInfo.crimeProcessedInfo.resultList[0].amlSearchContent,this.amlProcessedInfo.sanctionProcessedInfo.sanctionProcessedInfo.resultList[0].amlSearchContent)
    if(this.amlProcessedInfo.pepProcessedInfo && this.amlProcessedInfo.pepProcessedInfo.pepProcessedInfo.resultList && this.amlProcessedInfo.pepProcessedInfo.pepProcessedInfo.resultList.length > 0 && this.amlProcessedInfo.pepProcessedInfo.pepProcessedInfo.resultList[0].amlSearchContent !==null){
      // this.guid=this.amlProcessedInfo.pepProcessedInfo.pepProcessedInfo.resultList[0].amlSearchContent.guid
      // this.getCrrData()
    }
    else if(this.amlProcessedInfo.crimeProcessedInfo && this.amlProcessedInfo.crimeProcessedInfo.crimeProcessedInfo.resultList && this.amlProcessedInfo.crimeProcessedInfo.crimeProcessedInfo.resultList.length > 0 && this.amlProcessedInfo.crimeProcessedInfo.crimeProcessedInfo.resultList[0].amlSearchContent!==null){
      // this.guid=this.amlProcessedInfo.crimeProcessedInfo.crimeProcessedInfo.resultList[0].amlSearchContent.guid
      // this.getCrrData()
    }
    else if(this.amlProcessedInfo.sanctionProcessedInfo && this.amlProcessedInfo.sanctionProcessedInfo.sanctionProcessedInfo.resultList && this.amlProcessedInfo.sanctionProcessedInfo.sanctionProcessedInfo.resultList.length > 0 && this.amlProcessedInfo.sanctionProcessedInfo.sanctionProcessedInfo.resultList[0].amlSearchContent !==null){
      // this.guid=this.amlProcessedInfo.sanctionProcessedInfo.sanctionProcessedInfo.resultList[0].amlSearchContent.guid
      // this.getCrrData()
    }


    // if(this.amlProcessedInfo.pepProcessedInfo.pepProcessedInfo.resultList[0].amlSearchContent !==null){
    //   this.guid=this.amlProcessedInfo.pepProcessedInfo.pepProcessedInfo.resultList[0].amlSearchContent.guid
    //   this.getCrrData()
    // }
    // else if(this.amlProcessedInfo.crimeProcessedInfo.crimeProcessedInfo.resultList[0].amlSearchContent!==null){
    //   this.guid=this.amlProcessedInfo.crimeProcessedInfo.crimeProcessedInfo.resultList[0].amlSearchContent.guid
    //   this.getCrrData()
    // }
    // else if(this.amlProcessedInfo.sanctionProcessedInfo.sanctionProcessedInfo.resultList[0].amlSearchContent !==null){
    //   this.guid=this.amlProcessedInfo.sanctionProcessedInfo.sanctionProcessedInfo.resultList[0].amlSearchContent.guid
    //   this.getCrrData()
    // }


    if (this.adverseMediaProcessedInfo2) {
      this.adverseMediaProcessedInfo2 = this.adverseMediaProcessedInfo2.adverseMediaResults;
    }

    this.mapDataForAMLDetails(this.amlProcessedInfo);
    this.getSubscriptionExpense();

    if (activeChecks.selfieProcessedInfo) {
      this.getBiometricSelfie(
        activeChecks.selfieProcessedInfo.check.endUserCheckID
      );
    }

    if (activeChecks.identityDocProcessedInfo) {
      this.verificationID["verificationResult"] =
        activeChecks.identityDocProcessedInfo.docProcessedInfo[
          "verificationResult"
        ];
      this.verificationID["verificationScore"] =
        activeChecks.identityDocProcessedInfo.docProcessedInfo[
          "verificationScore"
        ];
      this.verificationID["verificationStatus"] =
        activeChecks.identityDocProcessedInfo.docProcessedInfo[
          "verificationStatus"
        ];
    }

    if (activeChecks.identityDocProcessedInfo2) {
      this.verificationID2["verificationResult"] =
        activeChecks.identityDocProcessedInfo2.docProcessedInfo[
          "verificationResult"
        ];
      this.verificationID2["verificationScore"] =
        activeChecks.identityDocProcessedInfo2.docProcessedInfo[
          "verificationScore"
        ];
      this.verificationID2["verificationStatus"] =
        activeChecks.identityDocProcessedInfo2.docProcessedInfo[
          "verificationStatus"
        ];
    }

    if (activeChecks.addressDocProcessedInfo) {
      this.verificationAddress["verificationResult"] =
        activeChecks.addressDocProcessedInfo.docProcessedInfo[
          "verificationResult"
        ];
      this.verificationAddress["verificationScore"] =
        activeChecks.addressDocProcessedInfo.docProcessedInfo[
          "verificationScore"
        ];
      this.verificationAddress["verificationStatus"] =
        activeChecks.addressDocProcessedInfo.docProcessedInfo[
          "verificationStatus"
        ];
    }

    for (var key in activeChecks) {
      let index = this.reportResult.findIndex((obj) => {
        if(obj.type !="amlProcessedInfo"){
          return obj.type == key
        }
      });
      if (index && activeChecks[key] && this.reportResult[index]) {
        this.reportResult[index].data = activeChecks[key];
      }
    }

    var index = this.reportResult.findIndex((obj) => obj.type == "expenditure");
    if (index &&  index!=-1 && this.reportResult[index] && activeChecks[key]) {
      this.reportResult[index].data = this.basicData;
    }

   // this.getActivityLog();
    console.log(this.reportResult);
    console.log("amlProcessedInfo");
    console.log(this.amlProcessedInfo);
    console.log("adverseMediaProcessedInfo2");
    console.log(this.adverseMediaProcessedInfo2);
  }

  toggleAccordion(index, report) {
    console.log("reoports", report);
    if (this.isCardDisabled(report)) {
      return;
    }
    console.log(this.isOpen);
    this.isOpen[index] = !this.isOpen[index] ? true : false;
  }

  isCardDisabled(report) {
    if (report.type == "amlProcessedInfo") {
      // if (
      //   !this.adverseMediaProcessedInfo2 &&
      //   !this.amlProcessedInfo.crimeProcessedInfo &&
      //   !this.amlProcessedInfo.pepProcessedInfo &&
      //   !this.amlProcessedInfo.sanctionProcessedInfo
      // ) {
      //   return true;
      // }
      if(report.data && (report.data.adverseMediaProcessedInfo2 || (report.data.amlProcessedInfo && report.data.amlProcessedInfo.amlProcessedInfo))){
        return false;
      }
      else{
        return true;
      }
    }
    else{
      if (report && report.data) {
        return false;
      }
      return true;
    }
  }

  getCompletionPercent() {
    let percent =
      (parseInt(this.basicData.completedChecks) /
        parseInt(this.basicData.totalChecks)) *
      100;
    if (isNaN(percent) || !percent) {
      return 0;
    } else {
      return percent.toFixed(0);
    }
  }

  openOverrideModal() {
    let validGroup = this.reportResult.filter((obj) => {
      if (obj.data && obj.data["check"]) {
        if (obj.data.check.verificationStatus == "FAILED") {
          return obj;
        }
      }
    });
    if (validGroup.length) {
      const initialState = {
        title: `Override Checks`,
        type: "override-checks",
        overriddenChecks: validGroup,
      };
      this.bsModalRef = this.modalService.show(KycModalComponent, {
        initialState,
        class: "modal-lg",
      });
      this.bsModalRef.content.closeBtnName = "Close";
      this.bsModalRef.content.onClose.subscribe((result) => {
        console.log("results", result);
        if (Object.keys(result).length) {
          this.overrideChecks(result);
          // location.reload()
        }
        

      });
    }
  }

  canStatusBeOverridden() {
    let validGroup = this.reportResult.filter((obj) => {
      if (obj.data && obj.data["check"]) {
        if (obj.data.check.verificationStatus == "FAILED") {
          return obj;
        }
      }
    });

    if (validGroup.length) {
      return true;
    } else {
      return false;
    }
  }

  overrideChecks(overrideArr) {
    console.log("YEAH");
    this._track.activityLog();
    let stream$ = [];
    for (var i = 0; i < overrideArr.length; i++) {
      let override$ = this._app.overrideChecks(overrideArr[i]);
      stream$.push(override$);
    }
    forkJoin(stream$).subscribe((res) => {
      console.log("res[0]");
      this._data.toastrShow(
        "User status has been update successfully.",
        "info"
      );
      console.log(res[0]);
      for (var i = 0; i < overrideArr.length; i++) {
        this.reportResult.map((obj) => {
          if (obj.data && obj.data["check"]) {
            if (
              obj.data.check.endUserCheckID == overrideArr[i].endUserCheckID
            ) {
              obj.data.check.verificationStatus = "SUCCESS";
            }
          }
          return obj;
        });
      }
    });
  }

  openReCheckModal() {
    console.log(this.basicData);
    let data = {
      value: '',
      userName: this.userName,
    };
    const initialState = {
      title: `Schdule Check`,
      type: "schedule-check",
      userType: "INDIVIDUAL",
      endUserInfo: data,
    };
    this.bsModalRef = this.modalService.show(KycModalComponent, {
      initialState,
      class: "modal-md scheduleModal",
    });
    this.bsModalRef.content.closeBtnName = "Close";
    console.log(this.bsModalRef.content.onClose);
    this.bsModalRef.content.onClose.subscribe((result) => {
      console.log("results", result);
      // if (result && Object.keys(result).length) {
      //   if (result.isCheckScheduled) {
      //     this.hasScheduledAlert = true;
      //   }
      // }
      if(Object.keys(result).length){
        this._data.changeLoaderVisibility(true);
        const payload = {
          "scheduledIntervalToCheck": result.value,
          "entityType": 'Individual',
          "endUserName": this.userName
        }
        this._app.setAmlAlerts(payload).pipe(finalize(() => this._data.changeLoaderVisibility(false))).subscribe(() => {
          this.getAlertsData();
        })
      }
      else{
        this.hasScheduledAlert = false;
      }
    });
  }
  // notifyOnCompletion(userName){
  // 	this._app.notifyOnCompletion(this.userName)
  // 	.subscribe(
  // 		(res)=>{
  // 			this._data.toastrShow("Notification is enabled. An email will be sent to the user on completion.",'success')
  // 		}
  // 	);
  // }

  downloadUserDocs() {
    this._data.changeLoaderVisibility(true);
    this._app.downloadUserDocs(this.userName).subscribe((res) => {
      const blob = new Blob([res], { type: "application/zip" });
      var file = new File([blob], "Documents.zip", { type: "application/zip" });
      FileSaver.saveAs(blob, `${this.userName}-documents.zip`);
      this._data.changeLoaderVisibility(false);
      console.log(res);
    });
  }
  notifyOnCompletion() {
    this._app.notifyOnCompletion(this.userName).subscribe((res) => {
      this._data.toastrShow(
        "Notification is enabled. An email will be sent to the user on completion.",
        "success"
      );
      this.getUserNameDetails();
        this.getUsersScheduledAlerts();
        this.getBasicUserInfo();
    });
  }

  downloadDetailedReport() {
    this._data.changeLoaderVisibility(true);
    this._app.downloadDetailedReport(this.userName).subscribe((res) => {
      const blob = new Blob([res], { type: "application/pdf" });
      var file = new File([blob], "Report.pdf", { type: "application/pdf" });
      FileSaver.saveAs(blob, `${this.userName}-Report.pdf`);
      this._data.changeLoaderVisibility(false);
      console.log(res);
    });
  }

  getRedoCheckDetails() {
    this.devops.getRedoCheckDetails(this.userName,this.clientId).subscribe((res) => {
	  this.redoPopupMessage = res.data.message;
	  console.log(this.redoPopupMessage )
      
    });
  }
  backToOveride(){
    localStorage.removeItem('lastVisited');
    this.close.emit("userList")

  }

  ngOnInit() {
    // this.verification.verificationStatus = "FAILED";
    // console.log(this.userName);
	// setTimeout(()=>scoreCircle());
  if (this.appuserName!='' && this.appuserName!=undefined) {
    this.userName = this.appuserName;
    this.getUserNameDetails();
    this.getUsersScheduledAlerts();
    this.getBasicUserInfo();
  }
  this.getRedoCheckDetails();
   this.last = localStorage.getItem('lastVisited')
   console.log(this.last);
   this.getAlertsData()
   
  }

  getAlertsData(){
    var payload;
    this._app.getAmlBookMarked(payload, this.userName).subscribe((res) => {
      this.hasScheduledAlert = res['data'];
    })
  }

  getCrrData(){
    let payload={
      guid:this.guid
    }
    this._app.getAdvMediaResults(payload).subscribe(res=>{
      console.log("thisis ",res)
      this.crrResponse=res.data.content[0].riskProfile
      this.crrResponse.score = Number(this.crrResponse.score).toFixed(2)
      this.crrResponse.crrStatus  = this.crrResponse.score > 50 ? "HIGH RISK": this.crrResponse.score > 29 ? 'MEDIUM RISK' : 'LOW RISK'
      this.crrResponse.crrColor = this.crrResponse.score > 50 ? '#ff3547' : this.crrResponse.score > 29 ? '#f9c851' : '#10c469'
      this.crrResponse.chartData =  [{name:'null',value: Number(this.crrResponse.score)}]
      
    })
  }

  refreshDetails(event){
    if(event=='refresh'){
      this.getUserNameDetails();
      this.getUsersScheduledAlerts();
      this.getBasicUserInfo();
    }
  }
}
