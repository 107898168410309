
import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute, Params, NavigationEnd } from "@angular/router";
import { DataService } from "../../../services/data.service";
import { AppService } from "../../../services/app.service";
import { AmlSearchService } from ".././aml-search.service";
import { first } from "rxjs/operators";
// import { AMLSearch  , AMLResultData} from '../../utils/aml-search' ;

import { BsModalRef } from "ngx-bootstrap/modal/bs-modal-ref.service";
import { BsModalService } from "ngx-bootstrap/modal";
import { KycModalComponent } from "../../../shared/kyc-modal/kyc-modal.component";
import { interval } from 'rxjs';
import { timeout } from 'rxjs/operators';
import { ShowTypes } from "@swimlane/ngx-charts";

// import * as StickySidebar from "sticky-sidebar";

// Create an Observable that will publish a value on an interval
const secondsCounter = interval(1000);
@Component({
  selector: 'app-profile-search',
  templateUrl: './profile-search.component.html',
  styleUrls: ['./profile-search.component.scss']
})
export class ProfileSearchComponent implements OnInit {
  searchField: string;
  searchQueryType = 'Individual';
  //Temporary Test Vars
  showTabularResult: boolean = false;
  showDetailedResult: boolean = false;
  amlResultsData: any[] = [];
  amlFilters: any[] = [];
  savedRiskArr: any[] = ["ALL", "SANCTIONS", "PEP", "CRIME"];
  selectedRiskPolicy: string = "ALL";

  selectedAMLEnitity: any;

  //Pagination
  jump: any;
  currentPage: number = 0;
  itemsPerPage: number = 10;
  totalItems: number;
  maxSize: number;
  searchType: "MOREFUZZY" | "STRICT" |"FUZZY" ="FUZZY"  ;
  possibleSearchTypes: any[] = ["FUZZY", "MOREFUZZY", "STRICT"];
  buildQuery: any = {
    size: this.itemsPerPage,
    page: this.currentPage,
    searchType: this.searchType,
    name: [],
    country: [],
    source: [],
    category: [],
    gender: [],
    type: [],
    riskpolicy: [],
  };
  pageList: any = []
  startIndex: number = 0;
  endIndex: number = 10;

  //routing
  currentRoute: string;

  //API Loading
  isAPILoading: boolean = false;

  bsModalRef: BsModalRef;

  

  countries: any;
  selectedCountry: any = {
    countryCode: "",
    countryName: "Select Country",
    countrySynonyms: "",
    isActive: "ACTIVE",
    jurisdictions: null,
  };
  guidEntered: string;

  // same route navigation vars
  navigationSubscription: any;

  constructor(
    private _data: DataService,
    private modalService: BsModalService,
    private _app: AppService,
    private _aml: AmlSearchService,
    private route: ActivatedRoute,
    public router: Router
  ) {
    console.log("1.constructor");
    var currentRoute = this.router.url;
    this.currentRoute =
      currentRoute.indexOf("?") !== -1
        ? currentRoute.substring(0, currentRoute.indexOf("?"))
        : currentRoute;
    console.log('constructor : ',this.currentRoute);

    // on reload successfull completion
    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      // If it is a NavigationEnd event re-initalise the component
      if (e instanceof NavigationEnd) {
        console.log("Recalled Component");
        // Subscribe to begin publishing values
        secondsCounter.pipe(timeout(500))
                      .subscribe(
                          value => { console.log(value)}, // Will never be called.
                          err => { this.clearFilters(); },     // Will emit error before even first value is emitted,
                                                       // since it did not arrive within 900ms period.
                      );
        
      }
    });
  }

  ngOnInit() {
    let index = JSON.parse(localStorage.getItem('key'))
    if (index == null) {
        this.getPagedData(this.currentPage)
        this.jump=this.currentPage+1
    }
    else {
        this.getPagedData(index)
        this.jump=index+1
    }
    this.hideLeftPanel();
    console.log("3.ngOnInit");
    this.getAllCountries();
    this.getRiskPolicy();
    // console.log(this.amlSearch);
  }

  hideLeftPanel() {
    var leftElem = document.getElementById("wrapper");
    leftElem.classList.add("forced");
    leftElem.classList.add("enlarged");
  }

  ///Test Hooks Starts
  traverse() {
    this.router.navigate([`/aml-search`], {
      queryParams: { country: `India` },
    });
  }

  // ngOnChanges(){
  //   console.log("2.ngOnChanges");
  // }

  // ngDoCheck(){
  //   console.log(this.getQueryParams());
  //   console.log("4.ngDoCheck");
  // }

  // ngAfterContentInit(){
  //   console.log("5.ngAfterContentInit");
  // }

  // ngAfterContentChecked(){
  //   console.log("6.ngAfterContentChecked");
  // }

  // ngAfterViewInit(){
  //   console.log("7.ngAfterViewInit");
  // }

  // ngAfterViewChecked(){
  //   console.log("8.ngAfterViewChecked");
  // }

  // ngOnDestroy(){
  //   console.log("9.ngOnDestroy");
  // }
  ///Test Hooks Ends

  getBuildQueryData(params) {
    this.buildQuery = {
      size: this.itemsPerPage,
      page: this.currentPage,
      searchType: this.searchType,
      name: [],
      country: [],
      source: [],
      category: [],
      gender: [],
      type: [],
      riskpolicy: [],
    };
    this.buildQuery["name"] = [];
    this.buildQuery["name"].push(this.searchField);
    for (var key in this.buildQuery) {
      if (key == "page" || key == "size") {
        this.buildQuery[key] = parseInt(params[key]);
      } else if (key == "searchType") {
        this.buildQuery[key] = params[key];
      } else {
        if (params[key] && params[key].indexOf(",") !== -1) {
          this.buildQuery[key] = params[key].split(",");
        } else {
          if (this.buildQuery[key].indexOf(params[key]) === -1) {
            if (params[key]) {
              this.buildQuery[key].push(params[key]);
            }
          }
        }
      }
    }
    console.log(this.buildQuery);
  }

  clearFilters() {
    console.log(this.searchField);
    this.jump=1;
    this.itemsPerPage = 10;
    this.currentPage = 0;
    this.buildQuery = {
      size: this.itemsPerPage,
      page: this.currentPage,
      searchType: this.possibleSearchTypes[0],
      name: [],
      country: [],
      source: [],
      category: [],
      gender: [],
      type: [],
    };
    this.searchType=this.possibleSearchTypes[0];
    this.searchField = "";
    this.guidEntered = "";
    // this.buildQuery["name"] = [];
    // this.buildQuery["name"].push(this.searchField);
    this.showTabularResult = false;
    this.showDetailedResult = false;
    this.amlResultsData = [];
    this.selectedRiskPolicy = "ALL";
    this.amlFilters = [];
    this.selectedCountry = this.countries[0];
    this.searchQueryType = 'Individual';
      
    // this.getSearchFilters();
    // this.getSearchResults();
  }
  clearAll(){
    this.searchField = "";
    this.searchQueryType = 'Individual';
    this.buildQuery = {
      size: this.itemsPerPage,
      page: this.currentPage,
      searchType: this.possibleSearchTypes[0],
      name: [],
      country: [],
      source: [],
      category: [],
      gender: [],
      type: [],
    };
    this.searchType=this.possibleSearchTypes[0];
  }
  clearFiltersInner() {
    this.itemsPerPage = 10;
    this.currentPage = 0;
    this.searchType = 'FUZZY'
    this.searchQueryType = 'Individual';
    this.searchField = "";
    this.guidEntered = "";
    this.buildQuery = {
      size: this.itemsPerPage,
      page: this.currentPage,
      searchType: this.possibleSearchTypes[0],
      name: [],
      country: [],
      source: [],
      category: [],
      gender: [],
      type: [],
    };
    this.searchType=this.possibleSearchTypes[0];
    this.showTabularResult = true;
    this.showDetailedResult = false;
    this.amlResultsData = [];
    this.selectedRiskPolicy = "ALL";
    this.amlFilters = [];
    this.selectedCountry = this.countries[0];
    
    //this.getSearchFilters();
    //this.getSearchResults();
  }

  doSearch(searchTerm) {
    // setTimeout(()=>{this._data.changeLoaderVisibility(false) , this.showTabularResult = true},1000)
    // console.log(searchTerm);
    // this._data.changeLoaderVisibility(true);
    let countryList = this.buildQuery.country;
    this.currentPage = 0;
    this.jump = 1;
    this.buildQuery = {
      size: this.itemsPerPage,
      page: this.currentPage,
      searchType: this.searchType,
      name: ""//[],
      // country: countryList,
      // source: [],
      // guid : this.guidEntered || "",
      // category: [],
      // gender: [],
      // type: [this.searchQueryType],
    };
    console.log("buildquery: ",this.buildQuery);
    // this.buildQuery["name"] = [];
    // this.buildQuery["name"].push(searchTerm);
    this.buildQuery["name"]="";
    this.buildQuery["name"]=searchTerm;

    this.getSearchResults();
    // this.getSearchFilters();
    // this.getSearchResults();
    // this._data.changeLoaderVisibility(false)
  }

  getQueryParams(currentPage?) {
    var query = {};
    for (var key in this.buildQuery) {
      if (key == "searchType" || key == "page" || key == "size") {
        query[key] = this.buildQuery[key];
      } else if (
        key != "searchType" &&
        key != "page" &&
        key != "size" &&
        this.buildQuery[key] &&
        this.buildQuery[key].length
      ) {
        query[key] = this.buildQuery[key].toString();
      }
    }
    // query['page'] = currentPage;
    return query;
  }

  doFilter(filterName, filterValue) {
    console.log(filterName, filterValue);
    this.currentPage = 0;
    this.buildQuery["page"] = 0;
    if (filterName == "searchType") {
      this.buildQuery[filterName] = filterValue;
    } else {
      var indexValue = this.buildQuery[filterName].indexOf(filterValue);
      if (indexValue === -1) {
        this.buildQuery[filterName].push(filterValue);
      } else {
        this.buildQuery[filterName].splice(indexValue, 1);
      }
    }
    this.getSearchResults();
    console.log(this.buildQuery);
    // this.navigationBoy();
    //this.getSearchResults();
  }
  
  getRelationSearch(relationName){
    console.log(relationName);
    this.searchField = relationName;
    this.buildQuery["name"] = [];
    this.buildQuery["name"].push(relationName);
    this.getSearchResults();
  }

  getSearchResults() {
    // this.navigationBoy();
    // this.getSearchFilters(); //Dynamic FIlters
    this._data.changeLoaderVisibility(true);
    this.isAPILoading = true;
    this.toggleBodyScrolling(false);
    // this._aml.doSearch(this.buildQuery).subscribe(
    //   res => {
    //     let amlResultsData = res.data.content;
    //     this.amlResultsData = [];
    //     for (var i = 0; i < amlResultsData.length; i++) {
    //       this.amlResultsData.push(amlResultsData[i]);
    //     }
    //     // if (this.currentPage === 0) {
    //     this.totalItems = res.data.totalElements;
    //     this.maxSize = res.data.totalPages;
    //     this.pageSize();
    //     // }
    //     if (this.amlResultsData.length > 0) {
    //       this.showTabularResult = true;
    //       this.showDetailedResult = false;
    //     } else
    //       this._data.toastrShow(
    //         `No record found for ${this.searchQueryType}.Please check with other name.`,
    //         "warning"
    //       );

    //     if(!res.data || !res.data.content){
    //       this._data.changeLoaderVisibility(false);
    //     }else{
    //       this.getSearchFilters();
    //     }
    //     this.isAPILoading = false;
    //     this.toggleBodyScrolling(true);
    //     // this.sticky();
    //   },
    //   err => {
    //     this.isAPILoading = false;
    //     this.toggleBodyScrolling(true);
    //   }
    // );
    this._aml.getFullProfileSearch(this.buildQuery).subscribe(result=>{
      if(result.data!=null){
        this.amlResultsData = result.data.content;
        if (this.currentPage === 0) {
          this.totalItems = result.data.totalElements;
          this.maxSize = result.data.totalPages;
          this.pageSize();
          }
        if (this.amlResultsData.length > 0) {
          this.showTabularResult = true;
          this.showDetailedResult = false;
        } else{
          this._data.toastrShow(
            `No record found for ${this.searchQueryType}.Please check with other name.`,
            "warning"
          );
        }
      }
      else{
        this._data.toastrShow(
          `No record found for ${this.searchQueryType}.Please check with other name.`,
          "warning"
        );
      }
    
      console.log("search result: ",this.amlResultsData);
      setTimeout(()=>{
        this._data.changeLoaderVisibility(false);
        this.toggleBodyScrolling(true);
        this.isAPILoading = false;
      });
    });
  }

  toggleBodyScrolling(allowScrolling) {
    if (!allowScrolling) {
      document.getElementsByTagName("body")[0].classList.add("noScroll");
    } else {
      document.getElementsByTagName("body")[0].classList.remove("noScroll");
    }
  }

  getSearchFilters() {
    this._aml.getSearchFilters(this.buildQuery).subscribe(res => {
      console.log(res);
      this.amlFilters = res.data;
      this._data.changeLoaderVisibility(false);
    });
  }

  getFormattedString(search, type) {
    return search;
    // .replace(/-/g, " ")
    // .replace(/_/g, " ")
    // .trim();
  }

  getMoreSearchResults() {
    console.log("getMoreUsersData");
    this.currentPage = this.currentPage + 1;
    this.buildQuery["page"] = this.buildQuery["page"] + 1;
    if (this.currentPage < this.maxSize) {
      this.getSearchResults();
      this.jump=this.currentPage+1;;
      // this.navigationBoy();
    }
  }

  getPaginatedData(isNext) {
    console.log("getMoreUsersData");
    this.currentPage = isNext ? this.currentPage + 1 : this.currentPage - 1;
    this.buildQuery["page"] = isNext
      ? this.buildQuery["page"] + 1
      : this.buildQuery["page"] - 1;
    if (this.currentPage < this.maxSize) {
      this.getSearchResults();
      this.pageSize();
      this.jump=this.currentPage+1;
      // this.navigationBoy();
    }
  }
  jumpTo() {
    // let index=parseInt(this.jump)
    if (this.jump <= this.maxSize) {
    if(Math.sign(this.jump) != -1 && Math.sign(this.jump) != 0)
    this.getPagedData(this.jump - 1)
    }
}

  getPaginatedText() {
    let a = this.currentPage * this.itemsPerPage + 1;
    let b;
    if (this.currentPage < this.maxSize - 1) {
      b = (this.currentPage + 1) * this.itemsPerPage;
    } else if (this.currentPage == this.maxSize - 1) {
      b = this.totalItems;
    }
    return a + "-" + b;
  }

  getPagedData(index) {
    console.log("getMoreUsersData");
    this.currentPage = index
    this.buildQuery["page"] = index;
    if (this.currentPage < this.maxSize) {
      this.getSearchResults();
        this.pageSize();
        this.jump=this.currentPage+1;
        
    }
  
  }
  getPage(length) {
    console.log("jfhdjhffhh", length)
    // const l=length/10;
    return (Math.round(length / 10));
  }
  pageSize() {
    this.pageList = []
    let totalPages = this.getPage(this.totalItems)
    this.startIndex = (this.currentPage - 4) < 0 ? 0 : this.currentPage - 4
    this.endIndex = (this.currentPage + 4) > this.getPage(this.totalItems) ? totalPages : this.currentPage + 4
    console.log("jgfgjghjghg", this.startIndex, this.endIndex)
    for (let i = 0; i <= this.currentPage + 4; i++) {
        if (i >= this.currentPage - 4 && i < totalPages) { this.pageList.push(i) }
    }
  
  }

  

  openAdvancedSearchModal() {
    const initialState = {
      title: `Advanced AML Search`,
      type: "advanced-aml-search",
    };
    this.bsModalRef = this.modalService.show(KycModalComponent, {
      initialState,
      animated: true,
      keyboard: false,
      backdrop: true,
      ignoreBackdropClick: true,
      class: "modal-lg",
    });
    this.bsModalRef.content.closeBtnName = "Close";
    this.bsModalRef.content.onClose.subscribe(result => {
      if (result && Object.keys(result).length) {
        console.log(result);
      }
    });
  }

  getCategoryName(aml) {
    if (aml && aml.category && aml.category.name) {
      return aml.category.name == "PEP"
        ? aml.category.name
        : aml.category.name.toLowerCase();
    } else {
      return "-";
    }
  }

  addNewRiskPolicy() {
    this._app.getAllDataSources().subscribe(
      res => {
        var i = 0;
        var data = res.data.map(e => {
          e.id = i;
          i++;
          return e;
        });
        this.openNewPolicyModal(res.data);
        console.log(res);
      },
      err => {
        console.log("Add risk policy", err);
      }
    );
  }

  openNewPolicyModal(data) {
    const initialState = {
      title: `Add New Risk Policy`,
      type: "add-new-risk-policy",
      dropdownList: data,
      selectedItems: [],
    };
    this.bsModalRef = this.modalService.show(KycModalComponent, {
      initialState,
      animated: true,
      keyboard: false,
      backdrop: true,
      ignoreBackdropClick: true,
      class: "modal-lg",
    });
    this.bsModalRef.content.closeBtnName = "Close";
    this.bsModalRef.content.onClose.subscribe(result => {
      if (result && Object.keys(result).length) {
        var addPolicy = { name: result[0].riskName, source: [] };
        result[0].selectedItems.forEach(e => {
          addPolicy.source.push(e.key);
        });

        this._app.addNewRiskPolicy(addPolicy).subscribe(
          res => {
            console.log("addNewRiskPolicy response : ", res);
            this.getRiskPolicy();
          },
          err => {
            console.log("addNewRiskPolicy error : ", err);
          }
        );
        console.log(addPolicy);
      }
    });
  }

  getAllCountries() {
    this._app
      .getAllCountries()
      .pipe(first())
      .subscribe(
        res => {
          this.countries = res["data"];
          this.countries.unshift(this.selectedCountry);
          console.log(this.countries);
        },
        err => {
          console.log(err);
        }
      );
  }

  onCountrySelect(country) {
    // console.log(country, this.selectedCountry);
    // this.selectedCountry = country.countryName;

    if (
      this.buildQuery.country &&
      this.buildQuery.country.indexOf(country.countryName) >= 0
    ) {
    } else {
      this.buildQuery.country.push(country.countryName);
    }
    console.log(this.buildQuery);
  }

  onCountrySelectHomepage(country) {
    // console.log(country, this.selectedCountry);
    // this.selectedCountry = country.countryName;

    // if (
    //   this.buildQuery.country &&
    //   this.buildQuery.country.indexOf(country.countryName) >= 0
    // ) {
    // } else {
      this.buildQuery.country = [];
      this.buildQuery.country.push(country.countryName);
    // }
    console.log(this.buildQuery);
  }
  //get saved riskpolicy
  getRiskPolicy() {
    this._aml.getSavedRiskPolicies().subscribe(
      res => {
        console.log("RISKPOLICY", res.data);
        res.data.forEach(element => {
          this.savedRiskArr.push(element.riskPolicyName);
        });
        // this.savedRiskArr.push(res.data.riskPolicyName);
      },
      err => {
        console.log(err);
      }
    );
  }

  //set selected riskpolicy
  setRiskPolicy(item) {
    this.selectedRiskPolicy = item;

    if (this.buildQuery.riskpolicy && this.buildQuery.riskpolicy.length > 0) {
      this.buildQuery.riskpolicy.pop();
      this.buildQuery.riskpolicy.push(this.selectedRiskPolicy);
    } else {
      //   console.log(this.buildQuery);
      this.buildQuery["riskpolicy"] = [this.selectedRiskPolicy];
    }
    console.log(this.buildQuery);
  }

  //set search type
  setSearchType(type) {
    this.buildQuery.searchType = type;
    this.searchType = type;
  }
  goBack(){
    if(this.showDetailedResult){
      this.selectedAMLEnitity = null;
      this.showDetailedResult = false;
      this.showTabularResult = true;
    }else{
      this.showTabularResult = false;
      this.showDetailedResult = false;
    }
  }

  gotoTable(){
    console.log("search term: ",this.searchField);
    this._data.changeLoaderVisibility(true);
    this.isAPILoading = true;
    this.showDetailedResult=!this.showDetailedResult;
    this.showTabularResult=true;
    // this.selectedAMLEnitity = null;
    // this.searchField="";
    // this.doSearch(this.searchField);
    this._data.changeLoaderVisibility(false);
    this.isAPILoading = false;
  }
}
