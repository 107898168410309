export const contentTypes = {
    params: 'params',
    json: 'JSON',
    formData: 'form-data',
 }
 
 export const inputTypes = {
    FILE: 'file',
    TEXT: 'text',
    DATE: 'date',
    SWITCH: 'switch',
    CHECKBOX: 'checkbox',
 }
 
 // inputs types found -- file,text,date,switch(boolean value)
 const data = [
    {
        index: 0,
        categoryName: "ID Verification",
        thumbnailImage: 'assets/api-doc-images/category-images/id.png',
        active: true,
        data: [
            {
                index: 0,
                subCategoryName: "Aadhaar Card",
                active: true,
                data: [
                    {
                        index: 0,
                        apiNamePostman: "OCR and Verification",
                        active: true,
                        method: "POST",
                        key: "aadhaar card verification",
                        displayName: "Aadhaar Card Verification",
                        imgSourceDark: "",
                        redirectUrl:"https://documenter.getpostman.com/view/14349489/UVJeGbtL#6b591a2a-3650-4c0e-821b-2cf8c8dd6556",
                        type: contentTypes.formData,
                        imgSourceLight: "assets/api-doc-images/ocr-verification/ocr-verify-aadhaar",
                        endpoint: "/kyc/india/v1/document-verification",
                        fields: [
                            {
                                label: "Upload File",
                                required: true,
                                subtitleText:"Choose file for verification",
                                key: "multipartFile",
                                value: "",
                                inputType: inputTypes.FILE,
                                validationRequired: false,
                                validations: [{}],
                            },
                            {
                                key: "docType",
                                value: "Aadhaar Document",
                                hidden: true,
                            }
                        ]
                    },
                    {
                        index: 1,
                        apiNamePostman: "Validation",
                        active: true,
                        method: "POST",
                        key: "Aadhaar Validation",
                        displayName: "Aadhaar Validation",
                        imgSourceDark: "",
                        redirectUrl:"https://documenter.getpostman.com/view/14349489/UVJeGbtL#f72f94fd-ec3a-4277-95dd-f0bd0e1398dc",
                        type: contentTypes.json,
                        imgSourceLight: "assets/api-doc-images/validation/aadhaar-validation",
                        endpoint: "/kyc/india/v1/document-validation",
                        fields: [
                            {
                                label: "Enter Aadhaar Number",
                                required: true,
                                key: "number",
                                value: "",
                                inputType: inputTypes.TEXT,
                                validationRequired: false,
                                validations: [{}]
                            },
                            {
                                key: "type",
                                value: "Aadhaar",
                                hidden: true,
                            }
                        ]
                    },
                    {
                        index: 2,
                        apiNamePostman: "OTP Verification",
                        active: true,
                        method: "POST",
                        key: "Send Otp",
                        displayName: "Aadhaar OKYC",
                        redirectUrl:"https://documenter.getpostman.com/view/14349489/UVJeGbtL#ff3c2d54-a163-4564-89e3-11d4207bf236",
                        type: contentTypes.json,
                        imgSourceDark: "",
                        imgSourceLight: "assets/api-doc-images/otp/send-otp",
                        endpoint: "/kyc/india/v1/initiate-aadhaar",
                        fields: [
                            {
                                label: "Enter Aadhaar Number",
                                required: true,
                                key: "aadhaar_no",
                                value: "",
                                inputType: inputTypes.TEXT,
                                validationRequired: false,
                                validations: [{}]
                            }
                        ]
                    },
                    {
                        index: 3,
                        apiNamePostman: "OTP Verification",
                        active: true,
                        method: "POST",
                        key: "Send Otp Extra",
                        displayName: "Aadhaar OKYC(Extra Attribute)",
                        redirectUrl:"https://documenter.getpostman.com/view/14349489/UVJeGbtL#ff3c2d54-a163-4564-89e3-11d4207bf236",
                        type: contentTypes.json,
                        imgSourceDark: "",
                        imgSourceLight: "assets/api-doc-images/otp/send-otp",
                        endpoint: "/kyc/india/v3/initiate-aadhaar",
                        fields: [
                            {
                                label: "Enter Aadhaar Number",
                                required: true,
                                key: "aadhaar_no",
                                value: "",
                                inputType: inputTypes.TEXT,
                                validationRequired: false,
                                validations: [{}]
                            }
                        ]
                    },
                    {
                        index: 4,
                        apiNamePostman: "OCR",
                        active: true,
                        method: "POST",
                        key: "Aadhaar OCR",
                        outputFields:"dataArray",
                        displayName: "Aadhaar OCR",
                        redirectUrl:"https://documenter.getpostman.com/view/14349489/UVJeGbtL#a9dcb61d-c250-4769-9df9-dde524c9c9de",
                        type: contentTypes.formData,
                        imgSourceDark: "",
                        imgSourceLight: "assets/api-doc-images/ocr/ocr-aadhaar",
                        endpoint: "/kyc/india/v1/id-ocr",
                        fields: [
                            {
                                label: "Upload File",
                                required: true,
                                subtitleText:"Choose file for verification",
                                key: "multipartFile",
                                value: "",
                                inputType: inputTypes.FILE,
                                validationRequired: false,
                                validations: [{}],
                            },
                            {
                                key: "docType",
                                value: "aadhaar",
                                hidden: true,
                            }
                        ]
                    },
                    {
                        index: 5,
                        apiNamePostman: "Premium Validation",
                        active: true,
                        method: "POST",
                        key: "premium aadhaar validation",
                        displayName: "Premium Aadhaar Validation",
                        redirectUrl:"https://documenter.getpostman.com/view/14349489/UVJeGbtL#6b7ef5cb-d5ca-43da-a4d6-ecde6725d9c9",
                        type: contentTypes.json,
                        outputFields:"onlyData",
                        imgSourceDark: "",
                        imgSourceLight: "assets/api-doc-images/validation/premium-aadhaar-validation",
                        endpoint: "/kyc/india/v1/premmium-aadhaar-validation",
                        fields: [
                            {
                                label: "Name",
                                required: true,
                                key: "name",
                                value: "",
                                inputType: inputTypes.TEXT,
                                validationRequired: false,
                                validations: [{}]
                            },
                            {
                                label: "Aadhaar Number",
                                required: true,
                                key: "aadhaarNumber",
                                value: "",
                                inputType: inputTypes.TEXT,
                                validationRequired: false,
                                validations: [{}]
                            }
                        ]
                    },
                    {
                        index: 6,
                        apiNamePostman: "Submit",
                        active: false,
                        method: "POST",
                        key: "Submit Aadhaar",
                        displayName: "Submit Aadhaar",
                        redirectUrl:"https://documenter.getpostman.com/view/14349489/UVJeGbtL#11ff39a8-13c2-4462-acb3-ecfceb5abc3c",
                        type: contentTypes.json,
                        imgSourceDark: "",
                        imgSourceLight: "assets/api-doc-images/otp/submit-aadhaar",
                        endpoint: "/kyc/india/v1/submit-aadhaar",
                        fields: [
                            {
                                label: "share_code",
                                required: true,
                                key: "share_code",
                                value: "",
                                inputType: inputTypes.TEXT,
                                validationRequired: false,
                                validations: [{}]
                            },
                            {
                                label: "Aadhaar_OTP",
                                required: true,
                                key: "aadhaar_otp",
                                value: "",
                                inputType: inputTypes.TEXT,
                                validationRequired: false,
                                validations: [{}]
                            },
                            {
                                label: "reference_id",
                                required: true,
                                key: "reference_id",
                                value: "",
                                inputType: inputTypes.TEXT,
                                validationRequired: false,
                                validations: [{}]
                            }
                        ]
                    },
                    {
                        index: 7,
                        apiNamePostman: "Submit",
                        active: false,
                        method: "POST",
                        key: "Submit Aadhaar Extra",
                        outputFields:"onlyData",
                        displayName: "Submit Aadhaar(Extra Attribute)",
                        redirectUrl:"https://documenter.getpostman.com/view/14349489/UVJeGbtL#11ff39a8-13c2-4462-acb3-ecfceb5abc3c",
                        type: contentTypes.json,
                        imgSourceDark: "",
                        imgSourceLight: "assets/api-doc-images/otp/submit-aadhaar",
                        
                        endpoint: "/kyc/india/v3/submit-aadhaar",
                        fields: [
                            {
                                label: "share_code",
                                required: true,
                                key: "share_code",
                                value: "",
                                inputType: inputTypes.TEXT,
                                validationRequired: false,
                                validations: [{}]
                            },
                            {
                                label: "Aadhaar_OTP",
                                required: true,
                                key: "aadhaar_otp",
                                value: "",
                                inputType: inputTypes.TEXT,
                                validationRequired: false,
                                validations: [{}]
                            },
                            {
                                label: "reference_id",
                                required: true,
                                key: "reference_id",
                                value: "",
                                inputType: inputTypes.TEXT,
                                validationRequired: false,
                                validations: [{}]
                            }
                        ]
                    }
                ]
            },
            {
                index: 1,
                subCategoryName: "Passport",
                active: true,
                data: [
                    {
                        index: 0,
                        apiNamePostman: "Validation",
                        active: true,
                        method: "POST",
                        key: "Passport Validation",
                        displayName: "Passport Validation",
                        redirectUrl:"https://documenter.getpostman.com/view/14349489/UVJeGbtL#cb05a1e9-ef3e-461d-a2df-7e3f2a7da964",
                        type: contentTypes.json,
                        imgSourceDark: "",
                        imgSourceLight: "assets/api-doc-images/validation/passport-validation",
                        endpoint: "/kyc/india/v1/document-validation",
                        fields: [
                            {
                                label: "Enter File Number",
                                required: true,
                                key: "number",
                                value: "",
                                inputType: inputTypes.TEXT,
                                validationRequired: false,
                                validations: [{}]
                            },
                            {
                                label: "Date of Birth",
                                required: true,
                                key: "dob",
                                value: "",
                                inputType: inputTypes.DATE,
                                validationRequired: false,
                                validations: [{}]
                            },
                            {
                                label: "Enter Passport Number",
                                required: true,
                                key: "passport_no",
                                value: "",
                                inputType: inputTypes.TEXT,
                                validationRequired: false,
                                validations: [{}]
                            },
                            {
                                key: "type",
                                value: "passport",
                                hidden: true,
                            }
                        ]
                    },
                    {
                        index: 1,
                        apiNamePostman: "OCR",
                        active: true,
                        method: "POST",
                        key: "Passport OCR",
                        outputFields:"dataArray",
                        displayName: "Passport OCR",
                        redirectUrl:"https://documenter.getpostman.com/view/14349489/UVJeGbtL#7e741e9f-eab7-489d-916b-a0f1078a6b8f",
                        type: contentTypes.formData,
                        imgSourceDark: "",
                        imgSourceLight: "assets/api-doc-images/ocr/ocr-passport",
                        endpoint: "/kyc/india/v1/id-ocr",
                        fields: [
                            {
                                label: "Upload File",
                                required: true,
                                subtitleText:"Choose file for verification",
                                key: "multipartFile",
                                value: "",
                                inputType: inputTypes.FILE,
                                validationRequired: false,
                                validations: [{}],
                            },
                            {
                                key: "docType",
                                value: "passport",
                                hidden: true,
                            }
                        ]
                    },
                    {
                        index: 2,
                        apiNamePostman: "OCR and Verification",
                        active: true,
                        method: "POST",
                        key: "passport verification",
                        displayName: "Passport Verification",
                        redirectUrl:"https://documenter.getpostman.com/view/14349489/UVJeGbtL#43594bbc-5a4c-4a7b-bb59-ba4cdfd9f119",
                        type: contentTypes.formData,
                        imgSourceDark: "",
                        imgSourceLight: "assets/api-doc-images/ocr-verification/ocr-verify-passport",
                        endpoint: "/kyc/india/v1/document-verification",
                        fields: [
                            {
                                label: "Upload File",
                                required: true,
                                subtitleText:"Choose file for verification",
                                key: "multipartFile",
                                value: "",
                                inputType: inputTypes.FILE,
                                validationRequired: false,
                                validations: [{}],
                            },
                            {
                                key: "docType",
                                value: "Passport document",
                                hidden: true,
                            }
                        ]
                    },
 
                ]
            },
            {
                index: 2,
                subCategoryName: "PAN Card",
                active: true,
                data: [
                    {
                        index: 0,
                        apiNamePostman: "Validation",
                        active: true,
                        method: "POST",
                        key: "Pan Validation",
                        outputFields:"onlyData",
                        displayName: "PAN Validation",
                        redirectUrl:"https://documenter.getpostman.com/view/14349489/UVJeGbtL#abaf5441-c45d-4d4d-b59c-219f25a8f5f5",
                        type: contentTypes.json,
                        imgSourceDark: "",
                        imgSourceLight: "assets/api-doc-images/validation/pan-validation",
                        endpoint: "/kyc/india/v1/document-validation",
                        fields: [
                            {
                                label: "Enter Full Name",
                                required: true,
                                key: "name",
                                value: "",
                                inputType: inputTypes.TEXT,
                                validationRequired: false,
                                validations: [{}]
                            },
                            {
                                label: "Date of Birth",
                                required: true,
                                key: "dob",
                                value: "",
                                inputType: inputTypes.DATE,
                                validationRequired: false,
                                validations: [{}]
                            },
                            {
                                label: "Enter PAN Number",
                                required: true,
                                key: "number",
                                value: "",
                                inputType: inputTypes.TEXT,
                                validationRequired: false,
                                validations: [{}]
                            },
                            {
                                key: "type",
                                value: "PAN",
                                hidden: true,
                            }
                        ]
                    },
                    {
                        index: 1,
                        apiNamePostman: "Premium Validation",
                        active: true,
                        method: "POST",
                        key: "Premium Pan Validation",
                        displayName: "Premium PAN Validation",
                        outputFields:"onlyData",
                        redirectUrl:"https://documenter.getpostman.com/view/14349489/UVJeGbtL#5401541e-544f-47d6-9f04-e3cce88b59f1",
                        type: contentTypes.json,
                        imgSourceDark: "",
                        imgSourceLight: "assets/api-doc-images/validation/premium-pan-validation",
                        endpoint: "/kyc/india/v1/premium-pan-validation",
                        fields: [
                            {
                                label: "Enter Full Name",
                                required: true,
                                key: "name",
                                value: "",
                                inputType: inputTypes.TEXT,
                                validationRequired: false,
                                validations: [{}]
                            },
                            {
                                label: "Enter PAN Number",
                                required: true,
                                key: "pan",
                                value: "",
                                inputType: inputTypes.TEXT,
                                validationRequired: false,
                                validations: [{}]
                            },
                            {
                                label: "Enter last 4 digit of Aadhaar",
                                required: true,
                                key: "aadhaarLastFour",
                                value: "",
                                inputType: inputTypes.TEXT,
                                validationRequired: false,
                                validations: [{}]
                            },
                            {
                                label: "Date of Birth",
                                required: true,
                                key: "dob",
                                value: "",
                                inputType: inputTypes.DATE,
                                validationRequired: false,
                                validations: [{}]
                            },
                            {
                                label: "Enter Address",
                                required: true,
                                key: "address",
                                value: "",
                                inputType: inputTypes.TEXT,
                                validationRequired: false,
                                validations: [{}]
                            }
                        ]
                    },
                    {
                        index: 2,
                        apiNamePostman: "Fetch",
                        active: true,
                        method: "POST",
                        key: "Pan Fetch",
                        displayName: "PAN Fetch",
                        redirectUrl:"https://documenter.getpostman.com/view/14349489/UVJeGbtL#21153697-308c-4dea-93ad-0be5d0cb19ee",
                        type: contentTypes.json,
                        imgSourceDark: "",
                        imgSourceLight: "assets/api-doc-images/fetch/pan-fetch",
                        endpoint: "/kyc/india/v1/fetch-pan",
                        fields: [
                            {
                                label: "Enter PAN Number",
                                required: true,
                                key: "pan",
                                value: "",
                                inputType: inputTypes.TEXT,
                                validationRequired: false,
                                validations: [{}]
                            }
                        ]
                    },
                    {
                        index: 3,
                        apiNamePostman: "Premium Fetch",
                        active: true,
                        method: "POST",
                        key: "Premium Pan Fetch",
                        outputFields:"onlyData",
                        displayName: "Premium PAN Fetch",
                        redirectUrl:"https://documenter.getpostman.com/view/14349489/UVJeGbtL#0a6e0776-78b6-4a53-856e-a7e4dbfe5fb8",
                        type: contentTypes.json,
                        imgSourceDark: "",
                        imgSourceLight: "assets/api-doc-images/fetch/premium-pan-fetch",
                        endpoint: "/kyc/india/v1/premium-pan-fetch",
                        fields: [
                            {
                                label: "Enter PAN number",
                                required: true,
                                key: "panNumber",
                                value: "",
                                inputType: inputTypes.TEXT,
                                validationRequired: false,
                                validations: [{}]
                            },
                            {
                                label: "Date of Birth",
                                required: true,
                                key: "dob",
                                value: "",
                                inputType: inputTypes.DATE,//<dd/mm/yyyy>
                                validationRequired: false,
                                validations: [{}]
                            }
                        ]
                    },
                    {
                        index: 4,
                        apiNamePostman: "OCR and Verification",
                        active: true,
                        method: "POST",
                        key: "pan card verification",
                        displayName: "PAN Card Verification",
                        redirectUrl:"https://documenter.getpostman.com/view/14349489/UVJeGbtL#905c889b-8664-4f34-bc69-89b3e72187ee",
                        type: contentTypes.formData,
                        imgSourceDark: "",
                        imgSourceLight: "assets/api-doc-images/ocr-verification/ocr-verify-pan",
                        endpoint: "/kyc/india/v1/document-verification",
                        fields: [
                            {
                                label: "Upload File",
                                required: true,
                                subtitleText:"Choose file for verification",
                                key: "multipartFile",
                                value: "",
                                inputType: inputTypes.FILE,
                                validationRequired: false,
                                validations: [{}],
                            },
                            {
                                key: "docType",
                                value: "PAN Document",
                                hidden: true,
                            }
                        ]
                    },
                    {
                        index: 5,
                        apiNamePostman: "OCR",
                        active: true,
                        method: "POST",
                        key: "Pan OCR",
                        outputFields:"dataArray",
                        displayName: "PAN OCR",
                        redirectUrl:"https://documenter.getpostman.com/view/14349489/UVJeGbtL#36e63a56-7b90-42f3-91bf-b1d6962f55e9",
                        type: contentTypes.formData,
                        imgSourceDark: "",
                        imgSourceLight: "assets/api-doc-images/ocr/ocr-pan",
                        endpoint: "/kyc/india/v1/id-ocr",
                        fields: [
                            {
                                label: "Upload File",
                                required: true,
                                subtitleText:"Choose file for verification",
                                key: "multipartFile",
                                value: "",
                                inputType: inputTypes.FILE,
                                validationRequired: false,
                                validations: [{}],
                            },
                            {
                                key: "docType",
                                value: "pan",
                                hidden: true,
                            }
                        ]
 
                    },
                ]
            },
            {
                index: 3,
                subCategoryName: "Voter ID Card",
                active: true,
                data: [
                    {
                        index: 0,
                        apiNamePostman: "Validation",
                        active: true,
                        method: "POST",
                        key: "Voter Card Validation",
                        displayName: "Voter Card Validation",
                        redirectUrl:"https://documenter.getpostman.com/view/14349489/UVJeGbtL#e36a55a7-faa7-4fc5-9936-e36e275e7f39",
                        type: contentTypes.json,
                        imgSourceDark: "",
                        imgSourceLight: "assets/api-doc-images/validation/voter-card-validation",
                        endpoint: "/kyc/india/v1/document-validation",
                        fields: [
                            {
                                label: "",
                                required: true,
                                key: "number",
                                value: "",
                                inputType: inputTypes.TEXT,
                                validationRequired: false,
                                validations: [{}]
                            },
                            {
                                key: "type",
                                value: "voter card",
                                hidden: true,
                            }
                        ]
                    },
                    {
                        index: 1,
                        apiNamePostman: "OCR and Verification",
                        active: true,
                        method: "POST",
                        key: "voter card verification",
                        displayName: "Voter Card Verification",
                        redirectUrl:"https://documenter.getpostman.com/view/14349489/UVJeGbtL#3ee649c4-4fe3-44a8-bdd3-d04d880bd2e7",
                        type: contentTypes.formData,
                        imgSourceDark: "",
                        imgSourceLight: "assets/api-doc-images/ocr-verification/ocr-verify-voter",
                        endpoint: "/kyc/india/v1/document-verification",
                        fields: [
                            {
                                label: "Upload File",
                                required: true,
                                subtitleText:"Choose file for verification",
                                key: "multipartFile",
                                value: "",
                                inputType: inputTypes.FILE,
                                validationRequired: false,
                                validations: [{}],
                            },
                            {
                                key: "docType",
                                value: "Voter Card",
                                hidden: true,
                            }
                        ]
                    }
                ]
            },
            {
                index: 4,
                subCategoryName: "RC",
                active: true,
                data: [
                    {
                        index: 0,
                        apiNamePostman: "OCR and Verification",
                        active: true,
                        method: "POST",
                        key: "RC verification",
                        displayName: "RC verification",
                        redirectUrl:"https://documenter.getpostman.com/view/14349489/UVJeGbtL#e17821ca-feda-4249-98b7-4af3e8f67fc9",
                        type: contentTypes.formData,
                        imgSourceDark: "",
                        imgSourceLight: "assets/api-doc-images/ocr-verification/ocr-verify-rc",
                        endpoint: "/kyc/india/v1/document-verification",
                        fields: [
                            {
                                label: "Upload File",
                                required: true,
                                subtitleText:"Choose file for verification",
                                key: "multipartFile",
                                value: "",
                                inputType: inputTypes.FILE,
                                validationRequired: false,
                                validations: [{}]
                            },
                            {
                                key: "docType",
                                value: "RC Document",
                                hidden: true,
                            }
                        ]
                    }
                ]
            },
            {
                index: 5,
                subCategoryName: "Driving License",
                active: true,
                data: [
                    {
                        index: 0,
                        apiNamePostman: "Validation",
                        active: true,
                        method: "POST",
                        key: "DL Validation",
                        displayName: "DL Validation",
                        redirectUrl:"https://documenter.getpostman.com/view/14349489/UVJeGbtL#078a7b76-e156-4846-bc45-a7f50e387420",
                        type: contentTypes.json,
                        imgSourceDark: "",
                        imgSourceLight: "assets/api-doc-images/validation/dl-validation",
                        endpoint: "/kyc/india/v1/document-validation",
                        fields: [
                            {
                                label: "Enter Diriving License Number",
                                required: true,
                                key: "number",
                                value: "",
                                inputType: inputTypes.TEXT,
                                validationRequired: false,
                                validations: [{}]
                            },
                            {
                                label: "Date of Birth",
                                required: true,
                                key: "dob",
                                value: "",
                                inputType: inputTypes.DATE,// dd/mm/yyyy
                                validationRequired: false,
                                validations: [{}]
                            },
                            {
                                key: "type",
                                value: "driving licence",
                                hidden: true,
                            }
                        ]
                    },
                    {
                        index: 1,
                        apiNamePostman: "Fetch",
                        active: true,
                        method: "POST",
                        key: "DL Fetch",
                        displayName: "DL Fetch",
                        redirectUrl:"https://documenter.getpostman.com/view/14349489/UVJeGbtL#9487d624-1d11-43a5-8ce0-e5c8a9148922",
                        type: contentTypes.json,
                        imgSourceDark: "",
                        imgSourceLight: "assets/api-doc-images/fetch/dl-fetch",
                        endpoint: "/kyc/india/v1/fetch-dl",
                        fields: [
                            {
                                label: "Enter Diriving License Number",
                                required: true,
                                key: "number",
                                value: "",
                                inputType: inputTypes.TEXT,
                                validationRequired: false,
                                validations: [{}]
                            },
                            {
                                label: "Date of Birth",
                                required: true,
                                key: "dob",
                                value: "",
                                inputType: inputTypes.DATE, // dd/mm/yyyy
                                validationRequired: false,
                                validations: [{}]
                            },
                        ]
                    },
                    {
                        index: 2,
                        apiNamePostman: "OCR and Verification",
                        active: true,
                        method: "POST",
                        key: "DL verification",
                        displayName: "DL verification",
                        redirectUrl:"https://documenter.getpostman.com/view/14349489/UVJeGbtL#27c2100d-c6be-412d-8902-67762857bb32",
                        type: contentTypes.formData,
                        imgSourceDark: "",
                        imgSourceLight: "assets/api-doc-images/ocr-verification/ocr-verify-dl",
                        endpoint: "/kyc/india/v1/document-verification",
                        fields: [
                            {
                                label: "Upload File",
                                required: true,
                                subtitleText:"Choose file for verification",
                                key: "multipartFile",
                                value: "",
                                inputType: inputTypes.FILE,
                                validationRequired: false,
                                validations: [{}],
                            },
                            {
                                key: "docType",
                                value: "DL Document",
                                hidden: true,
                            }
                        ]
                    },
                    {
                        index: 3,
                        apiNamePostman: "OCR",
                        active: true,
                        method: "POST",
                        key: "DL OCR",
                        outputFields:"dataArray",
                        displayName: "DL OCR",
                        redirectUrl:"https://documenter.getpostman.com/view/14349489/UVJeGbtL#5a7c0e0c-be7e-4402-a863-be7fbab704fc",
                        type: contentTypes.formData,
                        imgSourceDark: "",
                        imgSourceLight: "assets/api-doc-images/ocr/ocr-dl",
                        endpoint: "/kyc/india/v1/id-ocr",
                        fields: [
                            {
                                label: "Upload File",
                                required: true,
                                subtitleText:"Choose file for verification",
                                key: "multipartFile",
                                value: "",
                                inputType: inputTypes.FILE,
                                validationRequired: false,
                                validations: [{}],
                            },
                            {
                                key: "docType",
                                value: "dl",
                                hidden: true,
                            }
                        ]
                    },
                    {
                        index: 4,
                        apiNamePostman: "Premium Validation",
                        active: true,
                        method: "POST",
                        key: "Premium DL Validation",
                        outputFields:"onlyData",
                        displayName: "Premium DL Validation",
                        redirectUrl:"https://documenter.getpostman.com/view/14349489/UVJeGbtL#8af3eb42-9e94-4396-b3e7-becdcf8d29c8",
                        type: contentTypes.json,
                        imgSourceDark: "",
                        imgSourceLight: "assets/api-doc-images/validation/premium-dl-validation",
                        endpoint: "/kyc/india/v1/premium-dl-validation",
                        fields: [
                            {
                                label: "Enter Driving License Number",
                                required: true,
                                key: "dlNo",
                                value: "",
                                inputType: inputTypes.TEXT,
                                validationRequired: false,
                                validations: [{}]
                            },
                            {
                                label: "Date of Birth",
                                required: true,
                                key: "dob",
                                value: "",
                                inputType: inputTypes.DATE, // dd-mm-yyyy
                                validationRequired: false,
                                validations: [{}]
                            }
                        ]
                    }
                ]
            }
        ]
    },
    {
        index: 1,
        categoryName: "Finance API",
        thumbnailImage: 'assets/api-doc-images/category-images/finance.png',
        active: true,
        data: [
            {
                index: 0,
                subCategoryName: "Account",
                active: true,
                data: [
                    {
                        index: 0,
                        apiNamePostman: "Verification",
                        active: true,
                        method: "POST",
                        key: "Bank Account Verify",
                        displayName: "Bank Account Verify",
                        redirectUrl:"https://documenter.getpostman.com/view/14349489/UVJeGbtL#7f964463-7735-4332-bc1c-31600261f8af",
                        type: contentTypes.json,
                        imgSourceDark: "",
                        imgSourceLight: "assets/api-doc-images/bank/bank-account-verify",
                        endpoint: "/kyc/india/v1/verify-bank-account",
                        fields: [
                            {
                                label: "Enter Bank Account No.",
                                required: true,
                                key: "account_number",
                                value: "",
                                inputType: inputTypes.TEXT,
                                validationRequired: false,
                                validations: [{}]
                            },
                            {
                                label: "Enter Account Holder Name",
                                required: true,
                                key: "name",
                                value: "",
                                inputType: inputTypes.TEXT,
                                validationRequired: false,
                                validations: [{}]
                            },
                            {
                                label: "Enter IFSC Code",
                                required: true,
                                key: "ifsc",
                                value: "",
                                inputType: inputTypes.TEXT,
                                validationRequired: false,
                                validations: [{}]
                            }
                        ]
                    }
                ]
            },
            {
                index: 1,
                subCategoryName: "VPA Account",
                active: true,
                data: [
                    {
                        index: 0,
                        apiNamePostman: "Verification",
                        active: true,
                        method: "POST",
                        key: "Upi Verify",
                        displayName: "UPI Verify",
                        redirectUrl:"https://documenter.getpostman.com/view/14349489/UVJeGbtL#7474a5fe-cb2e-4bd5-837c-3f941bb55163",
                        type: contentTypes.json,
                        imgSourceDark: "",
                        imgSourceLight: "assets/api-doc-images/bank/upi-verify",
                        endpoint: "/kyc/india/v1/vpa-validate",
                        fields: [
                            {
                                label: "Enter UPI ID",
                                required: true,
                                key: "vpa",
                                value: "",
                                inputType: inputTypes.TEXT,
                                validationRequired: false,
                                validations: [{}]
                            }
                        ]
                    }
                ]
            },
            {
                index: 2,
                subCategoryName: "Cheque",
                active: true,
                data: [
                    {
                        index: 0,
                        apiNamePostman: "OCR",
                        active: true,
                        method: "POST",
                        key: "cheque ocr",
                        displayName: "Cheque OCR",
                        redirectUrl:"https://documenter.getpostman.com/view/14349489/UVJeGbtL#bbe3cf7b-318c-4526-8ce7-bb7541369422",
                        type: contentTypes.formData,
                        imgSourceDark: "",
                        imgSourceLight: "assets/api-doc-images/bank/cheque-ocr",
                        endpoint: "/kyc/india/v2/cheque-ocr",
                        fields: [
                            {
                                label: "Upload File",
                                required: true,
                                subtitleText:"Choose file for verification",
                                key: "file",
                                value: "",
                                inputType: inputTypes.FILE,
                                validationRequired: false,
                                validations: [{}]
                            }
                        ]
                    },
                    {
                        index: 1,
                        apiNamePostman: "OCR",
                        active: true,
                        method: "POST",
                        key: "cancel cheque ocr",
                        displayName: "Cancel Cheque OCR",
                        outputFields:"dataArray",
                        redirectUrl:"https://documenter.getpostman.com/view/14349489/UVJeGbtL#bbd4305f-497a-47cd-ab73-05644a22c7ab",
                        type: contentTypes.formData,
                        imgSourceDark: "",
                        imgSourceLight: "assets/api-doc-images/bank/cancelled-cheque-ocr",
                        endpoint: "/kyc/india/v2/cancelled-cheque-ocr",
                        fields: [
                            {
                                label: "Upload File",
                                required: true,
                                subtitleText:"Choose file for verification",
                                key: "file",
                                value: "",
                                inputType: inputTypes.FILE,
                                validationRequired: false,
                                validations: [{}]
                            },
                            {
                                key: "docType",
                                value: "cheque",
                                hidden: true,
                            }
                        ]
 
 
 
                    }
                ]
            },
            {
                index: 3,
                subCategoryName: "IFSC Code",
                active: true,
                data: [
                    {
                        index: 0,
                        apiNamePostman: "Search",
                        active: true,
                        method: "POST",
                        key: "IFSC Code Fetch",
                        outputFields:"onlyData",
                        displayName: "IFSC Code Fetch",
                        redirectUrl:"https://documenter.getpostman.com/view/14349489/UVJeGbtL#3b354f59-94af-4ace-90dd-8100eb2fb55f",
                        type: contentTypes.params,
                        imgSourceDark: "",
                        imgSourceLight: "assets/api-doc-images/fetch/ifsc-code-fetch",
                        endpoint: "/kyc/india/v1/ifsccode/",
                        fields: [
                            {
                                label: "Enter IFSC Code",
                                required: true,
                                key: "ifsc-code",
                                value: "",
                                inputType: inputTypes.TEXT,
                                validationRequired: false,
                                validations: [{}]
                            }
                        ]
                    },
                ]
            }
        ]
    },
    {
        index: 2,
        categoryName: "Business Document API`s",
        thumbnailImage: 'assets/api-doc-images/category-images/business.png',
        active: true,
        data: [
            {
                index: 0,
                subCategoryName: "PAN",
                active: true,
                data: [
                    {
                        index: 0,
                        apiNamePostman: "Verification",
                        active: true,
                        method: "POST",
                        key: "Pan Check Advance",
                        outputFields:"onlyData",
                        displayName: "PAN CHECK ADVANCE",
                        redirectUrl:"https://documenter.getpostman.com/view/14349489/UVJeGbtL#327c3d5f-6099-4d9f-9fce-f6ae6d83438e",
                        type: contentTypes.formData,
                        // imgSourceDark: "",
                        // imgSourceLight: "assets/api-doc-images/business/mca-search",
                        endpoint: "/kyc/india/v2/pan-check-advance",
                        fields: [
                            {
                                label: "Name",
                                required: true,
                                key: "name",
                                value: "",
                                inputType: inputTypes.TEXT,
                                validationRequired: false,
                                validations: [{}]
                            },
                            {
                                label: "Fathers Name",
                                required: true,
                                key: "fathersName",
                                value: "",
                                inputType: inputTypes.TEXT,
                                validationRequired: false,
                                validations: [{}]
                            },
                            {
                                label: "PAN Number",
                                required: true,
                                key: "pan",
                                value: "",
                                inputType: inputTypes.TEXT,
                                validationRequired: false,
                                validations: [{}]
                            },
                            {
                                label: "Date of Birth",
                                required: true,
                                key: "dob",
                                value: "",
                                inputType: inputTypes.DATE,
                                validationRequired: false,
                                validations: [{}]
                            },

                            {   
                                label:"Email",
                                key: "mail",
                                inputType: inputTypes.TEXT,
                                value: "",
                                required:true   
                            },
                            {
                                label: "Upload File",
                                required: true,
                                subtitleText:"Choose file for verification",
                                key: "multipartFile",
                                value: "",
                                inputType: inputTypes.FILE,
                                validationRequired: false,
                                validations: [{}],
                            }
                        ]
                    }
                ]
            },
            {
                index: 1,
                subCategoryName: "MCA Data",
                active: true,
                data: [
                    {
                        index: 0,
                        apiNamePostman: "Search",
                        active: true,
                        method: "POST",
                        key: "mca search",
                        outputFields:"onlyData",
                        displayName: "MCA Search",
                        redirectUrl:"https://documenter.getpostman.com/view/14349489/UVJeGbtL#abd0fa1e-206d-4979-86c1-02951a356ca5",
                        type: contentTypes.json,
                        imgSourceDark: "",
                        imgSourceLight: "assets/api-doc-images/business/mca-search",
                        endpoint: "/kyc/india/v1/mca-search",
                        fields: [
                            {
                                label: "Enter CIN",
                                required: true,
                                key: "cin",
                                value: "",
                                inputType: inputTypes.TEXT,
                                validationRequired: false,
                                validations: [{}]
                            }
                        ]
                    }
                ]
            },
            {
                index: 2,
                subCategoryName: "DIN",
                active: true,
                data: [
                    {
                        index: 0,
                        apiNamePostman: "Details",
                        active: true,
                        method: "POST",
                        key: "din details",
                        displayName: "DIN Details",
                        redirectUrl:"https://documenter.getpostman.com/view/14349489/UVJeGbtL#cb22377f-c565-4b21-9a42-2c38e1b44858",
                        type: contentTypes.json,
                        imgSourceDark: "",
                        imgSourceLight: "assets/api-doc-images/business/din-details",
                        endpoint: "/kyc/india/v1/din-details",
                        fields: [
                            {
                                label: "Enter ID",
                                required: true,
                                key: "id",
                                value: "",
                                inputType: inputTypes.TEXT,
                                validationRequired: false,
                                validations: [{}]
                            },
                            {
                                label: "Enter Name",
                                required: false,
                                key: "name",
                                value: "",
                                inputType: inputTypes.TEXT,
                                validationRequired: false,
                                validations: [{}]
                            },
                            {
                                label: "Enter Father's Name",
                                required: false,
                                key: "fatherName",
                                value: "",
                                inputType: inputTypes.TEXT,
                                validationRequired: false,
                                validations: [{}]
                            },
                            {
                                label: "Enter Address",
                                required: false,
                                key: "address",
                                value: "",
                                inputType: inputTypes.TEXT,
                                validationRequired: false,
                                validations: [{}]
                            },
                            {
                                label: "Select Date of Inception",
                                required: false,
                                key: "dateOfInception",
                                value: "",
                                type: 'type',
                                validationRequired: false,
                                validations: [{}]
                            },
                        ]
                    }
                ]
            },
            {
                index: 3,
                subCategoryName: "CIN",
                active: true,
                data: [
                    {
                        index: 0,
                        apiNamePostman: "Search",
                        active: true,
                        method: "POST",
                        key: "cin simple search",
                        displayName: "CIN Simple Search",
                        redirectUrl:"https://documenter.getpostman.com/view/14349489/UVJeGbtL#980e260e-3b77-497f-9d5a-58bcd16570f1",
                        type: contentTypes.json,
                        imgSourceDark: "",
                        imgSourceLight: "assets/api-doc-images/business/cin-search",
                        endpoint: "/kyc/india/v1/cin-simple-search",
                        fields: [
                            {
                                label: "Enter CIN",
                                required: true,
                                key: "cin",
                                value: "",
                                inputType: inputTypes.TEXT,
                                validationRequired: false,
                                validations: [{}]
                            }
                        ]
                    }
                ]
            },
            {
                index: 4,
                subCategoryName: "GST",
                active: true,
                data: [
                    {
                        index: 0,
                        apiNamePostman: "Search",
                        active: true,
                        method: "POST",
                        key: "gst",
                        displayName: "GST",
                        redirectUrl:"https://documenter.getpostman.com/view/14349489/UVJeGbtL#5082bfdc-1132-4eba-b917-18adad3dbafc",
                        type: contentTypes.json,
                        imgSourceDark: "",
                        imgSourceLight: "assets/api-doc-images/business/gst",
                        endpoint: "/kyc/india/v1/gst-details",
                        fields: [
                            {
                                label: "Enter GST Number",
                                required: true,
                                key: "gstNumber",
                                value: "",
                                inputType: inputTypes.TEXT,
                                validationRequired: false,
                                validations: [{}]
                            }
                        ]
                    },
                    {
                        index: 1,
                        apiNamePostman: "Validate",
 
                    }
                ]
            },
            {
                index: 5,
                subCategoryName: "IEC",
                active: true,
                data: [
                    {
                        index: 0,
                        apiNamePostman: "Verification",
                        active: true,
                        method: "POST",
                        key: "iec",
                        displayName: "IEC",
                        outputFields:"onlyData",
                        redirectUrl:"https://documenter.getpostman.com/view/14349489/UVJeGbtL#28015f45-3b26-4137-a129-fc1b8d6d81d8",
                        type: contentTypes.json,
                        imgSourceDark: "",
                        imgSourceLight: "assets/api-doc-images/business/iec",
                        endpoint: "/kyc/india/v1/iec-search",
                        fields: [
                            {
                                label: "Enter IEC number",
                                required: true,
                                key: "iec",
                                value: "",
                                inputType: inputTypes.TEXT,
                                validationRequired: false,
                                validations: [{}]
                            }
                        ]
                    }
                ]
            },
            {
                index: 6,
                subCategoryName: "Udyog Aadhaar",
                active: true,
                data: [
                    {
                        index: 0,
                        apiNamePostman: "Basic",
                        active: true,
                        method: "POST",
                        key: "udhyog aadhaar basic",
                        displayName: "Udhyog Aadhaar Basic",
                        redirectUrl:"https://documenter.getpostman.com/view/14349489/UVJeGbtL#d0a867ce-ac0a-4537-beca-fd879b6018d4",
                        type: contentTypes.json,
                        imgSourceDark: "",
                        imgSourceLight: "assets/api-doc-images/business/udhyog-aadhaar-basic",
                        endpoint: "/kyc/india/v1/udhyog-aadhaar-basic",
                        fields: [
                            {
                                label: "Enter Udhyog Aadhaar number",
                                required: true,
                                key: "uam",
                                value: "",
                                inputType: inputTypes.TEXT,
                                validationRequired: false,
                                validations: [{}]
                            }
                        ]
                    },
                    {
                        index: 1,
                        apiNamePostman: "Advance",
                        active: true,
                        method: "POST",
                        key: "udhyog aadhaar advance",
                        displayName: "Udhyog Aadhaar Advance",
                        redirectUrl:"https://documenter.getpostman.com/view/14349489/UVJeGbtL#6ed465cc-58d1-41db-ba52-75a60a370822",
                        type: contentTypes.json,
                        imgSourceDark: "",
                        imgSourceLight: "assets/api-doc-images/business/udhyog-aadhaar-advance",
                        endpoint: "/kyc/india/v1/udhyog-aadhaar-advance",
                        fields: [
                            {
                                label: "Enter Udhyog Aadhaar number",
                                required: true,
                                key: "uam",
                                value: "",
                                inputType: inputTypes.TEXT,
                                validationRequired: false,
                                validations: [{}]
                            }
                        ]
                    }
                ]
            }, {
                index: 6,
                subCategoryName: "GSTIN",
                active: true,
                data: [
                    {
                        index: 0,
                        apiNamePostman: "Authentication",
                        active: true,
                        method: "POST",
                        key: "GSTIN Authentication",
                        outputFields:"onlyData",
                        displayName: "GSTIN Authentication",
                        redirectUrl:"https://documenter.getpostman.com/view/14349489/UVJeGbtL#d541cfd2-5809-4c1a-b85d-671709a65cfd",
                        type: contentTypes.json,
                        imgSourceDark: "",
                        imgSourceLight: "assets/api-doc-images/business/gstin-authentication",
                        endpoint: "/kyc/india/v2/gstin-authentication",
                        fields: [
                            {
                                label: "Enter GST number",
                                required: true,
                                key: "gstin",
                                value: "",
                                inputType: inputTypes.TEXT,
                                validationRequired: false,
                                validations: [{}]
                            },
                            {
                                key: "additionalData",
                                value: false,
                                hidden: true,
                            }
                        ]
                    },
                    {
                        index: 1,
                        apiNamePostman: "Pan Identification",
                        active: true,
                        method: "POST",
                        key: "GSTIN Pan Identification",
                        displayName: "GSTIN PAN Identification",
                        redirectUrl:"https://documenter.getpostman.com/view/14349489/UVJeGbtL#10a559d7-f702-4168-b84f-047567ac9795",
                        type: contentTypes.json,
                        imgSourceDark: "",
                        imgSourceLight: "assets/api-doc-images/business/gstin-pan-identification",
                        endpoint: "/kyc/india/v2/gstin-pan-identification",
                        fields: [
                            {
                                label: "Enter PAN number",
                                required: true,
                                key: "pan",
                                value: "",
                                inputType: inputTypes.TEXT,
                                validationRequired: false,
                                validations: [{}]
                            }
                        ]
                    },
                    {
                        index: 2,
                        apiNamePostman: "GSTIN Return Filing",
                        active: true,
                        method: "POST",
                        key: "GSTIN Return Filing",
                        displayName: "GSTIN Return Filing",
                        redirectUrl:"https://documenter.getpostman.com/view/14349489/UVJeGbtL#4b75b9de-09b8-4a20-8354-917c2433b9b4",
                        type: contentTypes.json,
                        imgSourceDark: "",
                        imgSourceLight: "assets/api-doc-images/business/gstin-return-filing",
                        endpoint: "/kyc/india/v2/gstin-return-filing",
                        fields: [
                            {
                                label: "Enter GST number",
                                required: true,
                                key: "gstin",
                                value: "",
                                inputType: inputTypes.TEXT,
                                validationRequired: false,
                                validations: [{}]
                            }
                        ]
                    }
                ]
            }
 
        ]
    },
    {
        index: 3,
        categoryName: "Employee Verification API",
        thumbnailImage: 'assets/api-doc-images/category-images/employee.png',
        active: true,
        data: [
            {
                index: 0,
                subCategoryName: "EPFO",
                active: true,
                data: [
                    {
                        index: 0,
                        apiNamePostman: "Search",
                        active: true,
                        method: "POST",
                        key: "epfo-basic-search",
                        displayName: "EPFO Basic Search",
                        redirectUrl:"",
                        type: contentTypes.json,
                        imgSourceDark: "",
                        imgSourceLight: "assets/api-doc-images/business/epfo-basic-search",
                        endpoint: "/kyc/india/v1/epfo-basic-search",
                        fields: [
                            {
                                label: "Enter Establishment Name",
                                required: true,
                                key: "establishment_name",
                                value: "",
                                inputType: inputTypes.TEXT,
                                validationRequired: false,
                                validations: [{}]
                            },
                            {
                                label: "Exact Match",
                                required: true,
                                key: "exact_match",
                                value: "",
                                inputType: inputTypes.SWITCH,
                                validationRequired: false,
                                validations: [{}]
                            }
                        ]
                    }
                ]
            },
            {
                index: 1,
                subCategoryName: "EPF",
                active: true,
                data: [{
                    index: 0,
                    apiNamePostman: "Epf get otp",
                    active: true,
                    method: "POST",
                    key: "EPF OTP-based",
                    displayName: "EPF OTP based",
                    redirectUrl:"",
                    type: contentTypes.json,
                    imgSourceDark: "",
                    imgSourceLight: "assets/api-doc-images/business/epf-get-otp",
                    endpoint: "/kyc/india/v2/epf-get-otp",
                    fields: [
                        {
                            label: "Enter UAN number",
                            required: true,
                            key: "uan",
                            value: "",
                            inputType: inputTypes.TEXT,
                            validationRequired: false,
                            validations: [{}]
                        },
                        {
                            label: "Enter Mobile number",
                            required: true,
                            key: "mobile_no",
                            value: "",
                            inputType: inputTypes.TEXT,
                            validationRequired: false,
                            validations: [{}]
                        },
                    ]
                },
                {
                    index: 1,
                    apiNamePostman: "Epf get passbook",
                    active: true,
                    method: "POST",
                    key: "EPF Passbook based",
                    displayName: "GSTIN Authentication",
                    redirectUrl:"",
                    type: contentTypes.json,
                    imgSourceDark: "",
                    imgSourceLight: "assets/api-doc-images/business/epf-get-passbook",
                    endpoint: "/kyc/india/v2/epf-get-passbook",
                    fields: [
                        {
                            label: "Enter Reference ID",
                            required: true,
                            key: "referenceId",
                            value: "",
                            inputType: inputTypes.TEXT,
                            validationRequired: false,
                            validations: [{}]
                        },
                        {
                            label: "Enter OTP",
                            required: true,
                            key: "otp",
                            value: "",
                            inputType: inputTypes.TEXT,
                            validationRequired: false,
                            validations: [{}]
                        },
 
                    ]
                },
                {
                    index: 2,
                    apiNamePostman: "Epf Employment Search",
                    active: true,
                    method: "POST",
                    key: "Epf Employment Search",
                    displayName: "EPF Employment Search",
                    redirectUrl:"",
                    type: contentTypes.json,
                    imgSourceDark: "",
                    imgSourceLight: "assets/api-doc-images/business/gstin-authentication",
                    endpoint: "/kyc/india/v2/epf-employment-search",
                    fields: [
                        {
                            label: "Enter KID",
                            required: true,
                            key: "kid",
                            value: "",
                            inputType: inputTypes.TEXT,
                            validationRequired: false,
                            validations: [{}]
                        },
                        {
                            label: "Enter Employeee Name",
                            required: true,
                            key: "employeeName",
                            value: "",
                            inputType: inputTypes.TEXT,
                            validationRequired: false,
                            validations: [{}]
                        },
                    ]
                }]
            },
            {
                index: 2,
                subCategoryName: "Employer",
                active: true,
                data: [
                    {
                        index: 0,
                        apiNamePostman: "Employer search lite",
                        active: true,
                        method: "POST",
                        key: "Employer Search",
                        displayName: "Employer Search",
                        redirectUrl:"",
                        type: contentTypes.json,
                        imgSourceDark: "",
                        imgSourceLight: "assets/api-doc-images/business/employment-search-lite",
                        endpoint: "/kyc/india/v2/employer-search-lite",
                        fields: [
                            {
                                label: "Enter Company Name",
                                required: true,
                                key: "companyName",
                                value: "",
                                inputType: inputTypes.TEXT,
                                validationRequired: false,
                                validations: [{}]
                            }
                        ]
                    },
                    {
                        index: 1,
                        apiNamePostman: "",
                        active: false,
                        method: "POST",
                        key: "",
                        displayName: "",
                        redirectUrl:"",
                        type: contentTypes.json,
                        imgSourceDark: "",
                        imgSourceLight: "assets/api-doc-images/business/epf-get-passbook",
                        endpoint: "",
                        fields: [
                        ]
                    },
                    {
                        index: 2,
                        apiNamePostman: "",
                        active: false,
                        method: "POST",
                        key: "",
                        displayName: "",
                        redirectUrl:"",
                        type: contentTypes.json,
                        imgSourceDark: "",
                        imgSourceLight: "assets/api-doc-images/business/gstin-authentication",
                        endpoint: "",
                        fields: [
 
                        ]
                    }
                ]
            },
            {
                index: 2,
                subCategoryName: "Successful Data Fetch",
                active: false,
                data: []
            },
            {
                index: 4,
                subCategoryName: "Form-16 Verification",
                active: false,
                data: []
            },
            {
                index: 5,
                subCategoryName: "Form-16 OCR",
                active: false,
                data: []
            },
            {
                index: 6,
                subCategoryName: "Form-16 Quarterly",
                active: false,
                data: []
            },
            {
                index: 7,
                subCategoryName: "UAN Lookup Basis Mobile",
                active: true,
                data: [
                    {
                        index: 0,
                        apiNamePostman: "UAN Lookup",
                        active: true,
                        method: "POST",
                        key: "Uan lookup",
                        outputFields:"onlyData",
                        displayName: "UAN lookup",
                        redirectUrl:"https://documenter.getpostman.com/view/14349489/UVJeGbtL#de0ac19c-1d0b-4f67-94f1-e016c1107d6b",
                        type: contentTypes.json,
                        imgSourceDark: "",
                        imgSourceLight: "assets/api-doc-images/business/uan-lookup",
                        endpoint: "/kyc/india/v2/uan-lookup",
                        fields: [
                            {
                                label: "Enter Mobile Name",
                                required: true,
                                key: "mobile",
                                value: "",
                                inputType: inputTypes.TEXT,
                                validationRequired: false,
                                validations: [{}]
                            }
                        ]
                    }
                ]
            },
            {
                index: 8,
                subCategoryName: "ESIC Authentication",
                active: false,
                data: []
            },
            {
                index: 9,
                subCategoryName: "Employment Verification Advanced",
                active: false,
                data: []
            },
            {
                index: 10,
                subCategoryName: "Employee Stability Check",
                active: false,
                data: []
            },
            {
                index: 11,
                subCategoryName: "Employer Drop Down",
                active: false,
                data: []
            },
            {
                index: 12,
                subCategoryName: "TDS Quaterly Verify",
                active: true,
                data: [
                    {
                        index: 0,
                        apiNamePostman: "TDS Quaterly Verify",
                        active: true,
                        method: "POST",
                        key: "TDS Quaterly Verify",
                        outputFields:"onlyData",
                        displayName: "TDS Quaterly Verify",
                        redirectUrl:"https://documenter.getpostman.com/view/14349489/UVJeGbtL#b8d4cfa1-a9d8-4b57-b85c-7e95bd3b98e8",
                        type: contentTypes.json,
                        imgSourceDark: "",
                        imgSourceLight: "assets/api-doc-images/business/tds-quarter",
                        endpoint: "/kyc/india/v2/tds-quarterly-verify",
                        fields: [
                            {
                                label: "Enter Entity ID",
                                required: true,
                                key: "entityId",
                                value: "",
                                inputType: inputTypes.TEXT,
                                validationRequired: false,
                                validations: [{}]
                            },
                            {
                                label: "Enter TAN",
                                required: true,
                                key: "tan",
                                value: "",
                                inputType: inputTypes.TEXT,
                                validationRequired: false,
                                validations: [{}]
                            },
                            {
                                label: "Enter PAN",
                                required: true,
                                key: "pan",
                                value: "",
                                inputType: inputTypes.TEXT,
                                validationRequired: false,
                                validations: [{}]
                            },
                            {
                                label: "Enter Year Duration(i.e. 2020-21)",
                                required: true,
                                key: "fiscalYear",
                                value: "",
                                inputType: inputTypes.TEXT,
                                validationRequired: false,
                                validations: [{}]
                            },
                            {
                                label: "Select Quarter",
                                required: true,
                                key: "mobile",
                                value: "",
                                inputType: inputTypes.TEXT,
                                validationRequired: false,
                                validations: [{}]
                            },
                            {
                                key: "employmentType",
                                value: "SALARY",
                                hidden: true,
                            }
                        ]
                    }
                ]
            },
            {
                index: 13,
                subCategoryName: "Electricty Bill Verify",
                active: true,
                data: [
                    {
                        index: 0,
                        apiNamePostman: "Electricity Bill Verify",
                        active: true,
                        method: "POST",
                        key: "Electricity bill Verify",
                        outputFields:"onlyData",
                        displayName: "Electricity Bill Verify",
                        redirectUrl:"https://documenter.getpostman.com/view/14349489/UVJeGbtL#72c80e02-0a85-43bf-bc86-b0721949c5e1",
                        type: contentTypes.json,
                        imgSourceDark: "",
                        imgSourceLight: "assets/api-doc-images/business/electricity-bill",
                        endpoint: "/kyc/india/v2/electricity-bill-verify",
                        fields: [
                            {
                                label: "Enter Consumer ID",
                                required: true,
                                key: "consumer_id",
                                value: "",
                                inputType: inputTypes.TEXT,
                                validationRequired: false,
                                validations: [{}]
                            },
                            {
                                label: "Enter Service Provider",
                                required: true,
                                key: "service_provider",
                                value: "",
                                inputType: inputTypes.TEXT,
                                validationRequired: false,
                                validations: [{}]
                            }
                        ]
                    }
                ]
            }
 
        ]
    },
    {
        index: 4,
        categoryName: "e-Sign",
        thumbnailImage: 'assets/api-doc-images/category-images/e-sign.png',
        active: true,
        data: [
            {
                index: 0,
                subCategoryName: "E-Sign ",
                active: true,
                data: [
                    {
                        index: 0,
                        apiNamePostman: "esign",
                        active: true,
                        method: "POST",
                        key: "E-Sign",
                        outputFields:"onlyData",
                        displayName: "E Sign",
                        redirectUrl:"https://documenter.getpostman.com/view/14349489/UVJeGbtL#cdc87676-1c1d-4d39-8dbc-c593b4c11097",
                        type: contentTypes.formData,
                        imgSourceDark: "",
                        imgSourceLight: "assets/api-doc-images/digilocker/digilocker-link",
                        endpoint: "/kyc/india/v1/esign",
                        fields: [
                            {   
                                label:"Name",
                                key: "name",
                                inputType: inputTypes.TEXT,
                                value: "",
                                required:true   
                            },
                            {   
                                label:"Email",
                                key: "email",
                                inputType: inputTypes.TEXT,
                                value: "",
                                required:true   
                            },
                            {   
                                label:"Phone Number",
                                key: "phoneNumber",
                                inputType: inputTypes.TEXT,
                                value: "",
                                required:true   
                            },
 
                            {
                                label: "Signature Expiry Date(Select a date in future)",
                                required: true,
                                key: "signatureExpiry",
                                value: "",
                                inputType: inputTypes.DATE, // dd-mm-yyyy
                                validationRequired: false,
                                validations: [{}]
                            },
                            {   
                                label:"PageNo",
                                key: "pageNo",
                                inputType: inputTypes.TEXT,
                                value: "",
                                required:true   
                            },
                            {   
                                label:"Rectangle",
                                key: "rectangle",
                                inputType: inputTypes.TEXT,
                                value: "",
                                required:true   
                            },
                            {
                                label:"Capture Location",
                                key: "locationCapture",
                                inputType: inputTypes.CHECKBOX,
                                value: true,
                                required:true  
                            },
                            {
                                label: "Upload File",
                                required: true,
                                subtitleText:"Choose file to Upload",
                                key: "file",
                                value: "",
                                inputType: inputTypes.FILE,
                                validationRequired: false,
                                validations: [{}]
                            },
                            {
                                key: "reminder",
                                value: false,
                                hidden:true, 
                            },
                            {
                                key: "reminderDuration",
                                value: 12,
                                hidden:true, 
                            },
                            {
                                key: "checkOrder",
                                value: false,
                                hidden:true,  
                            },
                            {
                                key: "customReference",
                                value: "kychub",
                                hidden:true,  
                            },
                            {
                                key: "faceCapture",
                                value: false,
                                hidden:true,  
                            }
                        ]
                    },
                    {
          
                        index: 1,
                        apiNamePostman: "esign-download",
                        active: true,
                        method: "POST",
                        key: "E-Sign Download",
                        outputFields:"onlyData",
                        displayName: "E Sign Download",
                        redirectUrl:"https://documenter.getpostman.com/view/14349489/UVJeGbtL#a4c5c7b7-efe0-4e85-939f-7817240aa763",
                        type: contentTypes.params,
                        imgSourceDark: "",
                        imgSourceLight: "assets/api-doc-images/digilocker/digilocker-link",
                        endpoint: "/kyc/india/v1/esign/download/documents/",
                        fields: [
                            {
                                label: "Enter Document ID",
                                required: true,
                                key: "document_id",
                                value: "",
                                inputType: inputTypes.TEXT,
                                validationRequired: false,
                                validations: [{}]
                            }]
                    }
                ]
            }
            
        ]
    },
    {
        index: 5,
        categoryName: "Other API’s",
        thumbnailImage: 'assets/api-doc-images/category-images/other-api.png',
        active: true,
        data: [
            {
                index: 0,
                subCategoryName: "Digilocker",
                active: true,
                data: [
                    {
                        index: 0,
                        apiNamePostman: "Link",
                        active: true,
                        method: "POST",
                        key: "Digilocker Link",
                        displayName: "Digilocker Link",
                        redirectUrl:"https://documenter.getpostman.com/view/14349489/UVJeGbtL#6f976447-7eab-4d30-ac8d-331d16ee924c",
                        type: contentTypes.json,
                        imgSourceDark: "",
                        imgSourceLight: "assets/api-doc-images/digilocker/digilocker-link",
                        endpoint: "/kyc/india/v1/digilocker-link",
                        fields: [
                            {   
                                label:"Redirect Url",
                                key: "redirectUrl",
                                inputType: inputTypes.TEXT,
                                value: "https://www.kychub.com",
                                hidden: false,
                                readOnly: true,
                            }
                        ]
 
                    },
                    {
                        index: 1,
                        apiNamePostman: "Document",
                        active: true,
                        method: "POST",
                        key: "Digilocker Documents",
                        displayName: "Digilocker Documents",
                        redirectUrl:"https://documenter.getpostman.com/view/14349489/UVJeGbtL#2e59a647-9572-47d1-bf21-14e89b2dbf06",
                        type: contentTypes.json,
                        imgSourceDark: "",
                        imgSourceLight: "assets/api-doc-images/digilocker/digilocker-document",
                        endpoint: "/kyc/india/v1/digilocker-documents",
                        fields: [
                            {
                                label: "Request Id",
                                required: true,
                                key: "accessRequestId",
                                value: "",
                                readOnly:true,
                                inputType: inputTypes.TEXT,
                                validationRequired: false,
                                validations: [{}]
                            }
                        ]
 
                    },
                    {
                        index: 2,
                        apiNamePostman: "Download",
                        active: true,
                        method: "POST",
                        key: "Digilocker Download",
                        displayName: "Digilocker Download",
                        redirectUrl:"https://documenter.getpostman.com/view/14349489/UVJeGbtL#7da2e034-1566-40ec-96a1-4c694884a7c6",
                        type: contentTypes.json,
                        imgSourceDark: "",
                        imgSourceLight: "assets/api-doc-images/digilocker/digilocker-download",
                        endpoint: "/kyc/india/v1/digilocker-download",
                        fields: [
                            {
                                label: "Request Id",
                                required: true,
                                key: "accessRequestId",
                                value: "",
                                readOnly:true,
                                inputType: inputTypes.TEXT,
                                validationRequired: false,
                                validations: [{}]
                            },
                            {
                                label: "PDF",
                                required: true,
                                subtitleText:"",
                                key: "pdfB64",
                                value: true,
                                inputType: inputTypes.CHECKBOX,
                                validationRequired: false,
                                validations: [{}],
                                nonPayload:true,
                            },
                            {
                                label: "Parsed",
                                required: true,
                                subtitleText:"",
                                key: "parsed",
                                value: true,
                                inputType: inputTypes.CHECKBOX,  
                                validationRequired: false,
                                validations: [{}],
                                nonPayload:true,
                            },
                            {
                                label: "XML",
                                required: true,
                                subtitleText:"",
                                key: "xml",
                                value: true,
                                inputType: inputTypes.CHECKBOX,
                                validationRequired: false,
                                validations: [{}],
                                nonPayload:true,
                            }
                        ]
 
                    }
                ],
 
            },
            {
                index: 1,
                subCategoryName: "Aadhaar Masking",
                active: true,
                data: [
                    {
                        index: 0,
                        active: true,
                        method: "POST",
                        key: "Mask Aadhaar v3",
                        displayName: "Mask Aadhaar",
                        redirectUrl:"https://documenter.getpostman.com/view/14349489/UVJeGbtL#7c372ce0-f81a-4019-9294-306b892b4bf3",
                        type: contentTypes.formData,
                        imgSourceDark: "",
                        imgSourceLight: "assets/api-doc-images/mask/aadhaar-v3",
                        endpoint: "/kyc/india/v3/mask-aadhaar",
                        fields: [
                            {
                                label: "Upload File",
                                required: true,
                                subtitleText:"Choose file for verification",
                                key: "multipartFile",
                                value: "",
                                inputType: inputTypes.FILE,
                                validationRequired: false,
                                validations: [{}]
                            },
                            {
                                label: "MASK QR CODE",
                                required: true,
                                subtitleText:"",
                                key: "maskQRCode",
                                value: true,
                                inputType: inputTypes.CHECKBOX,
                                validationRequired: false,
                                validations: [{}],
                            },
                        ]
                    }
                ]
            },
            // {
            //     index: 2,
            //     subCategoryName: "RC",
            //     active: true,
            //     data: [
            //         {
            //             index: 0,
            //             apiNamePostman: "OCR and Verification",
            //             active: true,
            //             method: "POST",
            //             key: "RC verification",
            //             displayName: "RC verification",
            //             redirectUrl:"https://documenter.getpostman.com/view/14349489/UVJeGbtL#e17821ca-feda-4249-98b7-4af3e8f67fc9",
            //             type: contentTypes.formData,
            //             imgSourceDark: "",
            //             imgSourceLight: "assets/api-doc-images/ocr-verification/ocr-verify-rc",
            //             endpoint: "/kyc/india/v1/document-verification",
            //             fields: [
            //                 {
            //                     label: "Upload File",
            //                     required: true,
            //                     subtitleText:"Choose file for verification",
            //                     key: "multipartFile",
            //                     value: "",
            //                     inputType: inputTypes.FILE,
            //                     validationRequired: false,
            //                     validations: [{}]
            //                 },
            //                 {
            //                     key: "docType",
            //                     value: "RC Document",
            //                     hidden: true,
            //                 }
            //             ]
            //         }
            //     ]
            // },
            {
                index: 3,
                subCategoryName: "Aadhaar-Pan Link",
                active: true,
                data: [
                    {
                        index: 0,
                        apiNamePostman: "Validation",
                        active: true,
                        method: "POST",
                        key: "Validate Aadhaar Pan Link",
                        outputFields:"onlyData",
                        displayName: "Validate Aadhaar PAN Link",
                        redirectUrl:"https://documenter.getpostman.com/view/14349489/UVJeGbtL#43f69f87-65cd-4d57-ae8a-1c1d4c9524f3",
                        type: contentTypes.json,
                        imgSourceDark: "",
                        imgSourceLight: "assets/api-doc-images/validation/premium-pan-link",
                        endpoint: "/kyc/india/v1/premium-pan-link",
                        fields: [
                            {
                                label: "Enter PAN Number",
                                required: true,
                                key: "pan",
                                value: "",
                                inputType: inputTypes.TEXT,
                                validationRequired: false,
                                validations: [{}]
                            }
                        ]
                    }
                ]
            },
            {
                index: 4,
                subCategoryName: "Image Masking",
                active: true,
                data: [
                    {
                        index: 0,
                        apiNamePostman: "MaskingImage",
                        active: true,
                        method: "POST",
                        key: "Image Masking",
                        displayName: "Image Masking",
                        redirectUrl:"https://documenter.getpostman.com/view/14349489/UVJeGbtL#43f69f87-65cd-4d57-ae8a-1c1d4c9524f3",
                        type: contentTypes.formData,
                        imgSourceDark: "",
                        imgSourceLight: "assets/api-doc-images/validation/premium-pan-link",
                        endpoint: "/kyc/v1/mask-image",
                        fields: [
                            {
                                label: "Upload File",
                                required: true,
                                key: "multipartFile",
                                value: "",
                                inputType: inputTypes.FILE,
                                validationRequired: false,
                                validations: [{}]
                            },
                        ]
                    }
                ]
            },
            {
                index: 5,
                subCategoryName: "Face Match",
                active: true,
                data: [
                    {
                        index: 0,
                        apiNamePostman: "FaceMatch",
                        active: true,
                        method: "POST",
                        key: "Face Match",
                        displayName: "Face Match",
                        redirectUrl:"https://documenter.getpostman.com/view/14349489/UVJeGbtL#43f69f87-65cd-4d57-ae8a-1c1d4c9524f3",
                        type: contentTypes.formData,
                        imgSourceDark: "",
                        imgSourceLight: "assets/api-doc-images/validation/premium-pan-link",
                        endpoint: "/kyc/v1/mask-image",
                        fields: [
                            {
                                label: "Upload File",
                                required: true,
                                key: "multipartFile",
                                value: "",
                                inputType: inputTypes.FILE,
                                validationRequired: false,
                                validations: [{}]
                            },
                        ]
                    }
                ]
            }
        ]
    }
 ]
 
 export default data;