import { Directive, Input, OnChanges, SimpleChanges } from '@angular/core';
import { AbstractControl, FormControl,NG_VALIDATORS, Validator, ValidatorFn, Validators } from '@angular/forms';

/** A hero's name can't match the given regular expression */
export function passwordValidator(password: any): ValidatorFn {
  return (control: AbstractControl): {[key: string]: any} | any => {
    let isPassword;
	console.log(control);
	isPassword = !(control.value.confirmPassword === control.value.password) ? '*Password does not matches.':false;
    return isPassword ? {'isPassword': {value: isPassword}} : null;
  };
}

@Directive({
  selector: '[formValid]',
  providers: [{provide: NG_VALIDATORS, useExisting: ConfirmPasswordDirective, multi: true}]
})
export class ConfirmPasswordDirective implements Validator {
  formValue: string;
  
  validate(control: FormControl): any{
  	if(control.get('password')){
  		if(control.get('password').status === 'INVALID'){
  			return '_blank';
  		}else{
  			return passwordValidator(this.formValue)(control);	
  		}
  	}
  }
}

