import { Component, OnInit, Input } from '@angular/core';
import data from '../../../assets/constants/demo-api-doc';
import { DataService } from "../../services/data.service";
@Component({
  selector: 'app-api-doc',
  templateUrl: './api-doc.component.html',
  styleUrls: ['./api-doc.component.scss']
})
export class ApiDocComponent implements OnInit {
  data: any[] = data
  categoryApi: {} = {}
  apiSelected: {} = {}
  defaultSelect = { displayName: "---Select an API---", active: true }
  defaultSubCategorySelect = { subCategoryName: "---Select a Sub Category---", active: true }
  serviceList=[{ ...this.defaultSubCategorySelect }]
  apiList=[{...this.defaultSelect}]
  apiName: string = ""
  categoryName: string = ""
  currentTheme: string = null
  isBodyImg: boolean = true;
  isRequestImg: boolean = true;
  isResponseImg: boolean = true;
  constructor(private _data: DataService) {


  }
  ///to check if image exists
  checkImg = (url: string, type: string) => {
    const img = new Image();
    img.src = url;
    if (img.complete) {
      console.log('exit', url)
      switch (type) {
        case 'body':
          this.isBodyImg = true
          break;
        case 'request':
          this.isRequestImg = true
          break;
        case 'response':
          this.isResponseImg = true
          break;

        default:
          break;
      }
    } else {
      img.onload = () => {

        console.log('exit', url)
        switch (type) {
          case 'body':
            this.isBodyImg = true
            break;
          case 'request':
            this.isRequestImg = true
            break;
          case 'response':
            this.isResponseImg = true
            break;

          default:
            break;
        }
      };

      img.onerror = () => {
        console.log('no exit', url)
        switch (type) {
          case 'body':
            this.isBodyImg = false
            break;
          case 'request':
            this.isRequestImg = false
            break;
          case 'response':
            this.isResponseImg = false
            break;

          default:
            break;
        };
      };
    }
  }
  ngOnInit() {
    // this._data.themeName.subscribe(val => {

    //   if (val !== "") {
    //     console.log('called onit if---',window.localStorage.getItem('currentTheme'))
    //     this.currentTheme = val
    //   }
    //   else {
    //     console.log('called onit---',window.localStorage.getItem('currentTheme'))
    //     window.localStorage.getItem('currentTheme') == null ? this.currentTheme = "theme-light" : this.currentTheme = window.localStorage.getItem('currentTheme');
    //   }
    // })
  }

  ngOnChanges() {
    //window.localStorage.getItem('currentTheme') == null ?this.currentTheme = "theme-light" : this.currentTheme = window.localStorage.getItem('currentTheme');
    // this._data.themeName.subscribe(val => {
    //   console.log('==val---', val)
    // })

  }

  setCategoryServices(category:string){
    this.serviceList=[];
    const serObj= JSON.parse(category)
    this.serviceList=[{ ...this.defaultSubCategorySelect }, ...serObj.data]
    this.apiList = [{...this.defaultSelect }]
    this.apiSelected = { ...this.defaultSelect }
    this.apiName="";
  }

  setCategoryApi(apiData: string) {    
    const apiObj = JSON.parse(apiData)
    this.categoryApi = apiObj;
    this.categoryName = apiObj.categoryName;
    this.apiList=[{ ...this.defaultSelect }, ...apiObj.data]
    // resetting selected fields
    this.apiSelected={};
    this.apiName = ""

  }
  setApiSelected(apis) {
    this.apiSelected = {}
    let api=JSON.parse(apis)
    this.checkImg(api.imgSourceLight+ '-body.png', 'body')
    this.checkImg(api.imgSourceLight+ '-request.png', 'request')
    this.checkImg(api.imgSourceLight + '-response.png', 'response')
    this.apiSelected = { ...api }
    this.apiName = api.displayName !== this.defaultSelect.displayName? api.displayName : "";
 
  }
}
