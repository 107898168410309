import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, AbstractControl } from "@angular/forms";
import { Observable, zip } from "rxjs";
import { NewReportService, ServiceReport } from "./new-report.service";
import {
  debounceTime,
  distinctUntilChanged,
  finalize,
  map,
} from "rxjs/operators";
import { DataService } from "../../../services/data.service";
import * as FileSaver from "file-saver";
//import { Angular5Csv } from 'angular5-csv/dist/Angular5-csv';

@Component({
  selector: "app-new-report-page",
  templateUrl: "./new-report-page.component.html",
  styleUrls: ["./new-report-page.component.scss"],
})
export class NewReportPageComponent implements OnInit {
  startDate: any;
  endDate: any;

  services = [
    { id: "", label: "Filter by services" },
    { id: 1, label: "PAN Verification Service" },
    { id: 2, label: "Aadhaar Verification Service" },
    { id: 3, label: "Passport Verification Service" },
    { id: 4, label: "Bank Cheque Verification Service" },
    { id: 5, label: "AML Check" },
    { id: 6, label: "Signature Check" },
    { id: 7, label: "Other Verification Service" },
  ];

  defaultOption = { id: "", label: "Filter by services" };

  entityInfoCheckList = {
    entries: [
      {
        refId: "23DF6655GG",
        checks: "Passport",
        status: "Failed",
        uploadDate: new Date().toLocaleDateString(),
      },
      {
        refId: "23DF6655GG",
        checks: "Passport",
        status: "Failed",
        uploadDate: new Date().toLocaleDateString(),
      },
      {
        refId: "23DF6655GG",
        checks: "Passport",
        status: "Failed",
        uploadDate: new Date().toLocaleDateString(),
      },
      {
        refId: "23DF6655GG",
        checks: "Passport",
        status: "Failed",
        uploadDate: new Date().toLocaleDateString(),
      },
      {
        refId: "23DF6655GG",
        checks: "Passport",
        status: "Failed",
        uploadDate: new Date().toLocaleDateString(),
      },
      {
        refId: "23DF6655GG",
        checks: "Passport",
        status: "Failed",
        uploadDate: new Date().toLocaleDateString(),
      },
    ],
    pagination: {
      total: 20,
      skipCount: 20,
      maxItems: 20,
    },
  };
  reportFilterForm: FormGroup;

  paginationInfo: any;
  reportList: ServiceReport[];
  reportList$: Observable<ServiceReport[]>;
  paginationNumbers = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  currentPage = 0;
  maxItems = 20;
  constructor(
    private formBuilder: FormBuilder,
    private _data: DataService,
    private newReportService: NewReportService,
    private dataService: DataService
  ) {}

  ngOnInit() {
    this.reportFilterForm = this.formBuilder.group({
      startDate: [""],
      endDate: [""],
      service: [""],
    });

    this.getServiceController().valueChanges.subscribe((service) => {
      console.log("ser", service.id);
    });

    // this.getStartDateController().valueChanges.subscribe((startDate) => {
    //   if (startDate & this.getEndDateController().value)  {
    //     this.reportList$ = this.newReportService
    //     .getCalledServicesReportList({ startDate, endDate: this.getEndDateController().value })
    //     .pipe(
    //       map((response) => {
    //         console.log("called", response);
    //         return response ? response.data : [];
    //       })
    //     );
    //   }
    // })

    // this.getEndDateController().valueChanges.subscribe((endDate) => {
    //   if (endDate & this.getStartDateController().value)  {
    //     this.reportList$ = this.newReportService
    //     .getCalledServicesReportList({ startDate: this.getStartDateController().value, endDate })
    //     .pipe(
    //       map((response) => {
    //         console.log("called", response);
    //         return response ? response.data : [];
    //       })
    //     );
    //   }
    // })

    zip(
      this.startDateController.valueChanges,
      this.endDateController.valueChanges
    )
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe(([start, end]) => {
        this.reportList$ = this.newReportService
          .getCalledServicesReportList({ startDate: start, endDate: end })
          .pipe(
            map((response) => {
              return response ? response.data : [];
            })
          );
      });

    this._data.changeLoaderVisibility(true);
    this.reportList$ = this.newReportService.getCalledServicesReportList().pipe(
      map((response) => {
        return response ? response.data : [];
      }),
      finalize(() => this._data.changeLoaderVisibility(false))
    );
  }

  enableReport(){
    return this.startDateController.value && this.endDateController.value
  }

  downloadReport() {
    if(this.enableReport()){
    this.newReportService
      .downloadReport({
        starDate: this.startDateController.value,
        endDate: this.endDateController.value,
      })
      .subscribe((res) => 
      {
        const blob = new Blob([res], { type: 'application/csv' });
        var file = new File([blob], 'Report.csv', { type: 'application/csv' });
        FileSaver.saveAs(blob, `report.csv`);
        console.log(res);
    });
  }else{
    this.dataService.toastrShow(`Please select date range to download the report`, 'warning')
  }
  }

  get startDateController(): AbstractControl {
    return this.reportFilterForm.get("startDate");
  }

  get endDateController(): AbstractControl {
    return this.reportFilterForm.get("endDate");
  }

  getServiceController(): AbstractControl {
    return this.reportFilterForm.get("service");
  }

  private formatDate(date) {
    const d = new Date(date);
    let month = "" + (d.getMonth() + 1);
    let day = "" + d.getDate();
    const year = d.getFullYear();
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    return [year, month, day].join("-");
  }

  compareFn(c1: any, c2: any): boolean {
    return c1 && c2 ? c1.id === c2.id : c1 === c2;
  }

  calculatePagination() {
    const totalPages =
      this.entityInfoCheckList.pagination.total / this.currentPage;
    this.paginationInfo = {
      totalPages,
      total: this.entityInfoCheckList.pagination.total,
      maxItems: this.entityInfoCheckList.pagination.maxItems,
      skipCount: this.currentPage,
    };
  }

  updatePagination() {}

  sortReport(column: string) {
    console.log(column);
  }

  onPageNumber(page: number) {
    this.currentPage = page;
    this.reportList$ = this.newReportService
      .getCalledServicesReportList({ startDate: null, endDate: null }, null, {
        page: this.currentPage,
        size: this.maxItems,
      })
      .pipe(
        map((response) => {
          return response ? response.data : [];
        })
      );
  }
  onPrevious() {
    this.currentPage = this.currentPage !== 0 ? this.currentPage - 1 : 0;
    this.reportList$ = this.newReportService
      .getCalledServicesReportList({ startDate: null, endDate: null }, null, {
        page: this.currentPage,
        size: this.maxItems,
      })
      .pipe(
        map((response) => {
          return response ? response.data : [];
        })
      );
  }
  onNext() {
    this.currentPage = this.currentPage !== 0 ? this.currentPage + 1 : 0;
    this.reportList$ = this.newReportService
      .getCalledServicesReportList({ startDate: null, endDate: null }, null, {
        page: this.currentPage,
        size: this.maxItems,
      })
      .pipe(
        map((response) => {
          return response ? response.data : [];
        })
      );
  }
}
