import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';
import { finalize } from 'rxjs/operators';
import { DataService } from 'src/app/services/data.service';
import { AmlSearchService } from '../aml-search/aml-search.service';

@Component({
  selector: 'app-aml-screening',
  templateUrl: './aml-screening.component.html',
  styleUrls: ['./aml-screening.component.scss']
})
export class AmlScreeningComponent implements OnInit {
  searchData: any[] = [];
  searchDone: boolean = false;
  ifsearch: boolean = false;
  fuzzinessArray: any = [
    { key: 'Exact Match', value: 'STRICT' },
    { key: 'Near Match', value: 'FUZZY' },
    { key: 'Broad Match', value: 'MOREFUZZY' },];
  amlSearchForm: FormGroup = this.formBuilder.group({
    firstName: [''],
    fuzzy: ['STRICT'],
  });
  getAllDetails: Object;
  total: any;
  page = 0;
  itemsPerPage = 10;
  maxSize = 5;
  jump: any;

  constructor(private amlSearchService: AmlSearchService, private dataService: DataService, private formBuilder: FormBuilder,private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
  }

  clear(){
    this.searchDone = false;
    this.amlSearchForm.get('firstName').setValue('');
    this.jump = '';
    this.page = 0;
  }

  search(page, itemsPerPage) {
    let payload = {
      "size": itemsPerPage,
      "page": page,
      "searchType": this.amlSearchForm.value.fuzzy,
      "name": this.amlSearchForm.value.firstName
    }
    this.dataService.changeLoaderVisibility(true);
    this.amlSearchService.getFullProfileSearch(payload).pipe(finalize(() => this.dataService.changeLoaderVisibility(false))).subscribe((res) => {
      this.searchDone = true;
      this.searchData = res['data']['content'];
      this.total = res['data']['totalElements'];      
    });
  }

  viewReport(item){
    this.ifsearch = true;
    this.getAllDetails = item;    
  }

  onPageChange({ page, itemsPerPage }: PageChangedEvent) {
    this.itemsPerPage = itemsPerPage;
    this.page = page-1;
    this.search(this.page, this.itemsPerPage)
  }

  jumpTo(){
    this.page = this.jump-1
    this.search(this.jump, this.itemsPerPage)
  }
  public getSantizeUrl(url : string) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
}

}
