import { Directive, Input, ElementRef } from '@angular/core';


export enum reportStatus {
  Failed = 'FAILED',
  Passed = 'PASSED',
}

@Directive({
  selector: '[appNewReportStatus]'
})
export class NewReportStatusDirective {

  @Input('status') status = '';

  constructor(private eleRef: ElementRef) {}

  ngOnInit() {
      this.highlightStatus();
  }

  highlightStatus() {
      switch (this.status) {
          case reportStatus.Failed:
              this.eleRef.nativeElement.style.backgroundColor = '#B70000';
              break;
          case reportStatus.Passed:
              this.eleRef.nativeElement.style.backgroundColor = '#00A510';
              break;
          default:
              break;
      }
  }

}
