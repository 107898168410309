import { Component, OnInit } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Router, ActivatedRoute, Params, NavigationEnd } from "@angular/router";
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { KycModalComponent } from '../../../shared/kyc-modal/kyc-modal.component';
import { AmlReports, UserReports } from "../../../utils/reports";
import { DataService } from "../../../services/data.service";
import { PaymentService } from "../../../services/payment.service";
import { AppService } from "../../../services/app.service";
import { first } from 'rxjs/operators';
declare function scoreCircle(): any;

@Component({
  selector: 'app-user-area',
  templateUrl: './user-area.component.html',
  styleUrls: ['./user-area.component.scss']
})
export class UserAreaComponent implements OnInit {

  userHome: any = AmlReports;
  usersData: any[] = [];
  bsModalRef: BsModalRef;

  //Pagination
  jump: any;
  currentPage: number = 0;
  itemsPerPage: number = 10;
  totalItems: number;
  maxSize: number;
  sort: string = null;
  startIndex: number = 0;
  endIndex: number = 10;
  buildQuery: any = {
      'size': this.itemsPerPage,
      'page': this.currentPage,
      'identityInfo': null,
      'userType': 'INDIVIDUAL',
      'name': null,
      'sort': this.sort,
  };

  //loading
  isApiLoading: boolean = false;
  pageList: any = [];
  statusDetails: boolean;
  id: any;
  isEditableService: any;
  clientId: any;
  appuserName='';
  userReport=false;

  constructor( private modalService: BsModalService,
    private _data: DataService,
    private payment: PaymentService,
    private route: ActivatedRoute,
    private _app: AppService,
    public router: Router
   ) { }

   ngOnInit() {
    this.route.params.subscribe(params => {
      this.clientId = params["id"];
    });
    this.payment.getCurrentSubscription().subscribe((res)=> { 
        if(res['data'] && res['data'].subscriptionPlan && res['data'].subscriptionPlan.standardServices && res['data'].subscriptionPlan.standardServices.length){
            const index = res['data'].subscriptionPlan.standardServices.findIndex((e) => e.slug == 'individual-edit-user');
            if(index > -1){
                this.isEditableService = res['data'].subscriptionPlan.standardServices[index];
            }
        }
    });
    let index = JSON.parse(localStorage.getItem('key'))
    if (index == null) {
        this.getPagedData(this.currentPage)
        this.jump=this.currentPage+1
    }
    else {
        this.getPagedData(index)
        this.jump=index+1
    }

    this.getUserHomeData(false);

}

  ngOnDestroy() {


    console.log("destroyed!");
    if (this.bsModalRef) {
        this.bsModalRef.content.onClose.subscribe(result => {
            console.log(result);
        });
        this.bsModalRef.hide();
    }
}

createNewUser(heroUser?: any, isEdit?: boolean) {
    if(!isEdit){
        if (heroUser && heroUser.pendingChecks) {
            this._data.toastrShow(`User has already ${heroUser.pendingChecks} checks remaining , Please complete them to proceed`, "warning");
            return;
        }
    }
    else{
        if(heroUser && heroUser.userareaStatus == 'VERIFIED'){
            this._data.toastrShow(`KYC process is completed, no further changes can be made`, "warning");
            return;
        }
    }

    if (heroUser) {
        var userInfo = heroUser.corporateUserInfo ? heroUser.corporateUserInfo : heroUser.individualUserInfo;
        var userType = heroUser.corporateUserInfo ? "CORPORATE" : "INDIVIDUAL";
    }

    if (heroUser && heroUser.individualUserInfo) {
        //if identityInfoType is GUID , make it null
        userInfo.identityInfo = (heroUser.endUser.identityInfoType != "GUID") ? heroUser.endUser.identityInfo : null;
        userInfo.identityInfoType = (heroUser.endUser.identityInfoType == "GUID") ? "GUID" : null;
        userInfo.guid = (heroUser.endUser.identityInfoType == "GUID") ? heroUser.endUser.identityInfo : null;
        userInfo.username = heroUser.endUser.userName;
    }

    if (heroUser && heroUser.corporateUserInfo) {
        //if identityInfoType is GUID , make it null
        userInfo.identityInfo = (heroUser.endUser.identityInfoType != "GUID") ? heroUser.endUser.identityInfo : null;
        userInfo.identityInfoType = (heroUser.endUser.identityInfoType == "GUID") ? "GUID" : null;
        userInfo.guid = (heroUser.endUser.identityInfoType == "GUID") ? heroUser.endUser.identityInfo : null;
        userInfo.username = heroUser.endUser.userName;
    }

    const initialState = {
        title: isEdit ? `Edit User Details` : `Create New User`,
        type: "do-live-check",
        userType: userType,
        userInfo: userInfo,
        editableUserInfo: isEdit
    };
    this.bsModalRef = this.modalService.show(KycModalComponent, {
        initialState,
        animated: true,
        keyboard: false,
        backdrop: true,
        ignoreBackdropClick: true,
        class: "modal-lg liveCheckModal"
        // class: "modal-lg liveCheckModal"tabularDataModal
    });
    this.bsModalRef.content.closeBtnName = "Close";
    console.log(this.bsModalRef.content.onClose);
    this.bsModalRef.content.onClose.subscribe(result => {
        console.log("results", result);
        if (result && Object.keys(result).length) {
            this.usersData.unshift(result);
        }
    });
}

getUserHomeData(isScroll) {
    // if(!isScroll && this.currentPage === 0){
    //     this.usersData = [];
    //     this.isApiLoading = true ;  //show loader only for first page
    //     this._data.changeLoaderVisibility(this.isApiLoading);
    //     // this.isWidgetEmpty = false;
    // }
    // else{
    //     // this.isPaginatedDataLoading = true ;
    // }
    this.isApiLoading = true;  //show loader only for first page
    this._data.changeLoaderVisibility(this.isApiLoading);

    this._app.getClientUserHomeData(this.buildQuery,this.clientId)
        .pipe(first())
        .subscribe(
            (res) => {
                
                let usersData = res.data.content;
                this.usersData = [];
                for (var i = 0; i < usersData.length; i++) {
                    // usersData[i].status = this.getRandomStatus(); //for randomizing
                    this.usersData.push(usersData[i]);
                }
                // if (this.currentPage === 0) {
                //     this.totalItems = res.data.totalElements;
                //     this.maxSize = res.data.totalPages;
                // }
                // else {
                //     this.isApiLoading = false
                // }
                this.isApiLoading = false;
                this._data.changeLoaderVisibility(this.isApiLoading);
                // console.log(this.usersData);
                this.totalItems = res.data.totalElements;
                this.maxSize = res.data.totalPages;
                this.pageSize()
            },
            (err) => {
                this.isApiLoading = false;
            }
        );
    localStorage.removeItem('key');
    
}

clearInput() {
    this.jump=1;
    this.currentPage=0
    localStorage.removeItem('key');
    this.buildQuery = {
        'size': this.itemsPerPage,
        'page': this.currentPage,
        'identityInfo': null,
        'userType': 'INDIVIDUAL',
        'name': null,
        'sort': this.sort,
    };
}

storeIndex(name) {
    console.log("working.....")
    this.appuserName=name;
    this.userReport=true;
    let index = this.currentPage
    localStorage.setItem('key', JSON.stringify(index))
}
doFilter(filterName, value) {
    this.currentPage = 0;
    this.buildQuery["page"] = 0;
    if (filterName == 'identityInfo' || filterName == 'name') {
        // this.clearInput();
        //For Searching userType is mandatory
        this.buildQuery['size'] = this.itemsPerPage;
        this.buildQuery['page'] = this.currentPage;
        this.buildQuery['identityInfo'] = null;
        this.buildQuery['name'] = null;
        this.buildQuery['sort'] = this.sort;
    }
    this.buildQuery[filterName] = value;
    this.getUserHomeData(false);
}

getMoreUserHomeData() {
    console.log("getMoreUsersData");
    this.currentPage = this.currentPage + 1;
    this.buildQuery["page"] = this.buildQuery["page"] + 1;
    // console.log("djfhdjfhdfdjfh",this.currentPage,this.maxSize)
    if (this.currentPage < this.maxSize) {
        this.getUserHomeData(true);
        this.jump=this.currentPage+1;
    }
}

getPaginatedData(isNext) {

    console.log("getMoreUsersData");
    this.currentPage = isNext ? this.currentPage + 1 : this.currentPage - 1;
    this.buildQuery["page"] = isNext ? this.buildQuery["page"] + 1 : this.buildQuery["page"] - 1;
    console.log("djfhdjfhdfdjfh", this.currentPage, this.maxSize)
    if (this.currentPage < this.maxSize) {
        this.getUserHomeData(true);
        this.pageSize()
        this.jump=this.currentPage+1;
    }
}
getPagedData(index) {
    this.currentPage = index
    this.buildQuery["page"] = index;
    if (this.currentPage < this.maxSize) {
        this.getUserHomeData(true);
        this.pageSize()
        this.jump=this.currentPage+1;
    }

}
jumpTo() {
    // let index=parseInt(this.jump)
    if (this.jump <= this.maxSize) {
    if(Math.sign(this.jump) != -1 && Math.sign(this.jump) != 0)
    this.getPagedData(this.jump - 1)
    }
}
getPaginatedText() {
    let a = (this.currentPage * this.itemsPerPage) + 1;
    let b;
    if (this.currentPage < (this.maxSize - 1)) {
        b = (this.currentPage + 1) * this.itemsPerPage;
    }
    else if (this.currentPage == (this.maxSize - 1)) {
        // let bufferCheck = this.itemsPerPage * this.maxSize 
        b = this.totalItems;
    }
    return a + "-" + b;
    // let a = (this.buildQuery['page'] * this.buildQuery['size']) + 1 ;
    // let b = (this.buildQuery['page'] + 1 ) * this.buildQuery['size'];
    // return  a+"-"+b ;
}
updateIndex(pageIndex) {
    console.log("page...", this.currentPage)
    this.currentPage = pageIndex + 10
    // this.maxSize=this.startIndex+10;
}
getPage(length) {
    console.log("jfhdjhffhh", length)
    // const l=length/10;
    return (Math.round(length / 10));
}
pageSize() {
    this.pageList = []
    let totalPages = this.getPage(this.totalItems)
    this.startIndex = (this.currentPage - 4) < 0 ? 0 : this.currentPage - 4
    this.endIndex = (this.currentPage + 4) > this.getPage(this.totalItems) ? totalPages : this.currentPage + 4
    console.log("jgfgjghjghg", this.startIndex, this.endIndex)
    for (let i = 0; i <= this.currentPage + 4; i++) {
        if (i >= this.currentPage - 4 && i < totalPages) { this.pageList.push(i) }
    }

}

doSearch(term) {
    if (this.buildQuery['userType']) {
        if (term.indexOf("@") > 0) {
            this.doFilter('identityInfo', term);
        }
        else {
            this.doFilter('name', term);
        }
    }
}

showChecksData(heroUser?: any) {
    if (heroUser) {
        var userInfo = heroUser.corporateUserInfo ? heroUser.corporateUserInfo : heroUser.individualUserInfo;
        var userType = heroUser.corporateUserInfo ? "CORPORATE" : "INDIVIDUAL";
    }

    var identityInfo = heroUser.endUser.identityInfo;
    console.log("identity info ",identityInfo)
    const initialState = {
        title: `Checks Detail`,
        type: "all-user-checks",
        userType: userType,
        identityInfo: identityInfo 
    };
    this.bsModalRef = this.modalService.show(KycModalComponent, {
        initialState,
        class: "modal-lg tabularDataModal"
    });
    this.bsModalRef.content.closeBtnName = "Close";
    console.log(this.bsModalRef.content.onClose);
    this.bsModalRef.content.onClose.subscribe(result => {
        console.log("results", result);
        if (result && Object.keys(result).length) {
            let type = userType.toLowerCase();
            this.router.navigate([`/live-check/${type}/${result}`]);
            this.storeIndex("");            }
    });
}

openReCheckModal(heroUser?:any) {
    if (heroUser) {
        var userInfo = heroUser.corporateUserInfo ? heroUser.corporateUserInfo : heroUser.individualUserInfo;
        var userType = heroUser.corporateUserInfo ? "CORPORATE" : "INDIVIDUAL";
        var endUserInfo = heroUser.endUser;
    }

    const initialState = {
        title: `Schdule Check`,
        type: "schedule-check",
        userType: userType,
        // userInfo   : userInfo ,
        endUserInfo: endUserInfo
    };
    this.bsModalRef = this.modalService.show(KycModalComponent, {
        initialState,
        class: "modal-lg scheduleModal"
    });
    this.bsModalRef.content.closeBtnName = "Close";
    console.log(this.bsModalRef.content.onClose);
    this.bsModalRef.content.onClose.subscribe(result => {
        console.log("results", result);
        if (Object.keys(result).length) {
            console.log(result);
            this.scheduleCheck(result);
        }
    });
}
openStatusDetailsModal(a,b,c,d,e,f,g,h,i,j,k,l,m,n) {
    
    const initialState = {
        title: `Status Details`,
        type: "status-details",
        addressdoccheck: a,
        adversemediacheck: b,
        corporatecheck: c,
        crimecheck: d,
        iddoccheck: e,
        livenesscheck: f,
        pepcheck: g,
        sanctionscheck: h,
        selfiecheck: i,
        docMatch: j,
        idDocCheck2: k,
        adversemediacheck2: l,
        amlCheck2: m,
        corporateUserInfo: n
                     
        
    };
    console.log(initialState);
    this.bsModalRef = this.modalService.show(KycModalComponent, {
        initialState,
        class: "modal-lg scheduleModal"
    });
    this.bsModalRef.content.closeBtnName = "Close";
    console.log(this.bsModalRef.content.onClose);
    this.bsModalRef.content.onClose.subscribe(result => {
        console.log("results", result);
        if (Object.keys(result).length) {
            console.log(result);
            this.scheduleCheck(result);
        }
    });
}

scheduleCheck(result) {

}

getRandomStatus() {
    var arr = ["ti-thumb-up text-success", "ti-thumb-down text-danger", "ti-alert text-warning"];
    var randomValue = arr[Math.floor(arr.length * Math.random())];
    return randomValue;
}

getTitle(value) {
    if (value == "ti-thumb-up text-success") {
        return "Success";
    }
    if (value == "ti-thumb-down text-danger") {
        return "Failed";
    }
    if (value == "ti-alert text-warning") {
        return "Warning , Requires Manual Check";
    }
}
viewStatusDetails(idd){
    this.id = idd
    
    if(this.statusDetails == true){
        this.statusDetails = false
    }else{
    this.statusDetails = true;
    console.log(this.statusDetails)
    }
}

openList(){
    this.userReport=false;
}
goBack(){
    this.router.navigate(['dev-ops'])
}
}
