export class DiscountObj {
  discountPrice: any = null;
  originalPrice: any = null;
  remainingAmount: any = null;
  totalCost: any = null;
}

export class ApplicableTax {
  tax: any = null;
  taxType: any = null;
  taxRate: any = null;
  country: any = null;
}

import {
  Component,
  OnInit,
  Input,
  Output,
  HostListener,
  ViewChild,
  ViewChildren,
  ElementRef,
  EventEmitter,
  ChangeDetectorRef,
} from "@angular/core";
import { PaymentService } from "../../services/payment.service";
import { DataService } from "../../services/data.service";
import { BsModalService } from "ngx-bootstrap/modal";
import { BsModalRef } from "ngx-bootstrap/modal/bs-modal-ref.service";
import { KycModalComponent } from "../../shared/kyc-modal/kyc-modal.component";
import { WizardComponent } from "angular-archwizard";

import { FormGroup, FormBuilder, Validators, NgForm } from "@angular/forms";
// import {
//   StripeService,
//   StripeCardComponent,
//   Elements,
//   Element as StripeElement,
//   ElementOptions,
//   ElementsOptions,
// } from "ngx-stripe";

import { StripeKey } from "../../utils/misc";

declare var StripeCheckout: any;

declare var elements: any;
declare var stripe: any;
@Component({
  selector: "app-checkout",
  templateUrl: "./checkout.component.html",
  styleUrls: ["./checkout.component.scss"],
})
export class CheckoutComponent implements OnInit {
  @Input() planData: any;
  @Input() planDuration: any;
  @Input() priceCurrency: any;
  @Output() paymentComplete = new EventEmitter();
  @ViewChild(WizardComponent)
  wizard: WizardComponent;
  @ViewChildren("traverseBtn")
  traverseBtns: ElementRef;

  planStatus: any;

  handler: any;
  amount: any = 5000;
  stripeKey: any = StripeKey;
  // stripeKey             : any = "pk_test_MzcP5HYKsN62OMepT4ztcw5A00f7fjm8uo" ; // DEV
  // stripeKey             : any = "pk_live_JqFknkpP6c2IKPqdLtVhzFtn00WRITdyg1" ; // PROD

  showCheckoutWizard: boolean = true;
  isTrxnSuccessful: boolean = false;

  clientData: any;
  isAddressPresentOnServer: boolean = false;
  shouldGoNextstep: boolean = false;

  discountObj: DiscountObj = new DiscountObj();

  couponCode: any = null;
  couponId: any = null;

  couponRedeemSuccess: boolean = false;

  bsModalRef: BsModalRef;

  existingBalance: any = null;
  applicableTax: ApplicableTax = new ApplicableTax();

  emailPattern: any = "^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{0}$";

  // NOTE:
  // Stripe amounts are based equal to 1/100th of the base currency, so an amount of 500 equals $5.00.
  //Custom Design
  // @ViewChild('cardInfo') cardInfo: ElementRef;
  // card                           : any;
  // cardHandler                    = this.onChange.bind(this);
  // error                          : string;
  constructor(
    private _payment: PaymentService,
    private _data: DataService,
    private fb: FormBuilder,
    private cd: ChangeDetectorRef,
    private modalService: BsModalService,
    // private stripeService: StripeService
  ) {}

  ngOnInit() {
    console.log(this.planData);
    console.log(this.priceCurrency);
    console.log(this.planDuration);
    console.log(this.planStatus);
    if (this.planData && this.planData["maxCost"]) {
      console.log("PAY AS GO");
      let amount = this.planData["maxCost"]
        ? this.planData["maxCost"] * 100
        : null;
      this.amount = this.getConvertedRate(amount);
      this.discountObj.originalPrice = this.amount;
      this.configureStripe();
    } else {
      console.log("Subscription Plan");
      this.redeemDefaultCouponCode(this.planData);
    }

    this._data.loadClientData.subscribe(
      clientDataFromCookies =>{
        if (clientDataFromCookies) {
          this.clientData = clientDataFromCookies;
          console.log(clientDataFromCookies);
        } else this.clientData = {};
      });


    this.getPaymentAddress();
  }

  getConvertedRate(amount) {
    // let price = amount * this.priceCurrency.conversionRate;
    let price = amount;
    return price.toFixed(2);
  }

  redeemDefaultCouponCode(plan) {
    let redeemObj = {
      planId: plan["id"],
      saleId: null,
      subscriptionDuration: this.planDuration, //ANNUAL,MONTHLY
    };
    this._data.changeLoaderVisibility(true);
    this._payment.redeemCouponV2(redeemObj).subscribe(res => {
      this._data.changeLoaderVisibility(false);
      if (res.data && Object.keys(res.data).length) {
        this.existingBalance = res.data.remainingAmount * 100;
        let amount = res.data.totalCost * 100;
        this.amount = this.getConvertedRate(amount);
        this.discountObj.originalPrice = res.data.originalPrice * 100;
        this.discountObj.originalPrice = this.getConvertedRate(
          this.discountObj.originalPrice
        );
        this.configureStripe();
        // this.applyTax();
      } else {
        this._data.toastrShow("Coupon not Applicable", "info");
      }
    });
  }

  configureStripe() {
    this.handler = StripeCheckout.configure({
      key: this.stripeKey,
      // image: "https://www.kychub.com/assets/img/kyc%20hub%201.png",
      image: "https://www.kychub.com/assets/img/logo_small.png",
      locale: "auto",
      currency: this.priceCurrency["currencyCode"],
      token: token => {
        // this._payment.processPayment(token, this.amount)
        console.log(token, this.amount);
        this.processPayment(token);
      },
    });
  }

  redeemCouponCode(coupon) {
    this.couponCode = coupon.code;
    this.couponId = coupon.id;
    let redeemObj = {
      subscriptionType: this.planData["minCost"]
        ? "PAYASGO"
        : "STANDARD_SUBSCRIPTION",
      // "saleId"           :  this.planStatus == 'UPGRADE' ? null : coupon.id ,
      saleId: coupon.id ? coupon.id : null,
      newPlanId: this.planData.id,
      planStatus: this.planStatus ? this.planStatus : "NEW",
      subscriptionDuration: this.planData["subscriptionDuration"], //ANNUAL,MONTHLY
    };
    this._data.changeLoaderVisibility(true);
    this._payment.redeemCoupon(redeemObj).subscribe(res => {
      console.log(res);
      this._data.changeLoaderVisibility(false);
      if (
        res.data &&
        Object.keys(res.data).length &&
        res.data["discountPrice"]
      ) {
        this.discountObj = res.data;
        for (var key in this.discountObj) {
          this.discountObj[key] = this.discountObj[key] * 100;
        }
        this.amount = this.discountObj.totalCost;
        this.couponRedeemSuccess = true;
        // this.applyTax();
      } else {
        this.couponRedeemSuccess = false;
        this._data.toastrShow("Coupon not Applicable", "info");
      }
    },
    (err)=>{this.paymentComplete.emit(null);}
    );
  }

  handlePayment() {
    this.handler.open({
      name: "KycHub",
      description:
        "Know Your Customer - KYC and AML for different industries using machine learning",
      // image: "https://www.kychub.com/assets/img/kyc%20hub%201.png",
      image: "https://www.kychub.com/assets/img/logo_small.png",
      excerpt: "Deposit Funds to Account",
      amount: this.amount,
    });
  }

  @HostListener("window:popstate")
  onPopstate() {
    this.handler.close();
  }

  processPayment(tokenObj) {
    if (this.planData && this.planData["maxCost"]) {
      this.processPayAsGoPayment(tokenObj);
    } else {
      console.log("Subscription Plan");
      this.processSubscriptionPayment(tokenObj);
    }
  }

  processPayAsGoPayment(tokenObj) {
    let paymentObj = {
      planId: this.planData.id,
      amount: this.amount / 100,
      currencyCode: this.priceCurrency["currencyCode"],
      stripeEmail: tokenObj.email,
      stripeToken: tokenObj.id,
    };
    this._data.changeLoaderVisibility(true);
    this._payment.processPayAsGoPayment(paymentObj).subscribe(res => {
      this.showCheckoutWizard = false;
      this.isTrxnSuccessful = true;
      this._data.changeLoaderVisibility(false);
      this._data.toastrShow("Payment Successful", "info");
    });
  }

  processSubscriptionPayment(tokenObj) {
    let paymentObj = {
      planId: this.planData.id,
      saleId: null,
      stripeEmail: tokenObj.email,
      stripeToken: tokenObj.id,
      currencyCode: this.priceCurrency["currencyCode"],
      subscriptionDuration: this.planDuration, //ANNUAL,MONTHLY
    };
    console.log(this.planData);
    this._data.changeLoaderVisibility(true);
    this._payment.processSubscriptionPayment(paymentObj).subscribe(res => {
      this.showCheckoutWizard = false;
      this.isTrxnSuccessful = true;
      this._data.changeLoaderVisibility(false);
      this._data.toastrShow("Payment Successful", "info");
    });
  }

  finishProcess() {
    let obj = { isPaymentSuccessful: true };
    this.paymentComplete.emit(obj);
  }

  //get payment address
  getPaymentAddress() {
    this._payment.getPaymentAddress().subscribe(
      res => {
        console.log(res);
        if (res.data) {
          this.isAddressPresentOnServer = true;
          this.clientData = { ...this.clientData, ...res.data }; //merges objects
          console.log(this.clientData);
        }
      },
      err => {
        console.log(err);
      }
    );
  }

  //post payment address
  postPaymentAddress(addressObj) {
    this._payment.postPaymentAddress(addressObj).subscribe(
      res => {
        console.log(res);
        // document.getElementById("nextStep").click();
        this.isAddressPresentOnServer = true;
        this.shouldGoNextstep = true;
        let elems = this.traverseBtns["_results"];
        console.log(elems);
        for (var i = 0; i < elems.length; i++) {
          let el: HTMLElement = elems[i].nativeElement as HTMLElement;
          console.log(el);
          setTimeout(()=>el.click() , 0);
        }
      },
      err => {
        console.log(err);
        this.shouldGoNextstep = false;
      }
    );
  }

  //update payment address
  updatePaymentAddress(addressObj) {
    this._payment.updatePaymentAddress(addressObj).subscribe(
      res => {
        console.log(res);
        // document.getElementById("nextstep").click();
        // document.getElementById("nextStep").click();
        this.isAddressPresentOnServer = true;
        this.shouldGoNextstep = true;
        let elems = this.traverseBtns["_results"];
        console.log(elems);
        for (var i = 0; i < elems.length; i++) {
          let el: HTMLElement = elems[i].nativeElement as HTMLElement;
          console.log(el);
          setTimeout(()=>el.click() , 0);
        }
      },
      err => {
        console.log(err);
        this.shouldGoNextstep = false;
      }
    );
  }

  //handle address object on next click
  handleAddressObj() {
    let addressObj = {
      firstName: this.clientData.name,
      lastName: this.clientData.lastName,
      addressLine1: this.clientData.addressLine1,
      addressLine2: this.clientData.addressLine2,
      state: this.clientData.state,
      city: this.clientData.city,
      countryCode: this.clientData.country.countryCode,
      postalCode: this.clientData.postalCode,
      phoneNumber: this.clientData.contactNumber,
      currency: this.priceCurrency.currencyCode,
    };

    console.log(this.shouldGoNextstep);

    if (this.isAddressPresentOnServer) {
      this.updatePaymentAddress(addressObj);
    } else {
      this.postPaymentAddress(addressObj);
    }
  }

  // applyTax(){
  // 	let obj = {
  // 		"amount" : this.amount / 100
  // 	}
  // 	this._payment.applyTax(obj)
  // 	.subscribe(
  // 		(res)=>{
  // 			this.applicableTax.tax     = res.data.tax * 100 ;
  // 			this.applicableTax.taxType = res.data.taxType ;
  // 			this.applicableTax.taxRate = res.data.taxRate ;
  // 			this.applicableTax.country = res.data.country ;

  // 			this.amount = res.data.totalAmount * 100;
  // 			this.configureStripe();
  // 		}
  // 	);
  // }

  // resetCoupon(){
  // 	this._data.changeLoaderVisibility(true);
  // 	this.discountObj = new DiscountObj();
  // 	let applicableCost = this.planData["subscriptionDuration"] == "ANNUAL" ? this.planData["planCostAnnualy"] : this.planData["planCostMonthly"] ;
  // 	this.amount = applicableCost ? applicableCost : this.planData["maxCost"] ;
  // 	this.amount = this.amount * 100 ;
  // 	this.discountObj.originalPrice = this.amount ;

  // 	if(this.existingBalance){
  // 		this.amount = this.amount - this.existingBalance ;
  // 	}

  // 	this.couponRedeemSuccess = false;

  // 	// this.applyTax();
  // 	this._data.changeLoaderVisibility(false);
  // }

  // showAllCoupons(){
  // 	const initialState = {
  //            title              : `Apply Coupons`,
  //            type               : "show-coupons"
  //        };
  //        this.bsModalRef = this.modalService.show(KycModalComponent, {
  //            initialState,
  //            class: "modal-lg"
  //        });
  //        this.bsModalRef.content.closeBtnName = "Close";
  //        console.log(this.bsModalRef.content.onClose);
  //        this.bsModalRef.content.onClose.subscribe(result => {
  //            if (Object.keys(result).length) {
  //                this.redeemCouponCode(result);
  //            }
  //        });
  // }
}
