export const MatchAttributeStatus = [ 
									"SUCCESS",
									"FAILED",
									"NOT_FOUND_IN_DOC",
									"NOT_PRESENT_IN_DOC",
									"NOT_PROVIDED_BY_USER" 
									];

export const VerificationStates = [
	{
			"field" : "firstNameStatus" ,
			"status" : "SUCCESS" ,
			"icon"  : "fa-check text-success" ,
			"text" : "First Name got Matched"
	},
	{
			"field" : "firstNameStatus" ,
			"status" : "FAILED" ,
			"icon"  : "fa-times text-danger" ,
			"text" : "First Name got Failed"
	},
	{
			"field" : "firstNameStatus" ,
			"status" : "NOT_FOUND_IN_DOC" ,
			"icon"  : "fa-exclamation-circle text-warning" ,
			"text" : "First Name Not Found in Document"
	},
	{
			"field" : "firstNameStatus" ,
			"status" : "NOT_PRESENT_IN_DOC" ,
			"icon"  : "fa-exclamation-circle text-warning" ,
			"text" : "First Name Not Present in Document"
	},
	{
			"field" : "firstNameStatus" ,
			"status" : "NOT_PROVIDED_BY_USER" ,
			"icon"  : "fa-exclamation-circle text-warning" ,
			"text" : "First Name Not Provided by User"
	},

	{
			"field" : "lastNameStatus" ,
			"status" : "SUCCESS" ,
			"icon"  : "fa-check text-success" ,
			"text" : "Last Name got Matched"
	},
	{
			"field" : "lastNameStatus" ,
			"status" : "FAILED" ,
			"icon"  : "fa-times text-danger" ,
			"text" : "Last Name Match Failed"
	},
	{
			"field" : "lastNameStatus" ,
			"status" : "NOT_FOUND_IN_DOC" ,
			"icon"  : "fa-exclamation-circle text-warning" ,
			"text" : "Last Name Not Found in Document"
	},
	{
			"field" : "lastNameStatus" ,
			"status" : "NOT_PRESENT_IN_DOC" ,
			"icon"  : "fa-exclamation-circle text-warning" ,
			"text" : "Last Name Not Present in Document"
	},
	{
			"field" : "lastNameStatus" ,
			"status" : "NOT_PROVIDED_BY_USER" ,
			"icon"  : "fa-exclamation-circle text-warning" ,
			"text" : "Last Name Not Provided by User"
	},

	{
			"field" : "middleNameStatus" ,
			"status" : "SUCCESS" ,
			"icon"  : "fa-check text-success" ,
			"text" : "Middle Name got Matched"
	},
	{
			"field" : "middleNameStatus" ,
			"status" : "FAILED" ,
			"icon"  : "fa-times text-danger" ,
			"text" : "Middle Name Match Failed"
	},
	{
			"field" : "middleNameStatus" ,
			"status" : "NOT_FOUND_IN_DOC" ,
			"icon"  : "fa-exclamation-circle text-warning" ,
			"text" : "Middle Name Not Found in Document"
	},
	{
			"field" : "middleNameStatus" ,
			"status" : "NOT_PRESENT_IN_DOC" ,
			"icon"  : "fa-exclamation-circle text-warning" ,
			"text" : "Middle Name Not Present in Document"
	},
	{
			"field" : "middleNameStatus" ,
			"status" : "NOT_PROVIDED_BY_USER" ,
			"icon"  : "fa-exclamation-circle text-warning" ,
			"text" : "Middle Name Not Provided by User"
	},

	{
			"field" : "issueDateStatus" ,
			"status" : "SUCCESS" ,
			"icon"  : "fa-check text-success" ,
			"text" : "Document Issue Date got Matched"
	},
	{
			"field" : "issueDateStatus" ,
			"status" : "FAILED" ,
			"icon"  : "fa-times text-danger" ,
			"text" : "Document Issue Date Match Failed"
	},
	{
			"field" : "issueDateStatus" ,
			"status" : "NOT_FOUND_IN_DOC" ,
			"icon"  : "fa-exclamation-circle text-warning" ,
			"text" : "Issue Date Not Found in Document"
	},
	{
			"field" : "issueDateStatus" ,
			"status" : "NOT_PRESENT_IN_DOC" ,
			"icon"  : "fa-exclamation-circle text-warning" ,
			"text" : "Issue Date Not Present in Document"
	},
	{
			"field" : "issueDateStatus" ,
			"status" : "NOT_PROVIDED_BY_USER" ,
			"icon"  : "fa-exclamation-circle text-warning" ,
			"text" : "Issue Date Not Provided by User"
	},

	{
			"field" : "expiryDateStatus" ,
			"status" : "SUCCESS" ,
			"icon"  : "fa-check text-success" ,
			"text" : "Document Expiry Date got Matched"
	},
	{
			"field" : "expiryDateStatus" ,
			"status" : "FAILED" ,
			"icon"  : "fa-times text-danger" ,
			"text" : "Document Expiry Date Match Failed"
	},
	{
			"field" : "expiryDateStatus" ,
			"status" : "NOT_FOUND_IN_DOC" ,
			"icon"  : "fa-exclamation-circle text-warning" ,
			"text" : "Expiry Date Not Found in Document"
	},
	{
			"field" : "expiryDateStatus" ,
			"status" : "NOT_PRESENT_IN_DOC" ,
			"icon"  : "fa-exclamation-circle text-warning" ,
			"text" : "Expiry Date Not Present in Document"
	},
	{
			"field" : "expiryDateStatus" ,
			"status" : "NOT_PROVIDED_BY_USER" ,
			"icon"  : "fa-exclamation-circle text-warning" ,
			"text" : "Expiry Date Not Provided by User"
	},

	{
			"field" : "addressStatus" ,
			"status" : "SUCCESS" ,
			"icon"  : "fa-check text-success" ,
			"text" : "Address got Matched"
	},
	{
			"field" : "addressStatus" ,
			"status" : "FAILED" ,
			"icon"  : "fa-times text-danger" ,
			"text" : "Address Match Failed"
	},
	{
			"field" : "addressStatus" ,
			"status" : "NOT_FOUND_IN_DOC" ,
			"icon"  : "fa-check text-success" ,
			"text" : "Address Found in Document"
	},
	{
			"field" : "addressStatus" ,
			"status" : "NOT_PRESENT_IN_DOC" ,
			"icon"  : "fa-exclamation-circle text-warning" ,
			"text" : "Address Not Present in Document"
	},
	{
			"field" : "addressStatus" ,
			"status" : "NOT_PROVIDED_BY_USER" ,
			"icon"  : "fa-exclamation-circle text-warning" ,
			"text" : "Address Not Provided by User"
	},

	{
			"field" : "dobStatus" ,
			"status" : "SUCCESS" ,
			"icon"  : "fa-check text-success" ,
			"text" : "Date of Birth got Matched"
	},
	{
			"field" : "dobStatus" ,
			"status" : "FAILED" ,
			"icon"  : "fa-times text-danger" ,
			"text" : "Date of Birth Match Failed"
	},
	{
			"field" : "dobStatus" ,
			"status" : "NOT_FOUND_IN_DOC" ,
			"icon"  : "fa-exclamation-circle text-warning" ,
			"text" : "Date of Birth Found in Document"
	},
	{
			"field" : "dobStatus" ,
			"status" : "NOT_PRESENT_IN_DOC" ,
			"icon"  : "fa-exclamation-circle text-warning" ,
			"text" : "Date of Birth Present in Document"
	},
	{
			"field" : "dobStatus" ,
			"status" : "NOT_PROVIDED_BY_USER" ,
			"icon"  : "fa-exclamation-circle text-warning" ,
			"text" : "Date of Birth Not Provided by User"
	},

	{
			"field" : "docSerialNoStatus" ,
			"status" : "SUCCESS" ,
			"icon"  : "fa-check text-success" ,
			"text" : "Document Serial Number got Matched"
	},
	{
			"field" : "docSerialNoStatus" ,
			"status" : "FAILED" ,
			"icon"  : "fa-times text-danger" ,
			"text" : "Document Serial Number Match Failed"
	},
	{
			"field" : "docSerialNoStatus" ,
			"status" : "NOT_FOUND_IN_DOC" ,
			"icon"  : "fa-exclamation-circle text-warning" ,
			"text" : "Document Serial Number Found in Document"
	},
	{
			"field" : "docSerialNoStatus" ,
			"status" : "NOT_PRESENT_IN_DOC" ,
			"icon"  : "fa-exclamation-circle text-warning" ,
			"text" : "Document Serial Number Present in Document"
	},
	{
			"field" : "docSerialNoStatus" ,
			"status" : "NOT_PROVIDED_BY_USER" ,
			"icon"  : "fa-exclamation-circle text-warning" ,
			"text" : "Document Serial Number Not Provided by User"
	},
];