import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DevOpsRoutingModule } from './dev-ops-routing.module';
import { ClientListComponent } from './client-list/client-list.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PaginationModule } from 'ngx-bootstrap/pagination';

import { PipesModule } from "./../../pipes/pipes.module";
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { UserAreaComponent } from './user-area/user-area.component';
import { UserReportComponent } from './user-report/user-report.component';
import { CheckDetailsComponent } from './check-details/check-details.component';
import { SelfieDetailsComponent } from './selfie-details/selfie-details.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { ShowImageModule } from "./../../directives/show-image/show-image.module";
import { KnobModule } from "@xmlking/ngx-knob";

@NgModule({
    declarations: [ClientListComponent, UserAreaComponent,UserReportComponent,CheckDetailsComponent,SelfieDetailsComponent
    ],
    imports: [
        CommonModule,
        DevOpsRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        PaginationModule.forRoot(),
        ProgressbarModule,
        NgxChartsModule,
        PipesModule,
        ShowImageModule,
        KnobModule
    ]
})
export class DevOpsModule { }
