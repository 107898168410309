import { Injectable, Inject } from "@angular/core";
import {
  HttpClient,
  HttpRequest,
  HttpEvent,
  HttpEventType,
  HttpHeaders,
} from "@angular/common/http";
import { map } from "rxjs/operators";
import { DataService } from "../../services/data.service";

@Injectable({
  providedIn: "root",
})
export class BulkUploadService {
  constructor(
    private http: HttpClient,
    private _data: DataService,
    @Inject("API_URL") public API_URL: any
  ) {}

  // create a new batch
  createBatch(data) {
    return this.http.post<any>(`${this.API_URL}/bulk/batch/create`, data).pipe(
      map(res => {
        return res;
      })
    );
  }

  //get all batches
  getAllBatches(query) {
    return this.http.post<any>(`${this.API_URL}/bulk/batches`, query).pipe(
      map(res => {
        return res;
      })
    );
  }

  //get a particular batch details
  getBatchDetails(batchId , query) {
    let data = {
      batchId,
      page: 0,
      size: 5,
    };
    return this.http.post<any>(`${this.API_URL}/bulk/records`, query).pipe(
      map(res => {
        return res;
      })
    );
  }

  //uploadfile
  uploadFile(data) {
    return this.http.post<any>(`${this.API_URL}/bulk/upload`, data).pipe(
      map(res => {
        return res;
      })
    );
  }

  //update Records
  updateRecord(data) {
    return this.http.post<any>(`${this.API_URL}/bulk/record/update`, data).pipe(
      map(res => {
        return res;
      })
    );
  }

  //get total credit required
  getCreditRequired(batchId) {
    return this.http
      .get<any>(`${this.API_URL}/bulk/calculate/credits/${batchId}`)
      .pipe(
        map(res => {
          return res;
        })
      );
  }

  //final submission
  finalSubmit(batchId) {
    return this.http
      .post<any>(`${this.API_URL}/bulk/create/checkes/${batchId}`, null)
      .pipe(
        map(res => {
          return res;
        })
      );
  }
}
