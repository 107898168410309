import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse
} from "@angular/common/http";
import { Observable } from "rxjs";
import { DataService } from "../services/data.service";
@Injectable()
export class ICOInterceptor implements HttpInterceptor {
  constructor(private _data: DataService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const headersConfig = {
      Accept: "application/json"
    };
    headersConfig["appsource"] = `DASHBOARD`;
    let access_token = this._data.getCookiesByName("token"); //multipart/form-data

    //for bulk upload ui
    if (request.url.indexOf("bulk/upload") > 0) {
      console.log("AAAAAAAAAAAAAAAAAAAAAAA");
      headersConfig["Authorization"] = `Bearer ${access_token}`;
    }
    if (request.url.indexOf("getaquote") > 0) {
      console.log("AAAAAAAAAAAAAAAAAAAAAAA");
      headersConfig["Authorization"] = `Bearer ${access_token}`;
    }
    if (request.url.indexOf("oauth/token") > 0) {
      console.log("BBBBBBBBBBBBBBBBBBBBBB");
      headersConfig["Content-Type"] = `application/x-www-form-urlencoded`;
      headersConfig[
        "Authorization"
      ] = ` Basic Y2ZhMThlY2IxNDQ4ZWZjMmY4YWRhZDFhMjYxZWM0NzY1NDhmMDFkM2FiODE4ZTlmMWU5NGNlMTg3ZDZlYWI1Yzo5MzcwMTk4MTcxMzdjYjZiMzNmOTNjYjUwZTgxYmNiYzk5NzA5OGQ0ZmU4OWQ4ZGE5NGFhMmU3NzE3NzFmY2Y0`;
    }

    if (request.url.indexOf("video") > 0) {
      headersConfig["Authorization"] = `Bearer ${access_token}`;
      headersConfig["Content-Type"] = "application/octet-stream";
      // console.log("Video Here");
      // console.log(request);
    }
    else if(request.url.indexOf("-token")>0){
      
    }
     else if (request.url.indexOf("contactus") > 0) {
      
    } else if (
      request.url.indexOf("public/check") > 0 ||
      request.url.indexOf("public/price") > 0 ||
      request.url.indexOf("public/register") > 0
    ) {
      
      headersConfig[
        "Authorization"
      ] = ` Basic Y2ZhMThlY2IxNDQ4ZWZjMmY4YWRhZDFhMjYxZWM0NzY1NDhmMDFkM2FiODE4ZTlmMWU5NGNlMTg3ZDZlYWI1Yzo5MzcwMTk4MTcxMzdjYjZiMzNmOTNjYjUwZTgxYmNiYzk5NzA5OGQ0ZmU4OWQ4ZGE5NGFhMmU3NzE3NzFmY2Y0`;
    } else if(request.url.indexOf("img/no_of_faces/")>0 || request.url.indexOf("img/face_match/")>0){
      headersConfig["Authorization"]= '!@#$VGYJK*(&^%3%546JK$%6'
    }
     else if (request.url.indexOf("public/pay") > 0) {
      
      headersConfig[
        "Authorization"
      ] = ` Basic Y2ZhMThlY2IxNDQ4ZWZjMmY4YWRhZDFhMjYxZWM0NzY1NDhmMDFkM2FiODE4ZTlmMWU5NGNlMTg3ZDZlYWI1Yzo5MzcwMTk4MTcxMzdjYjZiMzNmOTNjYjUwZTgxYmNiYzk5NzA5OGQ0ZmU4OWQ4ZGE5NGFhMmU3NzE3NzFmY2Y0`;
    } else if (access_token && request.url.indexOf("bulk/upload") < 0) {
      headersConfig["Authorization"] = `Bearer ${access_token}`;
      
      
      // headersConfig["Content-Type"] = "application/octet-stream" ;
    } else if (
      request.url.indexOf("clients/register") > 0 ||
      request.url.indexOf("resetpassword") > 0 ||
      request.url.indexOf("setupaccount") > 0 ||
      request.url.indexOf("resetpasswordlink") > 0
    ) {
      headersConfig[
        "Authorization"
      ] = ` Basic NWZlNTk3NWRlMzE3YmQ4MmNkMTEzMDQ1ZTExYzk5OTM1ZjBkNmI3YTA3N2EzMGRlYjg2MzliOTMyOTY2M2UzZjplZjM4Mjk1NTg0ZTJiMjNiMmY4YzU0OGFjODFmYTNhODM3NmJlMTUxYzdkNmQ0OWY5OTg1ZjEwNzgwNTdjMzM5`;
      
    } else if (request.url.indexOf("bulk/upload") < 0) {
      headersConfig["Content-Type"] = `application/x-www-form-urlencoded`;
      headersConfig[
        "Authorization"
      ] = ` Basic NWZlNTk3NWRlMzE3YmQ4MmNkMTEzMDQ1ZTExYzk5OTM1ZjBkNmI3YTA3N2EzMGRlYjg2MzliOTMyOTY2M2UzZjplZjM4Mjk1NTg0ZTJiMjNiMmY4YzU0OGFjODFmYTNhODM3NmJlMTUxYzdkNmQ0OWY5OTg1ZjEwNzgwNTdjMzM5`;
    }else if (request.url.indexOf("/kyc/document-verification") > 0) {
      headersConfig["Authorization"] = `Bearer ${access_token}`;
    }
 
    // console.log(request);
    request = request.clone({
      setHeaders: headersConfig
    });

    return next.handle(request);
  }
}
