import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AmlScreeningComponent } from './aml-screening.component';

const routes: Routes = [
  { path: '', component: AmlScreeningComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AmlScreeningRoutingModule { }
